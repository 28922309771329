import { CreateScheet, css } from "packages/aphrodite/aphrodite";

export default function Description() {
    return (
        <div className={css(Styles.description)}>
            <p className={ css(Styles.description) }>
                <h2 className={css(Styles.h2)}>Hunting competition</h2>
                Compete on 25 maps in various styles and difficulties, built by the members of TMA community. To compete you simply have to drive your best times on the maps – your records will be collected automatically from Nadeo servers.
                <br /><br />
                Price pool of at least <strong>600 €</strong>!
                <br/><br/>
                There is a 200€ base prizepool provided by the TMA-Admin team + crowdfunded prizepool fromt the community
                <br/><br/>
                <strong>Contributors:</strong>
                <ul className={css(Styles.list)}>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Philippe Swenne</span>
                        <span className={css(Styles.right)}>€90.00</span>
                    </li>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Nathan Relyea</span>
                        <span className={css(Styles.right)}>€100.00</span>
                    </li>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Patrick Rubner</span>
                        <span className={css(Styles.right)}>€50.00</span>
                    </li>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Jan Schlumpberger</span>
                        <span className={css(Styles.right)}>€5.00</span>
                    </li>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Anonymous</span>
                        <span className={css(Styles.right)}>€30.00</span>
                    </li>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Anonymous</span>
                        <span className={css(Styles.right)}>€50.00</span>
                    </li>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Anonymous</span>
                        <span className={css(Styles.right)}>€50.00</span>
                    </li>
                    <li className={css(Styles.list_item, Styles.dono)} >
                        <span className={css(Styles.left)}>Anonymous</span>
                        <span className={css(Styles.right)}>€20.00</span>
                    </li>
                </ul>
                <a {...{
                    href: "https://www.paypal.com/pools/c/8DPuaPKmId",
                    target: "_blank",
                    className: css(Styles.donate)
                }}>
                    Donate
                </a>
                <h3 className={ css(Styles.h3) }>Overall competition</h3>
                <p className={css(Styles.short_desc)}>
                    Standings are determined by the sum of the points from all 25 maps. See the explanation for points distribution for full details.
                </p>
                <ul className={css(Styles.list)}>
                    <li className={css(Styles.list_item)} >
                        <span className={css(Styles.left)}>1st place</span>
                        <span className={css(Styles.right)}>40%</span>
                    </li>
                    <li className={css(Styles.list_item)} >
                        <span className={css(Styles.left)}>2nd place</span>
                        <span className={css(Styles.right)}>20%</span>
                    </li>
                    <li className={css(Styles.list_item)} >
                        <span className={css(Styles.left)}>3rd place</span>
                        <span className={css(Styles.right)}>10%</span>
                    </li>
                </ul>
                <h3 className={ css(Styles.h3) }>Map 25 championship</h3>
                <p className={css(Styles.short_desc)}>
                    Map 25 is a special endurance map, built as a collaboration. Thus, it has a special nature, and there is a special competition for it!
                </p>
                <ul className={css(Styles.list)}>
                    <li className={css(Styles.list_item)} >
                        <span className={css(Styles.left)}>1st place</span>
                        <span className={css(Styles.right)}>10%</span>
                    </li>
                    <li className={css(Styles.list_item)} >
                        <span className={css(Styles.left)}>2nd place</span>
                        <span className={css(Styles.right)}>5%</span>
                    </li>
                    <li className={css(Styles.list_item)} >
                        <span className={css(Styles.left)}>3rd place</span>
                        <span className={css(Styles.right)}>5%</span>
                    </li>
                </ul>

                <h3 className={ css(Styles.h3) }>Raffle between all participants</h3>
                <ul className={css(Styles.list)}>
                    <li className={css(Styles.list_item)} >
                        <span className={css(Styles.left)}>5 random participants</span>
                        <span className={css(Styles.right)}>2% each</span>
                    </li>
                </ul>

                <h2 className={ css(Styles.h3) }>Rules</h2>
                <ul className={css(Styles.list, Styles.rules)}>
                    <li>For each map, players receive points based on their position on the map leaderboard, and their time relative to the 1st place</li>
                    <li>Points are added together to determine the overall standings in the competition</li>
                    <li>You must have set a time on at least 3 of the maps to be eligible for the overall competition and raffle</li>
                    <li>Due to technical reasons, only top100 times on each map (globally) are processed</li>
                    <li>Times are collected automatically from Nadeo servers. Times or replays from any other source will be NOT considered</li>
                    <li>Cuts are allowed</li>
                    <li>Top3 finishers in overall competition are not eligible for raffle prize</li>
                    <li>Campaign mappers are allowed to participate</li>
                    <li>You must be at least 18 years old to be eligible for receiving prize money</li>
                    <li>TMA club admins (Ozei, Juice, deadfish, RogueDawn) are not eligible for any prize. Should any of them be in top3 standing, the prize will go the next on the leaderboard. They will also not be included in the raffle.</li>
                </ul>

                <h2 className={ css(Styles.h3) }>Points distribution</h2>
                <p>
                    You will be awarded points per map, based on your position on the leaderboard of the map, and your time relative to the current WR holder. The world record holder gets 200 points.
                </p>
                <p>
                    Position points for position P are determined with formula<br/>
                    <code className={css(Styles.code)}>100*2^(-(P - 1) / 4)</code>
                <p>
                </p>
                    Time points for time T, when world record is WR, are determined with formula<br/>
                    <code className={css(Styles.code)}>100*2^(-100*( T / WR - 1))</code>
                </p>
                <p>
                    <strong>Example:</strong><br/>
                    World record is 60 seconds. 3rd place time is 60.5 seconds. Player on the 3rd place gets points as follows:<br/>
                    <code className={css(Styles.code)}>
                    100*2^(-(3 - 1) / 4) + 100*2^(-100*(60.5/60 - 1))<br/>
                    = 100*2^(-0.5) + 100*2^(-0.8333)<br/>
                    = 70.7 + 56.1<br/>
                    = 126.8<br/>
                    </code>
                </p>
            </p>
        </div>
    )
}

const Styles = CreateScheet({
    description: {
        padding:  "0 20px",
        fontSize: 14,
    },
    h1: {
        fontSize:     25,
        marginBottom: 10,
    },
    h2: {
        marginBottom: 10,
    },
    h3: {
        marginTop:     30,
        marginBottom:  10,
        paddingBottom: 0,
    },
    short_desc: {
        marginTop: 0,
    },
    list: {
        padding:   0,
        margin:    0,
        listStyle: "none",
    },
    dono: {
        padding: 0,
    },
    list_item: {
        display:       "flex",
        borderBottom:  "1px solid #e7d5b3",
        padding:       "0 10px",
        fontSize:      14,
        ":last-child": {
            borderBottom: 0,
        }
    },
    rules: {
        listStyle:    "disc",
        paddingLeft:  20,
        marginBottom: 50,
    },
    left: {
        flexGrow:   1,
        display:    "flex",
        alignItems: "center",
    },
    right: {
        flexShrink:     0,
        width:          60,
        height:         30,
        display:        "flex",
        justifyContent: "flex-end",
        alignItems:     "center",
        fontWeight:     500,
    },
    code: {
        background:   '#dddddd',
        padding:      '2px 5px',
        borderRadius: 3,
        fontFamily:   'monospace',
        marginTop:    3,
        display:      "inline-block",
    },
    donate: {
        color:           "#012169",
        backgroundColor: "#ffc43a",
        display:         "flex",
        width:           200,
        height:          42,
        justifyContent:  "center",
        alignItems:      "center",
        fontSize:        16,
        fontWeight:      600,
        textDecoration:  "none",
        borderRadius:    21,
        margin:          "10px auto 0 auto",
    }
});