import Input, { InputTheme, InputLayout } from 'packages/forms/Input';
import { Danger, Success, Dark, Gray } from './colors';

export const DefaultLayout:InputLayout = {
    fontFamily:   "'Montserrat', sans-serif",
    fontSize:     14,
    fontWeight:   600,
    borderRadius: 7,
    border:       "1px solid transparent",
    padding:      "8px 15px",
    transition:   "box-shadow 0.2s",
}

export const PrimaryInput:InputTheme = {
    plain: {
        background:         "#F9F9F9",
        color:              Dark.default,
        borderColor:        "rgba(48, 48, 48, 0.2)",
        boxShadow:          undefined,
    },
    placeholder: {
        color:              "rgba(48, 48, 48, 0.3)",
    },
    label: {
        color:              Gray.default,
    },
    focus: {
        background:         undefined,
        color:              undefined,
        borderColor:        undefined,
        boxShadow:          undefined,
    },
    valid: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Success.default,
        boxShadow:          "none",
    },
    error: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Danger.default,
        boxShadow:          "none",
    },
    disabled: {
        background:         undefined,
        color:              Dark.default,
        borderColor:        Dark.disabled,
        boxShadow:          "none",
    }
}

export function MakeInput(theme: InputTheme, layout?: InputLayout, ) {
    return Input({...DefaultLayout, ...layout}, theme);
} 