import moment from "moment";
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import PrettyTable from "packages/tables/PrettyTable.react";
import PrintTime from "packages/utils/PrintTime";
import { WHCResponse } from "packages/whc/model";
import { WHCTable } from "src/themes/tables";

export default function CupsLog({ payload }: { payload: WHCResponse }) {
    return (
        <div className={css(Styles.block)}>
            <PrettyTable
                {...{
                    thead: ["dates", "map", "winner"],
                    tbody: payload.log.map((cup, i) => {
                        return [
                            `${moment
                                .unix(cup.start_at)
                                .format("MMM Do")} - ${moment
                                .unix(cup.end_at)
                                .format("MMM Do")}`,
                            <a
                                href={`https://trackmania.exchange/tracks/view/${cup.track_tmx_id}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {cup.track_name} by {cup.track_author}
                            </a>,
                            cup.ended_at &&
                            cup.current_username &&
                            cup.current_player_time ? (
                                <>
                                    <strong>{cup.current_username}</strong> with{" "}
                                    <strong>
                                        {PrintTime(cup.current_player_time)}
                                    </strong>{" "}
                                    on{" "}
                                    {moment
                                        .unix(cup.end_at)
                                        .format("MMM Do H:mm")}
                                </>
                            ) : (
                                "ongoing"
                            ),
                        ];
                    }),
                    theme: WHCTable,
                    layout: {
                        theadColumnFontSize: 15,
                        tbodyColumnFontSize: 13,
                        tbodyPadding: "0 10px",
                        titlePadding: "3px 10px",
                    },
                    columnsSizes: ["auto", "auto", "auto", 150],
                }}
            />
        </div>
    );
}

const Styles = CreateScheet({
    block: {
        paddingTop: 20,
        fontSize: 12,
        "@media (max-width: 600px)": {
            flexDirection: "column",
        },
        "& table": {
            padding: 0,
        },
        "& thead tr th": {
            fontSize: 12,
            ":first-child": {
                paddingLeft: 30,
            },
        },
        "& tbody tr td": {
            fontFamily: "'IBM Plex Mono', monospace",
            ":first-child": {
                paddingLeft: 30,
            },
        },
        "& tbody tr": {
            "& a": {
                color: "white",
            },
            "& strong": {
                color: "white",
            },
        },
    },
});
