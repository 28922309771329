import moment from "moment";
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithLocalBG } from "packages/aphrodite/WithBG";
import SmoothLine from "packages/motion/SmoothLine.react";
import PrintTime from "packages/utils/PrintTime";
import { WHCResponse } from "packages/whc/model";
import { useState } from "react";
import Countdown from "react-countdown";
import { Colors } from "../BaseStyles";

export default function CurrentState({ payload }: { payload: WHCResponse }) {
    const { cup } = payload;
    const [showPlay, setShowPlay] = useState(false);

    function onClickPlay() {
        setShowPlay((s) => !s);
    }

    return (
        <>
            <div
                className={css(
                    Styles.root,
                    WithLocalBG(
                        "black",
                        `linear-gradient(90deg, rgba(0,0,0, 1), rgba(0,0,0, .7)), linear-gradient(0deg, rgba(0,0,0, 1), rgba(0,0,0, .5)), url(${cup.track.image})`
                    )
                )}
                style={{ backgroundImage: `url(${cup.track.image})` }}
            >
                <h2 className={css(Styles.play)} onClick={onClickPlay}>
                    PLAY THE MAP
                </h2>
                <SmoothLine timeout={150}>
                    {showPlay ? (
                        <div className={css(Styles.playBlock)}>
                            <div className={css(Styles.playInfo)}>
                                To play the map search for{" "}
                                <strong>Hot Pursuit</strong> room in the game or{" "}
                                <a
                                    href={cup.track.tmx_link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    download from TMX
                                </a>
                            </div>
                        </div>
                    ) : undefined}
                </SmoothLine>
                <div className={css(Styles.block)}>
                    <strong>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={cup.track.tmx_link}
                        >
                            {cup.track.title}
                        </a>
                    </strong>
                    <small> by {cup.track.author}</small>
                    <small className={css(Styles.label)}>this week map</small>
                </div>
                {cup.current_state.phase_type_id !== 100 ? (
                    <>
                        <div className={css(Styles.block)}>
                            {cup.current_state.current_wr ? (
                                <>
                                    <strong>
                                        {PrintTime(
                                            cup.current_state.current_wr.time
                                        )}{" "}
                                    </strong>
                                    <small>
                                        by{" "}
                                        {
                                            cup.current_state.current_wr
                                                .player_username
                                        }
                                    </small>
                                </>
                            ) : (
                                "no record yet"
                            )}
                            <small className={css(Styles.label)}>
                                time to beat
                            </small>
                        </div>
                        {cup.current_state.current_wr && (
                            <div className={css(Styles.block)}>
                                {cup.current_state.time_left > 0 ? (
                                    <strong>
                                        <Countdown
                                            {...{
                                                date:
                                                    Date.now() +
                                                    cup.current_state
                                                        .time_left *
                                                        1000,
                                                renderer: (props) => {
                                                    return (
                                                        <div>
                                                            {props.days > 0
                                                                ? `${props.days}d `
                                                                : ""}
                                                            {props.hours > 0
                                                                ? `${props.hours}h `
                                                                : ""}
                                                            {props.minutes > 0
                                                                ? `${props.minutes}m `
                                                                : ""}
                                                            {props.seconds}s
                                                        </div>
                                                    );
                                                },
                                            }}
                                        />
                                    </strong>
                                ) : (
                                    "this week cup is over"
                                )}
                                <small className={css(Styles.label)}>
                                    time left
                                </small>
                            </div>
                        )}
                    </>
                ) : cup.current_state.phase_type_id === 100 ? (
                    <div className={css(Styles.block)}>
                        🎉🎉🎉{" "}
                        <strong>
                            {cup.current_state.current_wr?.player_username}
                        </strong>{" "}
                        with{" "}
                        <strong>
                            {PrintTime(cup.current_state.current_wr?.time || 0)}
                        </strong>{" "}
                        🎉🎉🎉
                        <small className={css(Styles.label)}>winner</small>
                    </div>
                ) : null}
                <div className={css(Styles.block)}>
                    {cup.current_state.phase_name}
                    <small className={css(Styles.label)}>current phase</small>
                </div>
                {cup.current_state.phase_type_id !== 100 && (
                    <div className={css(Styles.block)}>
                        <small>
                            {moment
                                .unix(cup.current_state.phase_start_at)
                                .format("MMM Do H:mm")}{" "}
                            -{" "}
                            {moment
                                .unix(cup.current_state.phase_end_at)
                                .format("MMM Do H:mm")}
                        </small>
                        <small className={css(Styles.label)}>phase range</small>
                    </div>
                )}
            </div>
        </>
    );
}

const Styles = CreateScheet({
    play: {
        textAlign: "center",
        fontWeight: 400,
        fontSize: 26,
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: "'IBM Plex Mono', monospace",
        margin: "40px 0 0 0",
        userSelect: "none",
    },
    playBlock: {
        paddingTop: 40,
    },
    playInfo: {
        padding: "30px",
        background: "#0e0e0e",
        color: "#b8b8b8",
        textDecoration: "none",
        fontSize: 16,
        textAlign: "center",
        fontFamily: "'IBM Plex Mono', monospace",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
        "& a": {
            color: "white",
            fontWeight: 500,
        },
        "& strong": {
            color: "white",
            fontWeight: 500,
        },
    },
    root: {
        maxWidth: 800,
        margin: "0 auto",
        "& p": {
            margin: 0,
            padding: "10px 30px",
        },
    },
    block: {
        position: "relative",
        marginTop: 50,
        textAlign: "center",
        borderBottom: `1px solid #3a3a3a`,
        fontFamily: "'IBM Plex Mono', monospace",
        fontSize: 16,
        fontWeight: 400,
        color: Colors.LightGray,
        "& strong": {
            color: "white",
        },
        "& a": {
            color: "white",
        },
    },
    label: {
        position: "absolute",
        fontSize: 11,
        fontFamily: "Montserrat",
        fontWeight: 300,
        left: 30,
        color: "#f6f3fe",
        opacity: 0.7,
        bottom: -2,
        textTransform: "none",
    },
});
