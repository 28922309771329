import { CreateScheet } from "packages/aphrodite/aphrodite";

const GeneralStyles = CreateScheet({
    content: {
        padding: "0 16px 16px 16px",
    },
    meta_link: {
        ":hover": {
            textDecoration: "underline",
        }
    },
    no_data: {
        textAlign:    "center",
        marginTop:    40,
        marginBottom: 16,
        color:        "#525252",
        opacity:      0.7,
        fontSize:     18,
        fontFamily:   "Montserrat",
    },
    h2: {
        fontSize:   18,
        fontWeight: 600,
        margin:     '36px 0 6px',
        fontFamily: "Montserrat",
        textAlign:  "center",
    },
    add_player: {
        display: "flex",
        
    },
    no_tma: {
        paddingTop: 20,
        textAlign:  "center",
        fontSize:   9,
    }
})

export default GeneralStyles;