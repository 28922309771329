import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithBG } from "packages/aphrodite/WithBG";
import { ReactComponent as Discord } from "src/icons/discord-text.svg";
import Header from "../modules/Header.react";
import { Bebas } from "../fonts";

export default function Landing() {
    return (
        <article
            className={css(
                Styles.page,
                WithBG(
                    "#191d1d",
                    "linear-gradient(rgba(31, 36, 37, 0.7), rgba(31, 36, 37, 0.7)), url(/images/landing-bg.jpg)",
                    "cover"
                ),
                Styles.pageBG,
            )}
        >
            <Header />
            <section className={css(Styles.body)}>
                <div className={css(Styles.hero)}>
                    <h1 className={css(Styles.title1)}>
                        Trackmania Mappers Assembly
                    </h1>
                    <h2 className={css(Styles.title2)}>
                        Creations, Collaborations, Competitions and more
                    </h2>
                </div>
                <div className={css(Styles.banner)}>
                    <a
                        {...{
                            href: "https://discord.gg/b8MfZsYFWg",
                            target: "_blank",
                            className: css(Styles.banner_inner),
                            rel: "noreferrer",
                        }}
                    >
                        <span className={css(Styles.banner_text)}>
                            Join us on discord
                        </span>
                        <Discord className={css(Styles.discord)} />
                    </a>
                </div>
            </section>
        </article>
    );
}

const Styles = CreateScheet({
    page: {
        minHeight: "100vh",
        color: "white",
        display: "flex",
        flexDirection: "column",
    },
    pageBG: {
        "::after": {
            left: 0,
            bottom: 80,
            right: 0,
            top: 0,
            backgroundPosition: "center",
        }

    },
    body: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
    hero: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        marginTop: "5%",
        flexDirection: "column",
        textAlign: "center",
        fontFamily: "Montserrat",
    },
    title1: {
        fontFamily: [Bebas],
        fontSize: 50,
        margin: 0,
        fontWeight: 300,
        textShadow: 'rgba(0, 0, 0, 0.1) 1px 1px, rgba(0, 0, 0, 0.09) 2px 2px, rgba(0, 0, 0, 0.08) 3px 3px, rgba(0, 0, 0, 0.07) 4px 4px, rgba(0, 0, 0, 0.06) 5px 5px, rgba(0, 0, 0, 0.05) 6px 6px, rgba(0, 0, 0, 0.04) 7px 7px, rgba(0, 0, 0, 0.03) 8px 8px, rgba(0, 0, 0, 0.02) 9px 9px, rgba(0, 0, 0, 0.01) 10px 10px',
    },
    title2: {
        margin: "0",
        fontSize: 23,
        fontWeight: 100,
        color: "rgba(255,255,255,0.7)",
        maxWidth: 900,
    },
    banner: {
        width: "100%",
        height: 80,
        background: "#5865f2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "background 0.2s",
        ":hover": {
            backgroundColor:
                "hsl(226,calc(var(--saturation-factor, 1)*98%),70%)",
        },
    },
    banner_inner: {
        maxWidth: 900,
        height: "100%",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: "0 20px",
        textDecoration: "none",
        color: "white",
    },
    banner_text: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        fontSize: 20,
    },
    discord: {
        display: "block",
        width: 180,
        height: 60,
    },
    content: {
        flexGrow: 1,
        background: "#191d1d",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: 60,
    },
    content_inner: {
        maxWidth: 900,
        display: "flex",
        paddingTop: 30,
        width: "100%",
        flexWrap: "wrap",
    },
    content_item: {
        width: "50%",
        padding: "30px 20px",
        "@media (max-width: 715px)": {
            width: "100%",
        },
    },
    content_item_title: {
        fontSize: 18,
        fontWeight: 500,
        margin: "0 0 10px 0",
        fontFamily: "Ubuntu, Arial, sans-serif",
    },
    showcase: {
        width: "100%",
        maxWidth: 900,
        padding: "30px 20px 0 20px",
    },
});
