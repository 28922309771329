import { CreateScheet, css } from "aphrodite";
import { WithLocalBG } from "packages/aphrodite/WithBG";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import { GetFormat } from "packages/tournaments/model";
import PrintTime from "packages/utils/PrintTime";
import { useParams } from "react-router-dom";
import { GetPath, PageProps } from "../model";
import GeneralStyles from "../modules/GeneralStyles";
import { GetTableTheme } from "packages/themes/components";

export default function Track({ theme, tournament, prefix }: PageProps) {
    const { uid } = useParams<{ uid: string }>();
    const track = tournament.tracks.find((t) => t.uid === uid);

    function OpenTrack() {
        if (track) {
            window.open(
                track.tmx_url.length > 0 ? track.tmx_url : track.file_url
            );
        }
    }

    const records = tournament.records[uid] || [];

    const format = GetFormat(tournament.time_attack_format_id);
    if (!format) {
        return null;
    }

    return (
        <div className={css(Styles.content)}>
            {track ? (
                <>
                    <div
                        onClick={OpenTrack}
                        className={css(
                            Styles.meta,
                            WithLocalBG(
                                "white",
                                `linear-gradient(to right, rgba(255,255,255, 0.9) 30%, rgba(255,255,255, 0.6)), url(${track.image_url})`
                            )
                        )}
                    >
                        <strong>
                            <a
                                {...{
                                    href: track.tmx_url,
                                    target: "_blank",
                                    className: css(
                                        GeneralStyles.meta_link,
                                        Styles.track_name,
                                        theme.color("primary")
                                    ),
                                }}
                            >
                                {track.clear_name}
                            </a>
                        </strong>
                        <span>
                            by
                            <span
                                className={css(
                                    Styles.author_name,
                                    theme.color("secondary")
                                )}
                            >
                                {track.author.username}
                            </span>
                        </span>
                    </div>
                    {tournament.has_time_attack && (
                        <div className={css(Styles.table)}>
                            <PrettyTable
                                {...{
                                    thead: [
                                        "position",
                                        "player",
                                        format.has_single_score
                                            ? format.score_title
                                            : undefined,
                                        "time",
                                    ].filter((v) => Boolean(v)),
                                    tbody: records.map((record) => {
                                        const track = tournament.tracks.find(
                                            (t) => t.uid === record.track_uid
                                        );
                                        if (!track) {
                                            return [];
                                        }

                                        return [
                                            () => (
                                                <strong>
                                                    <a
                                                        {...{
                                                            href: `/${GetPath(
                                                                tournament.uri,
                                                                "players",
                                                                record.player
                                                                    .username,
                                                                prefix
                                                            )}`,
                                                            onClick: OpenPage(
                                                                GetPath(
                                                                    tournament.uri,
                                                                    "players",
                                                                    record
                                                                        .player
                                                                        .username,
                                                                    prefix
                                                                )
                                                            ),
                                                            className: css(
                                                                theme.color(
                                                                    "secondary"
                                                                )
                                                            ),
                                                        }}
                                                    >
                                                        {record.player.username}
                                                    </a>
                                                </strong>
                                            ),
                                            record.score.position,
                                            format.has_double_score ? (
                                                <span>
                                                    <strong>
                                                        {format.score_func(
                                                            record.score.score
                                                        )}
                                                    </strong>{" "}
                                                    (
                                                    {format.score_func(
                                                        record.score
                                                            .position_score
                                                    )}{" "}
                                                    + {record.score.time_score})
                                                </span>
                                            ) : format.has_single_score ? (
                                                <span>
                                                    {format.score_func(
                                                        record.score.score
                                                    )}
                                                </span>
                                            ) : undefined,
                                            PrintTime(record.time),
                                        ].filter((v) => Boolean(v));
                                    }),
                                    theme: GetTableTheme(theme),
                                    layout: {
                                        theadColumnFontSize: 15,
                                        tbodyColumnFontSize: 13,
                                        titlePadding: "3px 10px 13px 10px",
                                    },
                                }}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div className={css(GeneralStyles.no_data)}>no such track</div>
            )}
        </div>
    );
}

const Styles = CreateScheet({
    content: {
        padding: "0",
        marginTop: -20,
    },
    meta: {
        padding: "40px 26px",
        fontSize: 20,
        textShadow: "1px 1px rgb(0 0 0 / 10%)",
        cursor: "pointer",
        fontFamily: "Montserrat",
    },
    track_name: {},
    author_name: {
        paddingLeft: 6,
    },
    table: {
        padding: "0 16px 16px 16px",
    },
});
