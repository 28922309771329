import { CSSProperties } from "aphrodite";

export type DraftLayout = {
    editor_padding:                    CSSProperties["padding"]
    editor_min_height:                 CSSProperties["minHeight"]
    editor_font_size:                  CSSProperties["fontSize"]
    editor_border_top_left_radius:     CSSProperties["borderTopLeftRadius"],
    editor_border_top_right_radius:    CSSProperties["borderTopRightRadius"],
    editor_border_bottom_left_radius:  CSSProperties["borderBottomLeftRadius"],
    editor_border_bottom_right_radius: CSSProperties["borderBottomRightRadius"],
    nav_border_top_left_radius:        CSSProperties["borderTopLeftRadius"],
    nav_border_top_right_radius:       CSSProperties["borderTopRightRadius"],
    nav_border_bottom_left_radius:     CSSProperties["borderBottomLeftRadius"],
    nav_border_bottom_right_radius:    CSSProperties["borderBottomRightRadius"],
}

export type DraftTheme = {
    editor_background:         CSSProperties["background"],
    editor_color:              CSSProperties["color"],
    editor_border:             CSSProperties["border"],
    nav_background:            CSSProperties["background"],
    nav_border:                CSSProperties["border"],
    nav_border_bottom:         CSSProperties["borderBottom"],
    nav_item_background:       CSSProperties["background"],
    nav_item_hover_background: CSSProperties["background"],
    nav_item_color:            CSSProperties["color"],
}

export const DefaultLayout:DraftLayout = {
    editor_padding:                    5,
    editor_min_height:                 0,
    editor_font_size:                  14,
    editor_border_top_left_radius:     0,
    editor_border_top_right_radius:    0,
    editor_border_bottom_left_radius:  6,
    editor_border_bottom_right_radius: 6,
    nav_border_top_left_radius:        6,
    nav_border_top_right_radius:       6,
    nav_border_bottom_left_radius:     0,
    nav_border_bottom_right_radius:    0,
}