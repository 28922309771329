import { CreateScheet, css } from "aphrodite";
import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import { Format, Tournament } from "packages/tournaments/model";
import GenericStyles from "src/jsx/forms/GenericStyles";
import { MakeInput, PrimaryInput } from "src/themes/input";
import useSafeState from "packages/hooks/useSafeState";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { PrimaryDraft } from "src/themes/draftjs";
import { PrimaryFilled, LoadButton, DangerFilled, MakeButton } from "src/themes/button";
import { useState } from "react";
import UpdateTournament from "packages/tournaments/API/UpdateTournament";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme, WarningLight } from "src/themes/lightbox";
import moment from "moment";
import FormatPicker from "../modules/FormatPicker.react";
import PrettyTable from "packages/tables/PrettyTable.react";
import { DarkTable } from "src/themes/tables";
import PrintTime from "packages/utils/PrintTime";
import useSimpleError from "packages/hooks/useSimpleError";
import SmoothLine from "packages/motion/SmoothLine.react";
import Switch from "packages/forms/Switch.react";
import UpdateLeaderboard from "packages/tournaments/API/UpdateLeaderborard";

export default function TimeAttackEditor({ content, close }:ComponentProps<{
    tournament: Tournament,
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header>
                Edit Time Attack settings
            </Header>
            <Body>
                <TimeAttackEditorForm {...content} {...{ onClose: close, onSave: close }} />
            </Body>
        </Lightbox>
    )
}

const data_example = [{time: 37614}, {time: 37731}, {time: 37744}, {time: 37768}, {time: 37803}]

function TimeAttackEditorForm({
    tournament,
    onSave,
    onClose,
}:{
    tournament: Tournament,
    onSave:     () => void,
    onClose:    () => void,
}) {
    const [ updatingLeaderboard, setUpdatingLeaderboarad ] = useState(false);
    
    const [ state, setState ] = useSafeState<{
        has_time_attack:           boolean
        time_attack_description:   string | null
        time_attack_start_at:      null | number,
        time_attack_end_at:        null | number,
        time_attack_time_coef:     string,
        time_attack_position_coef: string,
        time_attack_format_id:     number,
        time_attack_format:        Format,
    }>({
        has_time_attack:           tournament.has_time_attack,
        time_attack_description:   tournament.time_attack_description,
        time_attack_start_at:      tournament.time_attack_start_at,
        time_attack_end_at:        tournament.time_attack_end_at,
        time_attack_time_coef:     tournament.time_attack_time_coef.toString(),
        time_attack_position_coef: tournament.time_attack_position_coef.toString(),
        time_attack_format_id:     tournament.time_attack_format_id,
        time_attack_format:        tournament.time_attack_format || {
            id:                1,
            name:              "Personal best per track",
            description:       "",
            has_position_coef: false,
            has_time_coef:     false,
        }
    });
    const [hasErr, setErr ] = useSimpleError(["bad-pos-coef", "bad-time-coef"])
    const [ isSaving, setIsSaving ] = useState(false);

    function OnClickSave() {
        if (parseInt(state.time_attack_position_coef) <= 1) {
            alert("position coef must be > 1")
            setErr("bad-pos-coef")
        } else if (parseFloat(state.time_attack_time_coef) < 0.001) {
            alert("time coef must be >= 0.001")
            setErr("bad-time-coef")
        } else {
            if (
                state.time_attack_end_at &&
                tournament.time_attack_end_at &&
                state.time_attack_end_at > tournament.time_attack_end_at &&
                tournament.is_upading_snapshots_stopped &&
                tournament.campaign
            ) {
                showWarning(<span>Chaning "end at" gonna <strong>clear frozen state</strong> of the leaderboard and <strong>start pulling it again</strong>. Continue?</span>, WarningLight, {
                    title:   "WARNING",
                    buttons: [{
                        text:      "YES",
                        className: css(MakeButton(DangerFilled)),
                        onClick:   () => {
                            SaveData();
                        }
                    }]
                })
            } else {
                SaveData();
            }
        }
    }

    async function SaveData() {
        setIsSaving(true);

        const err = await UpdateTournament(tournament.uri, {
            ...state,
            time_attack_position_coef: parseInt(state.time_attack_position_coef),
            time_attack_time_coef:     parseFloat(state.time_attack_time_coef),
        })

        setIsSaving(false);
        if (err !== null) {
            showWarning(err.text, DarkTheme);
        } else {
            onSave();
        }
    }

    return (
        <section>
            <h2 className={css(GenericStyles.section_title)}>Time attack</h2>
            <section className={css(GenericStyles.section)}>
                <div className={css(GenericStyles.section_title_description)}>
                    Public tournaments visible to everyone, private only for managers
                </div>
                <div className={css(GenericStyles.switch)} onClick={() => {
                    setState({
                        has_time_attack: {$set: !state.has_time_attack}
                    })
                }}>
                    <Switch checked={state.has_time_attack} />
                    <div className={css(GenericStyles.switch_label)}>Has Time Attack</div>
                </div>
            </section>
            <SmoothLine>
                { state.has_time_attack ? (
                    <div>
                        <section className={css(GenericStyles.section)}>
                            <label className={css(GenericStyles.section_label)}>Time Attack description</label>
                            <DraftEditorReact {...{
                                layout:          {editor_min_height:  100},
                                theme:           PrimaryDraft,
                                defaultValue:    state.time_attack_description || "",
                                onContentChange: draft => {
                                    setState({time_attack_description: {$set: draft }})
                                },
                            }}/>
                        </section>
                        <hr className={css(GenericStyles.divider)} />

                        <h2 className={css(GenericStyles.section_title)}>Format</h2>
                        <div className={css(GenericStyles.section_title_description)}>
                            Time Attack Leaderboard only works if <strong>campaign provided</strong> in Tracks
                        </div>
                        <section className={css(GenericStyles.section)}>
                            <FormatPicker {...{
                                active:    state.time_attack_format,
                                setActive: format => {
                                    setErr("bad-pos-coef", "clear");
                                    setErr("bad-time-coef", "clear");
                                    setState({
                                        time_attack_time_coef:     {$set: parseFloat(state.time_attack_time_coef) < 0.001 ? (0.01).toString() : state.time_attack_time_coef},
                                        time_attack_position_coef: {$set: parseInt(state.time_attack_position_coef) <= 1 ? "2" : state.time_attack_position_coef},
                                        time_attack_format:        {$set: format},
                                        time_attack_format_id:     {$set: format.id},
                                    }) 
                                }
                            }} />
                            <div className={css(Styles.format_desc)}>
                                <span dangerouslySetInnerHTML={{__html: state.time_attack_format.description}} />
                            </div>
                        </section>
                        { state.time_attack_format.has_time_coef && (
                            <section className={css(GenericStyles.section)}>
                                <label className={css(GenericStyles.section_label)}>Time coef ({`>`}0)</label>
                                <input {...{
                                    placeholder: "time coef",
                                    type:        "number",
                                    step:        "0.01",
                                    className:   css(MakeInput(PrimaryInput, { width: "100%", error: hasErr("bad-time-coef") })),
                                    value:       state.time_attack_time_coef || "",
                                    onChange:    (e) => {
                                        setErr("bad-time-coef", "clear");
                                        setState({
                                            time_attack_time_coef: {$set: e.target.value }
                                        }) 
                                    }
                                }}/>
                                <SmoothLine>
                                    { hasErr("bad-time-coef") ? <span className={css(GenericStyles.error)}>time coef must be {">"} 0</span> : undefined }
                                </SmoothLine>
                            </section>
                        ) }
                        { state.time_attack_format.has_position_coef && (
                            <section className={css(GenericStyles.section)}>
                                <label className={css(GenericStyles.section_label)}>Position coef ({`>`}1)</label>
                                <input {...{
                                    placeholder: "position coef",
                                    type:        "number",
                                    className:   css(MakeInput(PrimaryInput, { width: "100%", error: hasErr("bad-pos-coef") })),
                                    value:       state.time_attack_position_coef || "",
                                    onChange:    (e) => {
                                        setErr("bad-pos-coef", "clear");
                                        setState({
                                            time_attack_position_coef: {$set: e.target.value }
                                        }) 
                                    }
                                }}/>
                                <SmoothLine>
                                    { hasErr("bad-pos-coef") ? <span className={css(GenericStyles.error)}>position coef must be {">"} 1</span> : undefined }
                                </SmoothLine>
                            </section>
                        ) }
                        { state.time_attack_format.has_time_coef || state.time_attack_format.has_position_coef ? (
                            <>
                                <label className={css(GenericStyles.section_label)}>Example</label>
                                <PrettyTable {...{
                                    thead: [
                                        "player",
                                        state.time_attack_format.has_position_coef ? "position" : "",
                                        state.time_attack_format.has_position_coef ? "position score" : "",
                                        state.time_attack_format.has_time_coef ? "pb time" : "",
                                        state.time_attack_format.has_time_coef ? "time score" : "",
                                        state.time_attack_format.has_time_coef && state.time_attack_format.has_position_coef ? "player score" : "",
                                    ].filter(a => Boolean(a)),
                                    tbody: data_example.map((data, i) => {
                                        let pos_score:string | number = "incorrect coef (must be > 1)";
                                        if (parseInt(state.time_attack_position_coef) >= 2) {
                                            pos_score = Math.round(100*Math.pow(2, (-(i)/(parseInt(state.time_attack_position_coef)-1))) * 10)/10
                                        }

                                        let time_score:string | number = "incorrect coef (must be >= 0.001)";
                                        if (parseFloat(state.time_attack_time_coef) >= 0.001) {
                                            time_score = Math.round(100*Math.pow(2,(-1/parseFloat(state.time_attack_time_coef)*(data.time/data_example[0].time-1)))* 10)/10
                                        }
                                        return [
                                            `player ${i + 1}`,
                                            state.time_attack_format.has_position_coef ? i + 1 : "",
                                            state.time_attack_format.has_position_coef ? pos_score : "",
                                            state.time_attack_format.has_time_coef ? PrintTime(data.time) : "",
                                            state.time_attack_format.has_time_coef ? time_score : "",
                                            //@ts-ignore
                                            state.time_attack_format.has_time_coef && state.time_attack_format.has_position_coef ? Math.round((pos_score + time_score)*10)/10 : "",
                                        ].filter(a => Boolean(a));
                                    }),
                                    theme: DarkTable,
                                    layout:{
                                        theadColumnFontSize: 13,
                                        tbodyColumnFontSize: 12,
                                        titlePadding:        "3px 10px 13px 10px",
                                    },
                                }} />
                            </>
                        ) : null }
                        <hr className={css(GenericStyles.divider)} />

                        <h2 className={css(GenericStyles.section_title)}>Date range</h2>
                        <div className={css(GenericStyles.section_title_description)}>
                            If "End at" specified TA leaderboard will become frozen right after this time
                        </div>
                        <section className={css(GenericStyles.section)}>
                            <label className={css(GenericStyles.section_label)}>Start at (UTC)</label>
                            <input {...{
                                type:      "datetime-local",
                                min:       moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
                                className: css(MakeInput(PrimaryInput, { width: "100%" })),
                                value:     state.time_attack_start_at ? moment.unix(state.time_attack_start_at).utc().format(moment.HTML5_FMT.DATETIME_LOCAL): "",
                                onChange:  (e) => {
                                    setState({
                                        time_attack_start_at: {$set: moment.utc(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL).unix() }
                                    }) 
                                }
                            }}/>
                        </section>
                        <section className={css(GenericStyles.section)}>
                            <label className={css(GenericStyles.section_label)}>End at (UTC)</label>
                            <input {...{
                                type:      "datetime-local",
                                min:       moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
                                className: css(MakeInput(PrimaryInput, { width: "100%" })),
                                value:     state.time_attack_end_at ? moment.unix(state.time_attack_end_at).utc().format(moment.HTML5_FMT.DATETIME_LOCAL): "",
                                onChange:  (e) => {
                                    setState({
                                        time_attack_end_at: {$set: moment.utc(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL).unix() }
                                    }) 
                                }
                            }}/>
                        </section>
                        <section className={css(GenericStyles.section)}>
                            <label className={css(GenericStyles.section_label)}>Current status</label>
                            <div className={css(Styles.status)}>
                                { tournament.is_upading_snapshots_stopped && tournament.snapshots_created_at ? 
                                    `frozen ${moment.unix(tournament.snapshots_created_at).fromNow()} ` :
                                    tournament.campaign ? "updating " : "idle (no campaign selected) "
                                }
                                { tournament.is_upading_snapshots_stopped && tournament.campaign && (
                                    <LoadButton {...{
                                        theme:   DangerFilled,
                                        loading: updatingLeaderboard,
                                        onClick: () => {
                                            showWarning(<span>Updating leaderboard will <strong>overwrite</strong> current state. Continue?</span>, WarningLight, {
                                                title:   "WARNING",
                                                buttons: [{
                                                    text:      "YES",
                                                    className: css(MakeButton(DangerFilled)),
                                                    onClick:   async () => {
                                                        setUpdatingLeaderboarad(true);
                                                        const err = await UpdateLeaderboard(tournament.uri);
                                                        if (err != null) {
                                                            alert(err.text);
                                                        }

                                                        setUpdatingLeaderboarad(false);
                                                        onSave();
                                                    }
                                                }]
                                            })
                                        },
                                        layout: {
                                            padding:    "3px 10px",
                                            fontWeight: 400,
                                        }
                                    }}>
                                        Upadte leaderboard one time
                                    </LoadButton>
                                ) }
                            </div>
                        </section>
                        <hr className={css(GenericStyles.divider)} />
                    </div>
                ) : undefined }
            </SmoothLine>

            <footer className={css(Styles.footer)}>
                <LoadButton {...{
                    theme:   DangerFilled,
                    loading: isSaving,
                    onClick: onClose,
                }}>
                    Close w/o saving
                </LoadButton>
                <LoadButton {...{
                    theme:     PrimaryFilled,
                    loading:   isSaving,
                    className: css(Styles.button),
                    onClick:   OnClickSave,
                }}>
                    Save
                </LoadButton>
            </footer>
        </section>
    )
}

const Styles = CreateScheet({
    footer: {
        display:        "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: 15,
    },
    format_desc: {
        fontSize:   13,
        paddingTop: 6,
    },
    status: {
        fontFamily: "Montserrat",
        fontSize:   14,
        fontWeight: 600,
        fontStyle:  "italic",
    }
});