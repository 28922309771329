import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { session } from "packages/session/session";
import { Fragment, useState } from "react";
import { LoadButton, MakeButton, DangerFilled } from "src/themes/button";
import SignInBlock from "src/jsx/modules/SignInBlock.react";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme } from "src/themes/lightbox";
import RegisterPlayer from "packages/tournaments/API/RegisterPlayer";
import UnregisterPlayer from "packages/tournaments/API/UnregisterPlayer";
import { PageProps } from "../model";
import moment from "moment";

export default function RegisterBlock({ tournament, theme }: PageProps) {
    const [isLoading, setIsLoading] = useState(false);

    async function Register() {
        setIsLoading(true);

        const err = await RegisterPlayer(tournament.uri);
        if (err != null) {
            showWarning(err.text, DarkTheme);
        }

        setIsLoading(false);
    }

    async function Unregister() {
        showWarning("Are you sure you want to leave the cup?", DarkTheme, {
            buttons: [
                {
                    text: "YES",
                    className: css(MakeButton(DangerFilled)),
                    onClick: async () => {
                        setIsLoading(true);

                        const err = await UnregisterPlayer(tournament.uri);
                        if (err != null) {
                            alert(err.text);
                        }

                        setIsLoading(false);
                    },
                },
            ],
        });
    }

    return (
        <Fragment>
            {tournament.registration_start_at &&
            moment().unix() < tournament.registration_start_at ? (
                <div className={css(Styles.reg_info)}>
                    Registration starts{" "}
                    {moment.unix(tournament.registration_start_at).fromNow()}
                </div>
            ) : tournament.registration_end_at &&
              moment().unix() > tournament.registration_end_at ? (
                <div className={css(Styles.reg_info)}>Registration is over</div>
            ) : (
                <Fragment>
                    {session.user && session.user.has_uplay ? (
                        <Fragment>
                            {tournament.is_player ? (
                                <div className={css(Styles.success)}>
                                    Successfully registered!
                                    <LoadButton
                                        {...{
                                            theme: {
                                                plain: {
                                                    background: "transparent",
                                                    color: theme.get_color(
                                                        "primary"
                                                    ),
                                                },
                                                active: {
                                                    boxShadow: "none",
                                                },
                                            },
                                            loading: isLoading,
                                            layout: {
                                                fontSize: 14,
                                                fontWeight: 500,
                                                padding: "10px 10px 0 10px",
                                                fontFamily: "Montserrat",
                                            },
                                            onClick: Unregister,
                                        }}
                                    >
                                        unregister
                                    </LoadButton>
                                </div>
                            ) : (
                                <LoadButton
                                    {...{
                                        theme: {
                                            plain: {
                                                background:
                                                    theme.get_color("primary"),
                                                color: "white",
                                            },
                                            hover: {
                                                boxShadow: `0 8px 25px -8px ${theme.get_color(
                                                    "primary"
                                                )}`,
                                            },
                                            active: {
                                                boxShadow: "none",
                                            },
                                        },
                                        loading: isLoading,
                                        layout: {
                                            width: "100%",
                                            fontSize: 16,
                                            fontWeight: 500,
                                            fontFamily: "Montserrat",
                                        },
                                        onClick: Register,
                                    }}
                                >
                                    Register
                                </LoadButton>
                            )}
                        </Fragment>
                    ) : (
                        <div className={css(Styles.sign_in)}>
                            <p>Sign in to participate</p>
                            <SignInBlock only_uplay={true} />
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

const Styles = CreateScheet({
    sign_in: {
        textAlign: "center",
        fontSize: 14,
        fontWeight: 500,
    },
    success: {
        fontSize: 14,
        fontWeight: 500,
        textAlign: "center",
        fontFamily: "Montserrat",
    },
    reg_info: {
        fontSize: 14,
        fontWeight: 600,
        textAlign: "center",
        fontFamily: "Montserrat",
    },
});
