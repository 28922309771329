export const Haettenschweiler = {
    fontFamily: "Haettenschweiler",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('Haettenschweiler'), url('/fonts/haettenschweiler.woff') format('woff')",
};

export const Velocista = {
    fontFamily: "VELOCISTA Heavy",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('VELOCISTA Heavy'), url('/fonts/Velocista-Demo-FFP.woff') format('woff')",
};

export const Palatino = {
    fontFamily: "Palatino",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('VELOCISTA Heavy'), url('/fonts/Palatino.woff') format('woff')",
};

export const BigNoodle = {
    fontFamily: "BigNoodle",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('BigNoodleTitling'), url('/fonts/bignoodletitling.woff') format('woff')",
};

export const Benn = {
    fontFamily: "Benn",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('Benn'), url('/fonts/Benn.woff') format('woff')",
};

export const Timeline = {
    fontFamily: "Timeline",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('Timeline'), url('/fonts/Timeline.woff') format('woff')",
};

export const Desib = {
    fontFamily: "Desib",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('Desib'), url('/fonts/Desib.woff') format('woff')",
};

export const Bebas = {
    fontFamily: "Bebas",
    fontStyle: "normal",
    fontWeight: "normal",
    src: "local('Bebas'), url('/fonts/Bebas-Regular.woff') format('woff')",
};
