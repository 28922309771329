import { DraftTheme } from "packages/draftjs/Draft.theme";
import { Primary } from "./colors";

export const PrimaryDraft: DraftTheme = {
    editor_background: "transparent",
    editor_color: "inherit",
    editor_border: `1px solid ${Primary.default}`,
    nav_background: Primary.default,
    nav_border: `1px solid ${Primary.default}`,
    nav_border_bottom: "none",
    nav_item_background: Primary.default,
    nav_item_hover_background: Primary.hover,
    nav_item_color: "white",
};

export const PrimaryReadOnluDraft: DraftTheme = {
    editor_background: "transparent",
    editor_color: "inherit",
    editor_border: `none`,
    nav_background: Primary.default,
    nav_border: `none`,
    nav_border_bottom: "none",
    nav_item_background: Primary.default,
    nav_item_hover_background: Primary.hover,
    nav_item_color: "white",
};
