import { StyleDeclaration, CreateScheet } from 'aphrodite';
import { ButtonTheme, ButtonLayout } from './button.theme';
export type { ButtonTheme, ButtonLayout };

const Button = ({ disabled, ...styles }: ButtonLayout, theme: ButtonTheme) => {
    let css:StyleDeclaration = {
        userSelect: "none",
        outline: 0,
        border:  "1px solid transparent",
        boxShadow: "none",
        ...styles,
        ...theme.plain,
        ':nth-child(1n) svg': {
            fill:   theme.plain.color
        },
        ":disabled": {
            opacity:    0.6,
            cursor:     "default",
            boxShadow:  "none",
        }
    }

    if (theme.hover) {
        css = {...css, ...{
            ":hover:enabled": {
                ...theme.hover,
                ':nth-child(1n) svg': {
                    fill:   theme.hover.color,
                }
            }
        }}
    }

    if (theme.active) {
        css = {...css, ...{
            ":enabled": {
                ":active": {
                    ...theme.active,
                    ':nth-child(1n) svg': {
                        fill:   theme.active.color,
                    }
                }
            }
        }}
    }

    return CreateScheet({ base: css }).base;
};

export default Button;