import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import PageHeader from "src/jsx/modules/Header.react";
import { history } from "packages/history/history";
import { Route, Switch, useParams } from "react-router-dom";
import {
    FilterPages,
    GetPathPage,
    PageMarkers,
    PageComponents,
    GetPath,
} from "./model";
import { Fragment, useEffect, useState } from "react";
import * as runtypes from "runtypes";
import Menu from "./modules/Menu.react";
import CreateThemeStyles, { ColorsRuntype } from "packages/themes/theme";
import {
    useLoadTorunament,
} from "packages/tournaments/API/LoadTournament";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { WithBG } from "packages/aphrodite/WithBG";
import ManagerPanel from "./modules/ManagerPanel.react";
import { Danger, Dark } from "src/themes/colors";
import { showLightbox } from "packages/lightbox/lightbox";
import { DarkTheme } from "src/themes/lightbox";
import TournamentAdministration from "./forms/Administration.react";
import { GetSpinnerTheme } from "packages/themes/components";
import { PageProps } from "src/runtime/pages";
import { ImagesRuntype } from "packages/tournaments/model";
import { Bebas } from "../fonts";

export const TournamentPageMetaRuntype = runtypes
    .Record({
        theme: ColorsRuntype.Or(runtypes.Null),
        images: ImagesRuntype,
    })
    .Or(runtypes.Null);
export type TournamentPageMeta = runtypes.Static<
    typeof TournamentPageMetaRuntype
>;

export default function Tournament(
    props: PageProps<TournamentPageMeta> & {
        uri?: string;
        prefix?: string;
    }
) {
    const [currentPage, setCurrentPage] = useState<PageMarkers>(
        GetPathPage(history.location.pathname, props.prefix) || "landing"
    );
    const { uri: pathURI } = useParams<{ uri: string }>();
    const uri = props.uri ? props.uri : pathURI;
    const [state] = useLoadTorunament(true, uri);
    const [isLoadingImage, setIsLoadingImage] = useState(true);

    const colors = state.payload?.theme
        ? state.payload.theme
        : props.meta.payload?.theme
        ? props.meta.payload.theme
        : {
              primary: "#23272A",
              secondary: "#2C2F33",
              text: "black",
              background_one: "#23272A",
              background_two: "white",
          };

    if (!colors.text) {
        colors.text = "black";
    }
    if (!colors.background_one) {
        colors.background_one = "black";
    }
    if (!colors.background_two) {
        colors.background_two = "white";
    }
    const theme = CreateThemeStyles(colors);

    function OpenAdministration() {
        if (state.payload && state.payload.is_manager) {
            showLightbox({
                selector: "edit-tournament",
                content: {
                    tournament: state.payload,
                },
                Component: TournamentAdministration,
                theme: DarkTheme,
            });
        }
    }

    useEffect(() => {
        setCurrentPage(
            GetPathPage(history.location.pathname, props.prefix) || "landing"
        );
        // eslint-disable-next-line
    }, [history.location.pathname]);

    useEffect(() => {
        setIsLoadingImage(true);
        if (props.meta.payload?.images?.logo) {
            const logoImage = new Image();
            logoImage.onload = function () {
                setIsLoadingImage(false);
            };
            logoImage.onerror = () => {
                setIsLoadingImage(false);
            };
            logoImage.src = props.meta.payload?.images.logo;
        } else {
            setIsLoadingImage(false);
        }
    }, [props.meta.payload?.images?.logo]);

    const pages = FilterPages(state.payload);

    return (
        <article
            {...{
                className: css(
                    Styles.page,
                    props.meta.payload?.images?.background
                        ? WithBG(
                              theme.get_color("background_one"),
                              `url(${props.meta.payload?.images.background})`
                          )
                        : theme.background("background_one")
                ),
            }}
        >
            {state.payload?.is_manager && (
                <ManagerPanel
                    {...{
                        uri,
                        theme,
                        tournament: state.payload,
                    }}
                />
            )}
            <PageHeader simple={true} dark={true} />
            {state.payload && !state.payload.is_public && (
                <div className={css(Styles.top_warning)}>
                    this cup is only visible for managers:{" "}
                    <strong onClick={OpenAdministration}>make public</strong>
                </div>
            )}
            {state.error && (
                <>
                    <br />
                    <br />
                    <br />
                    <section className={css(Styles.body)}>
                        <div className={css(Styles.error)}>
                            {state.error.text}
                        </div>
                    </section>
                </>
            )}
            <WithSpinner
                {...{
                    theme: GetSpinnerTheme(theme),
                    suspense:
                        state.isLoading ||
                        isLoadingImage ||
                        props.meta.is_loading,
                    height: 150,
                    grow: true,
                    minDuration: 500,
                    className: Styles.spinner,
                }}
            >
                {state.payload && (
                    <div className={css(Styles.payload)}>
                        <header className={css(Styles.header)}>
                            {state.payload.images?.logo ? (
                                <img
                                    {...{
                                        src: state.payload.images.logo,
                                        className: css(Styles.logo),
                                        alt: state.payload.title,
                                    }}
                                />
                            ) : (
                                <h1
                                    className={css(
                                        Styles.title,
                                        uri === "tma-2" &&
                                            Styles.titile_campaing
                                    )}
                                >
                                    {uri === "tma-2" ? (
                                        <>
                                            TMA CAMPAIGN{" "}
                                            <span
                                                className={css(
                                                    theme.color("primary")
                                                )}
                                            >
                                                VOL2
                                            </span>
                                        </>
                                    ) : (
                                        state.payload.title
                                    )}
                                </h1>
                            )}
                            {state.payload.short_description && (
                                <div className={css(Styles.short_desc)}>
                                    {state.payload.short_description}
                                </div>
                            )}
                        </header>
                        <section
                            id="tournament-body"
                            className={css(
                                Styles.body,
                                theme.background("background_two"),
                                theme.color("text"),
                                LinksColor(theme.get_color("secondary"))
                            )}
                        >
                            {state.payload ? (
                                <Fragment>
                                    <Menu
                                        {...{
                                            current_page: currentPage,
                                            theme,
                                            pages,
                                            uri,
                                            prefix: props.prefix,
                                        }}
                                    />
                                    <Switch>
                                        {pages.map((page) => {
                                            const Component =
                                                PageComponents[page.marker];
                                            if (!state.payload) return null;

                                            return (
                                                <Route
                                                    {...{
                                                        key: page.marker,
                                                        path: `/${GetPath(
                                                            uri,
                                                            page.url,
                                                            undefined,
                                                            props.prefix
                                                        )}`,
                                                        exact: true,
                                                    }}
                                                >
                                                    <Component
                                                        {...{
                                                            tournament:
                                                                state.payload,
                                                            theme,
                                                            prefix: props.prefix,
                                                        }}
                                                    />
                                                </Route>
                                            );
                                        })}
                                    </Switch>
                                </Fragment>
                            ) : null}
                        </section>
                    </div>
                )}
            </WithSpinner>
        </article>
    );
}

const LinksColor = (color: string) =>
    CreateScheet({
        base: {
            ":nth-child(1n) a": {
                color: color,
            },
        },
    }).base;

const Styles = CreateScheet({
    page: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        fontFamily: "Ubuntu",
        transition: "background-image 1s, background 1s",
    },
    header: {
        padding: "70px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    payload: {
        paddingBottom: 40,
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 150,
    },
    top_warning: {
        color: Danger.default,
        textAlign: "center",
        backgroundColor: "rgba(255,255,255,0.9)",
        borderTob: `1px solid ${Dark.default}`,
        borderBottom: `1px solid ${Dark.default}`,
        position: "fixed",
        fontSize: 14,
        padding: "10px 5px",
        top: 60,
        left: 0,
        right: 0,
        fontWeight: 600,
        fontFamily: "Ubuntu",
        "& strong": {
            textDecoration: "underline",
            ":hover": {
                cursor: "pointer",
                fontWeight: 800,
            },
        },
    },
    logo: {
        width: 400,
    },
    title: {
        margin: 0,
        padding: 0,
        fontWeight: 700,
        textAlign: "center",
        cursor: "pointer",
        color: "white",
        fontFamily: "Montserrat",
        fontSize: 50,
        textShadow:
            "1px 1px rgb(0 0 0 / 10%), 2px 2px rgb(0 0 0 / 9%), 3px 3px rgb(0 0 0 / 8%), 4px 4px rgb(0 0 0 / 7%), 5px 5px rgb(0 0 0 / 6%), 6px 6px rgb(0 0 0 / 5%), 7px 7px rgb(0 0 0 / 4%), 8px 8px rgb(0 0 0 / 3%), 9px 9px rgb(0 0 0 / 2%), 10px 10px rgb(0 0 0 / 1%)",
    },
    titile_campaing: {
        fontFamily: [Bebas],
        fontSize: 70,
    },
    titile_campaing_sub: {},
    body: {
        margin: "0 auto",
        maxWidth: 700,
        fontSize: 14,
        borderRadius: 10,
        width: "100%",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        position: "relative",
        transition: "max-width 0.3s",
        "@media (max-width: 700px)": {
            borderRadius: 0,
        },
    },
    silent_update: {
        position: "absolute",
        top: 50,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255,255,255)",
        zIndex: 100,
        opacity: 0.5,
        padding: "20px",
    },
    error: {
        fontFamily: "Montserrat",
        fontSize: 20,
        textAlign: "center",
        padding: "10px 0",
    },
    short_desc: {
        fontFamily: "Montserrat",
        color: "white",
        fontSize: 30,
        marginTop: 20,
        fontWeight: 300,
    },
});
