import { CreateScheet } from "aphrodite";
import { Gray, White } from "src/themes/colors";
import { TableLayout, TableTheme } from "./table.theme";

export function MakeTableCSS(theme: TableTheme, layout: TableLayout) {
    return CreateScheet({
        container: {
            width: layout.width || "100%",
            maxWidth: layout.maxWidth || "auto",
            border: layout.border || "none",
            borderRadius: layout.borderRadius || 0,
        },
        title: {
            fontSize: layout.titleFontSize || 16,
            borderBottom:
                layout.titleBorderBottom || `1px solid ${Gray.default}`,
            padding: layout.titlePadding || "10 0",
        },
        table: {
            width: "100%",
            padding: layout.tbodyPadding || 0,
        },
        trow: {
            background: theme.trowBackground || White.default,
            ":nth-child(even)": {
                background: theme.trowEvenBackground || White.default,
            },
            ":hover": {
                background: theme.trowHoverBackground || White.default,
            },
        },
        thead: {
            backgroundColor: theme.theadBackground || White.default,
            lineHeight: layout.theadLineHeight || "normal",
            color: theme.theadColor || "black",
            ":nth-child(1n) th": {
                borderBottom: layout.theadColumnBorderBottom || "none",
            },
        },
        tbody: {
            color: theme.tbodyColor || "black",
        },
        theadColumn: {
            textAlign: layout.theadColumnTextAlign || "left",
            padding:
                typeof layout.theadColumnPadding !== "undefined"
                    ? layout.theadColumnPadding
                    : 10,
            fontSize: layout.theadColumnFontSize || 16,
        },
        tbodyColumn: {
            borderTop: layout.tbodyColumnBorderTop || `none`,
            padding:
                typeof layout.tbodyColumnPadding !== "undefined"
                    ? layout.tbodyColumnPadding
                    : 10,
            fontSize: layout.tbodyColumnFontSize || 16,
        },
        clickable: {
            cursor: "pointer",
        },
    });
}

export function MakeColumnStyles(size: number | "auto") {
    return CreateScheet({
        base: {
            width: typeof size == "number" ? size : "auto",
        },
    }).base;
}

export function SortableHeaderStyles(
    theme: TableTheme,
    direction: "ASC" | "DESC" | false
) {
    const indicatorColor = theme.sortIndicatorColor || "#000";
    return CreateScheet({
        main: {
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
        },
        indicator: {
            marginLeft: 10,
            display: direction ? "block" : "none",
            width: "0",
            height: "0",
            borderStyle: "solid",
            borderWidth:
                direction === "DESC" ? "6px 6px 0 6px" : " 0 6px 6px 6px",
            borderColor:
                direction === "DESC"
                    ? `${indicatorColor} transparent transparent transparent`
                    : `transparent transparent ${indicatorColor} transparent`,
        },
    });
}
