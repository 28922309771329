import { CreateScheet, css } from "aphrodite";
import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import { Tournament } from "packages/tournaments/model";
import GenericStyles from "src/jsx/forms/GenericStyles";
import { MakeInput, PrimaryInput } from "src/themes/input";
import useSafeState from "packages/hooks/useSafeState";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { PrimaryDraft } from "src/themes/draftjs";
import useSimpleError from "packages/hooks/useSimpleError";
import SmoothLine from "packages/motion/SmoothLine.react";
import { PrimaryFilled, LoadButton, DangerFilled } from "src/themes/button";
import { useState } from "react";
import UpdateTournament from "packages/tournaments/API/UpdateTournament";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme } from "src/themes/lightbox";

export default function TournamentEditor({ content, close }:ComponentProps<{
    tournament: Tournament,
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header>
                Edit tournament
            </Header>
            <Body>
                <TournamentEditorForm {...content} {...{ onClose: close, onSave: close }} />
            </Body>
        </Lightbox>
    )
}

function TournamentEditorForm({
    tournament,
    onSave,
    onClose,
}:{
    tournament: Tournament,
    onSave:     () => void,
    onClose:    () => void,
}) {
    const [ state, setState ] = useSafeState<{
        title:                   string,
        short_description:       string | null,
        description:             string | null,
        rules:                   string | null,
    }>({
        title:                   tournament.title,
        short_description:       tournament.short_description,
        description:             tournament.description,
        rules:                   tournament.rules,
    });
    const [ hasErr, setErr ] = useSimpleError(["empty-title"])
    const [ isSaving, setIsSaving ] = useState(false);

    async function OnClickSave() {
        if (tournament.title.length === 0) {
            setErr("empty-title")
        } else {
            setIsSaving(true);

            const err = await UpdateTournament(tournament.uri, state)

            setIsSaving(false);
            if (err !== null) {
                showWarning(err.text, DarkTheme);
            } else {
                onSave();
            }
        }
    }

    return (
        <section>
            <h2 className={css(GenericStyles.section_title)}>General</h2>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>Title</label>
                <input {...{
                    placeholder: "title",
                    className:   css(MakeInput(PrimaryInput, { width: "100%", error: hasErr("empty-title") })),
                    value:       state.title,
                    onChange:    (e) => { setState({title: {$set: e.target.value }}) }
                }}/>
                <SmoothLine>
                    { hasErr("empty-title") ? "title is required" : undefined }
                </SmoothLine>
            </section>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>Short description <span className={css(GenericStyles.section_label_small)}>(max 255 chars)</span></label>
                <input {...{
                    placeholder: "short description",
                    className:   css(MakeInput(PrimaryInput, { width: "100%" })),
                    value:       state.short_description || "",
                    onChange:    (e) => { e.target.value.length < 256 && setState({short_description: {$set: e.target.value }}) }
                }}/>
            </section>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>Description</label>
                <DraftEditorReact {...{
                    layout:          {editor_min_height:  100},
                    theme:           PrimaryDraft,
                    defaultValue:    state.description || "",
                    onContentChange: draft => {
                        setState({description: {$set: draft }})
                    },
                }}/>
            </section>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>Rules</label>
                <DraftEditorReact {...{
                    layout:          {editor_min_height:  100},
                    theme:           PrimaryDraft,
                    defaultValue:    state.rules || "",
                    onContentChange: draft => {
                        setState({rules: {$set: draft }})
                    },
                }}/>
            </section>
            <hr className={css(GenericStyles.divider)} />

            <footer className={css(Styles.footer)}>
                <LoadButton {...{
                    theme:   DangerFilled,
                    loading: isSaving,
                    onClick: onClose,
                }}>
                    Close w/o saving
                </LoadButton>
                <LoadButton {...{
                    theme:     PrimaryFilled,
                    loading:   isSaving,
                    className: css(Styles.button),
                    onClick:   OnClickSave,
                }}>
                    Save
                </LoadButton>
            </footer>
        </section>
    )
}

const Styles = CreateScheet({
    footer: {
        display:        "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: 15,
    }
});