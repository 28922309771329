import { MakeAnimation } from "packages/aphrodite/aphrodite";

const wobbleVertical = MakeAnimation({
    '0%,100%': {
        transform: 'translateY(0%)',
        transformOrigin: '50% 50%'
    },
    '15%': {
        transform: 'translateY(-30px) rotate(-6deg)'
    },
    '30%': {
        transform: 'translateY(15px) rotate(6deg)'
    },
    '45%': {
        transform: 'translateY(-15px) rotate(-3.6deg)'
    },
    '60%': {
        transform: 'translateY(9px) rotate(2.4deg)'
    },
    '75%': {
        transform: 'translateY(-6px) rotate(-1.2deg)'
    }
});

export default wobbleVertical;