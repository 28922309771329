import { css, CreateScheet } from "aphrodite";
import { MakeButton, UbiFilled, DiscordFilled } from "src/themes/button";
import { ReactComponent as Discord } from "src/icons/discord-text.svg";
import { ReactComponent as Ubisoft } from "src/icons/ubi-connect.svg";
import { Fragment } from "react";

export default function SignInBlock({ only_uplay }: { only_uplay?: boolean }) {
    return (
        <Fragment>
            <button
                {...{
                    className: css(
                        MakeButton(UbiFilled, { borderRadius: 0 }),
                        Styles.button
                    ),
                    onClick: () => {
                        window.location.href = `/api/v1/auth/ubi?redirect=${window.location.pathname}`;
                    },
                }}
            >
                <Ubisoft className={css(Styles.ubi_icon)} />
            </button>
            {!only_uplay && (
                <>
                    <br />
                    <button
                        {...{
                            className: css(
                                MakeButton(DiscordFilled),
                                Styles.button
                            ),
                            onClick: () => {
                                window.location.href = `/api/v1/auth/discord?redirect=${window.location.pathname}`;
                            },
                        }}
                    >
                        <Discord className={css(Styles.discord_icon)} />
                    </button>
                </>
            )}
        </Fragment>
    );
}

const Styles = CreateScheet({
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 40,
        width: "100%",
    },
    discord_icon: {
        height: 25,
        width: 100,
    },
    ubi_icon: {
        height: 14,
    },
});
