// styles
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
// components
import Dropdown, { DropdownBaseFunctions } from "packages/dropdowns/dropdownBase.react";
import useDebounce from "packages/hooks/useDebounce";
import { SessionUser } from "packages/session/model";
import Spinner from "packages/spinners/spinner.react";
import { useRef, useState } from "react";
// icons
import { ReactComponent as CrossIcon } from 'src/icons/cross.svg';
import { ReactComponent as ArrowIcon } from 'src/icons/down-arrow.svg';
import { Danger, Dark, White } from "src/themes/colors";
import { MakeInput, PrimaryInput } from "src/themes/input";
import { PurpleithPath } from "src/themes/spinner";
import { useSearchUsers } from "../API/SearchUsers";

export default function UserPicker({
    active,
    setActive,
}: {
    active?:   SessionUser,
    setActive: (user?: SessionUser) => void,
}) {
    const [ searchKey, setSearchKey ] = useState("");
    const [ State, reload, setPayload ] = useSearchUsers(false, searchKey);
    const reloadList = useDebounce(reload, 300)
    const triggers = useRef<DropdownBaseFunctions>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    function onChangeKey(key: string) {
        setSearchKey(key);
        triggers.current?.toggleDropElement(true)
        reloadList(key);
    }

    const onItemClick = (user: SessionUser) => {
        setSearchKey("");
        setPayload([]);
        setActive(user);
    }

    function dropActive() {
        if (active) {
            setActive(undefined);
            inputRef.current?.focus();
        }
    }

    return (
        <Dropdown {...{
            layout: {
                wrapper: {
                    zIndex: 5,
                }
            },
            triggers: triggers,
            header: (
                <div className={ css(Styles.header) } onClick={ dropActive }>
                    <div className={ css(MakeInput(PrimaryInput), Styles.header) }>
                        { !active ? (
                        <input {...{
                            className:   css(MakeInput(PrimaryInput), Styles.input),
                            value:       searchKey,
                            onChange:    e => { onChangeKey(e.target.value) },
                            ref:         inputRef,
                            placeholder: "Start typing to search users",
                        }} />
                        ) : (
                            <div className={ css(MakeInput(PrimaryInput), Styles.input, Styles.user) }>
                                <span>{active.full_name}</span>
                                <small className={css(Styles.userSmall)}>
                                    {active.has_uplay ? "uplay" : "discord"}
                                </small>
                            </div>
                        ) }
                    </div>
                    { State.isLoading ? (
                        <Spinner {...{
                            height: 16,
                            theme:  PurpleithPath,
                            styles: Styles.headerIcon
                        }} />
                    ) : (
                        <>
                            { !active ? (
                                <ArrowIcon width="16" height="16" className={css(Styles.headerIcon, Styles.arrowDown)}/>
                            ) : (
                                <CrossIcon width="16" height="16" className={css(Styles.headerIcon, Styles.arrowDown)}/>
                            )}
                        </>
                    ) }
                </div>
            ),
            dropElement: () => (
                <>
                    {(State.payload && !Boolean(active)) && (
                        <ul className={css(Styles.dropElement)}>
                            { State.payload && !Boolean(active) ? (
                                <>
                                    { !State.isLoading && searchKey.length > 0 && State.payload?.length === 0 ? (
                                        <li {...{
                                            className: css(Styles.dropItem, Styles.no_resuts),
                                        }}>
                                            Nothing found for this key
                                        </li>
                                    ) : State.payload.map((item, index) => (
                                        <li {...{
                                            key:       index,
                                            className: css(Styles.dropItem, Styles.user),
                                            onClick:   () => {
                                                triggers.current?.toggleDropElement();
                                                onItemClick(item);
                                            },
                                        }}>
                                            <span>{item.full_name}</span>
                                            <small className={css(Styles.userSmall)}>
                                                {item.has_uplay ? "uplay" : "discord"}
                                            </small>
                                        </li>
                                    )) }
                                </>
                            ) : null }
                        </ul>
                    )}
                </>
            )
        }}/>
    )
}

const Styles = CreateScheet({
    header: {
        position: "relative",
        width: "100%",
        padding: 0,
    },
    error: {
        borderColor: Danger.default,
    },
    header_elm: {
        flexGrow: 1,
        display:  "flex",
        position: "relative",
    },
    arrowDown: {
        fill:       Dark.default,
    },
    input: {
        width: "100%",
        padding: "10px 40px 10px 15px",
        border: "none",
        margin: 0,
        background: "transparent",
        ":focus": {
            outline: "none",
            boxShadow: "none",
        }
    },
    headerIcon: {
        position: "absolute",
        top: "50%",
        right: 15,
        marginTop: -8,
        cursor: "pointer",
    },
    dropElement: {
        position: "absolute",
        top: "100%",
        left: 0,
        right: 0,
        background: White.default,
        listStyle: "none",
        boxShadow: `0px 10px 20px rgba(48, 48, 48, 0.15)`,
        borderRadius: 12,
        margin: 0,
        padding: "10px 15px",
        overflow: "auto",
        maxHeight: "auto",
        zIndex: 5,
    },
    dropItem: {
        padding:      "10px 15px",
        fontSize:     "1rem",
        fontWeight:   600,
        borderRadius: 5,
        cursor:       "pointer",
        color:        "black",
        ":hover":     {
            background: "#F5F5F5",
        }
    },
    no_resuts: {
        fontStyle:  "italic",
        fontWeight: 400,
        fontSize:   12,
    },
    user: {
        display:        "flex",
        alignItems:     "center",
        justifyContent: "space-between",
        fontFamily:     "Montserrat",
        color:          "black",
        fontSize:       14
    },
    userSmall: {
        fontSize: 12,
        color:    "#C1C1C1"
    }
})
