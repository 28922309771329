import { CreateScheet } from "./aphrodite";

export const WithBG = (color: string, css_image: string, size?: string) =>
    CreateScheet({
        page: {
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            "::after": {
                content: '" "',
                position: "fixed",
                left: 0,
                bottom: 0,
                right: 0,
                top: 0,
                backgroundPosition: "top center",
                backgroundRepeat: "no-repeat",
                backgroundSize: size || "cover",
                backgroundColor: color,
                backgroundImage: css_image,
                zIndex: -1,
            },
        },
    }).page;

export const WithLocalBG = (color: string, css_image: string) =>
    CreateScheet({
        page: {
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            zIndex: 1,
            "::after": {
                content: '" "',
                position: "absolute",
                left: 0,
                bottom: 0,
                right: 0,
                top: 0,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: color,
                backgroundImage: css_image,
                zIndex: -1,
            },
        },
    }).page;
