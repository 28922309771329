import { ContentBlock, ContentState } from "draft-js";
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { ModProps } from "../Mods.react";
// components
import { Lightbox, Header, Body, Footer, ComponentProps } from 'packages/lightbox/lightbox';
import { MakeInput, PrimaryInput } from "src/themes/input";
import { MakeButton, PrimaryFilled } from "src/themes/button";
import React, { useRef, useState } from "react";
import IsValidURL from "packages/helpers/IsValidURL";

export function LinkStrategy(contentBlock:ContentBlock, callback:(start:number, end:number) => void, contentState:ContentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}

export function LinkComponent({
    children,
    contentState,
    entityKey,
}:React.PropsWithChildren<ModProps>) {
    const {url} = contentState.getEntity(entityKey).getData();
    return (
        <a href={url} className={css(Styles.link)} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
}



export interface InsertLinkLightboxProps {
    onInsert: (url:string) => void
}

export default function InsertLink(data:ComponentProps<InsertLinkLightboxProps>) {
    const InputRef = useRef<HTMLInputElement>(null)
    const [ InputError, SetInputError ] = useState(false)

    function insert() {
        if (InputRef.current) {
            SetInputError(false)
            if (!IsValidURL(InputRef.current.value.trim())) {
                SetInputError(true)
            } else {
                data.content?.onInsert(InputRef.current.value.trim())
                data.close();
            }
        }
    }

    function onKeyDown(e:React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            e.preventDefault();
            insert()
        }
    }
 
    return (
        <Lightbox {...{ close: data.close }}>
            <Header>
                Insert link
            </Header>
            <Body>
                <input {...{
                    type:      "text",
                    className: css(MakeInput(PrimaryInput, { error: InputError })),
                    ref:       InputRef,
                    placeholder: "http(s)://",
                    onKeyDown,
                }}/>
            </Body>
            <Footer {...{ styles: Styles.footer }}>
                <button className={css(MakeButton(PrimaryFilled))} onClick={insert}>Insert</button>
            </Footer>
        </Lightbox>
    );
}
const Styles = CreateScheet({
    link: {

    },
    footer: {
        padding: "10px 20px",
        textAlign: "right",
        ":nth-child(1n) button": {
            marginLeft: 10,
        }
    },
})