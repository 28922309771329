import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { TournamentRuntype, Tournament } from "../model";

export default async function LoadTorunament(uri: string):Promise<ResultWithError<Tournament>> {
    const res = await get<Tournament>({ url: `tournaments/${uri}` });
    return CheckResultRuntime(TournamentRuntype, res);
}

export const useLoadTorunament = makeAPIHook(LoadTorunament);