import Spinner from 'packages/spinners/spinner.react';
import { css, CreateScheet, CSSProperties, StyleDeclaration } from 'aphrodite';
import Button, { ButtonLayout, ButtonTheme } from './button';

type props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    loading:    boolean,
    theme:      ButtonTheme,
    layout:     ButtonLayout,
    styles?:    StyleDeclaration
}

export default function LoadButton({
    children,
    loading,
    layout,
    styles,
    theme,
    ...rest
} : props) {
    const dsiabled = loading || rest.disabled
    return (
        <button {...{
            ...rest,
            disabled:   dsiabled,
            className:  `${css(
                Styles.button,
                Button({...layout, disabled: dsiabled || layout.disabled}, theme),
                styles || null,
            )} ${rest.className || ""}`
        }}>
            { children }
            { loading && (
                <aside className={ css(Styles.spinner, SpinnerBlock(theme.plain.background, layout.borderRadius)) }>
                    <Spinner {...{
                        theme: {
                            color:      theme.plain.color,
                            pathColor:  "transparent",
                        },
                        height:         "100%",
                    }} />
                </aside>
            ) }
        </button>
    );
}

const Styles = CreateScheet({
    button: {
        position: "relative",
    },
    spinner: {
        position:   "absolute",
        top:        0,
        bottom:     0,
        left:       0,
        right:      0,
    }
});

const SpinnerBlock = (color: CSSProperties["backgroundColor"], borderRadius: CSSProperties["borderRadius"]) => CreateScheet({
    base: {
        borderRadius:       borderRadius,
        backgroundColor:    color ? color : "white",
    }
}).base