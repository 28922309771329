import { useReducer, useRef } from "react";

export default function useSimpleError<T extends string>(_: T[]):[
    (err?: T)                => boolean,
    (err: T, drop?: "clear") => Array<T>,
] {
    const errs = useRef<Array<T>>([]);
    const [, forceUpdate] = useReducer(x => x > 100 ? 0 : x + 1, 0);

    function has(err?: T):boolean {
        if (err) {
            return Boolean(errs.current.find(e => e.toLocaleLowerCase() === err.toLocaleLowerCase()));
        }

        return errs.current.length > 0;
    }

    function set(err: T, drop?: "clear"):Array<T> {
        const hasErr = has(err);
        if (drop && hasErr) {
            errs.current = errs.current.filter(e => e.toLocaleLowerCase() !== err.toLocaleLowerCase());
            forceUpdate();
        } else if (!drop && !hasErr) {
            errs.current = [...errs.current, err];
            forceUpdate();
        }

        return errs.current;
    }

    return [ has, set ];
}