import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { OpenPage } from "packages/history/history";
import { ReactComponent as LogoIcon } from "src/icons/tma.svg";
import { ReactComponent as MenuIcon } from "src/icons/menu.svg";
import useMaxWidth from "packages/hooks/useMaxWidth";
import { useEffect, useRef, useState } from "react";
import { session, SignOut } from "packages/session/session";
import { BigNoodle } from "../fonts";
import { showLightbox } from "packages/lightbox/lightbox";
import SignIn from "./SignIn.react";
import { DarkTheme } from "src/themes/lightbox";
import useOutsideClick from "packages/hooks/useOutsideClick";

export default function Header({
    simple,
    dark,
    blured,
    withDelta,
}: {
    simple?: boolean;
    dark?: boolean;
    blured?: boolean;
    withDelta?: boolean;
}) {
    const isLess650 = useMaxWidth(650);
    const isLess490 = useMaxWidth(490);
    const isLess400 = useMaxWidth(400);
    const userDD = useRef<HTMLDivElement>(null);
    useOutsideClick(userDD, () => {
        if (ShowInfo) {
            SetShowInfo(false);
        }
    });

    const [IsScrolled, SetIsScrolled] = useState(false);
    const [ShowInfo, SetShowInfo] = useState(false);
    const [ShowMenu, SetShowMenu] = useState(false);

    function OnScroll(e: Event) {
        if (window.pageYOffset > 30 && !IsScrolled) {
            SetIsScrolled(true);
        } else if (window.pageYOffset < 30 && IsScrolled) {
            SetIsScrolled(false);
        }
    }

    function OnClickSignOut() {
        SetShowInfo(false);
        SignOut();
    }

    function OnClickSignIn() {
        showLightbox({
            selector: "sign-in",
            content: {},
            Component: SignIn,
            theme: DarkTheme,
        });
    }

    useEffect(() => {
        window.addEventListener("scroll", OnScroll);

        return () => {
            window.removeEventListener("scroll", OnScroll);
        };
    });

    return (
        <header className={css(Styles.header)}>
            <section
                className={css(
                    Styles.header_block,
                    (IsScrolled || dark) && Styles.is_scrolled,
                    blured && Styles.blured
                )}
            >
                <nav className={css(Styles.menu)}>
                    <a
                        className={css(Styles.logo)}
                        onClick={OpenPage("")}
                        href="/"
                    >
                        <LogoIcon className={css(Styles.logo_icon)} />
                        {withDelta && (
                            <>
                                <div className={css(Styles.ft)}>ft.</div>
                                <a
                                    href="https://www.projectdelta.club/"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <img
                                        className={css(Styles.delta)}
                                        src="/images/delta.png"
                                        alt="delta"
                                    />
                                </a>
                            </>
                        )}
                    </a>
                    <div className={css(Styles.grow)} />
                    <ul className={css(Styles.list)}>
                        {!simple && !isLess490 && (
                            <li className={css(Styles.item)}>
                                <a
                                    className={css(Styles.link)}
                                    onClick={OpenPage("campaign")}
                                    href="/campaign"
                                >
                                    Campaign
                                </a>
                            </li>
                        )}
                        {!simple && !isLess400 && (
                            <li className={css(Styles.item)}>
                                <a
                                    className={css(Styles.link)}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://discord.gg/b8MfZsYFWg"
                                >
                                    Discord
                                </a>
                            </li>
                        )}
                        {session.user ? (
                            <li className={css(Styles.item)}>
                                <div
                                    className={css(Styles.dd_item)}
                                    ref={userDD}
                                >
                                    <div
                                        className={css(Styles.user_block)}
                                        onClick={() => {
                                            SetShowInfo((s) => !s);
                                        }}
                                    >
                                        {session.user.full_name[0]}
                                    </div>
                                    {ShowInfo && (
                                        <div className={css(Styles.dd_block)}>
                                            account:{" "}
                                            <strong>
                                                {session.user.full_name}
                                            </strong>
                                            <div
                                                {...{
                                                    className: css(
                                                        Styles.sign_out
                                                    ),
                                                    onClick: OnClickSignOut,
                                                }}
                                            >
                                                Sign out
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </li>
                        ) : /*(
                            <li
                                className={css(Styles.item)}
                                onClick={OnClickSignIn}
                            >
                                Sign In
                            </li>
                        ) */ null}
                        <li className={css(Styles.item)}>
                            <a
                                className={css(Styles.link)}
                                href="https://merch.tma.gg/"
                            >
                                Merch
                            </a>
                        </li>
                        {!simple && isLess650 && (
                            <li className={css(Styles.item)}>
                                <div
                                    className={css(Styles.dd_item)}
                                    onClick={() => {
                                        SetShowMenu((s) => !s);
                                    }}
                                >
                                    <MenuIcon
                                        className={css(Styles.menu_icon)}
                                    />
                                    {ShowMenu && (
                                        <ul className={css(Styles.dd_block)}>
                                            {isLess400 && (
                                                <li
                                                    className={css(
                                                        Styles.menu_item
                                                    )}
                                                >
                                                    <span
                                                        className={css(
                                                            Styles.link
                                                        )}
                                                        onClick={() => {
                                                            window.open(
                                                                "https://discord.gg/b8MfZsYFWg"
                                                            );
                                                        }}
                                                    >
                                                        Discord
                                                    </span>
                                                </li>
                                            )}
                                            {isLess490 && (
                                                <li
                                                    className={css(
                                                        Styles.menu_item
                                                    )}
                                                >
                                                    <span
                                                        className={css(
                                                            Styles.link
                                                        )}
                                                        onClick={OpenPage(
                                                            "campaign"
                                                        )}
                                                    >
                                                        Campaign
                                                    </span>
                                                </li>
                                            )}
                                            <li
                                                className={css(
                                                    Styles.menu_item
                                                )}
                                            >
                                                <span
                                                    className={css(Styles.link)}
                                                    onClick={OpenPage(
                                                        "showcase"
                                                    )}
                                                >
                                                    Showcase
                                                </span>
                                            </li>
                                            <li
                                                className={css(
                                                    Styles.menu_item
                                                )}
                                            >
                                                <span
                                                    className={css(Styles.link)}
                                                    onClick={OpenPage(
                                                        "graphics"
                                                    )}
                                                >
                                                    Graphics
                                                </span>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </li>
                        )}
                    </ul>
                </nav>
            </section>
        </header>
    );
}

const Styles = CreateScheet({
    header: {
        width: "100%",
        paddingBottom: 60,
        color: "white",
    },
    header_block: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        height: 60,
        "backdrop-filter": "blur(2px)",
        flexShrink: 0,
        zIndex: 100,
        transition:
            "background-color 0.2s, border-bottom 0.2s, box-shadow 0.2s, backdrop-filter 0.2s",
    },
    is_scrolled: {
        backgroundColor: "rgb(25, 23, 22, 0.5)",
        boxShadow:
            "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px",
        "backdrop-filter": "blur(5px)",
    },
    blured: {
        backgroundColor: "rgb(25, 23, 22, 0.1)",
        "backdrop-filter": "blur(10px)",
    },
    menu: {
        maxWidth: 900,
        margin: "0 auto",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        padding: "0 20px",
        height: "100%",
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        "& svg": {
            fill: "white",
            transition: "all 0.1s",
        },
        ":hover": {
            "& svg": {
                fill: "rgba(255,255,255,0.7)",
            },
        },
    },
    logo_icon: {
        height: 24,
    },
    list: {
        listStyle: "none",
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 14,
        fontWeight: 600,
        fontFamily: "Ubuntu, Arial, sans-serif",
        height: "100%",
    },
    item: {
        margin: "0 15px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        textShadow: "1px 1px rgb(0 0 0 / 30%)",
        ":last-child": {
            marginRight: 0,
        },
        "& a": {
            color: "white",
            transition: "all 0.1s",
        },
        ":hover": {
            "& a": {
                color: "rgba(255,255,255,0.7)",
            },
        },
    },
    link: {
        textDecoration: "none",
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
    dd_item: {
        position: "relative",
        whiteSpace: "nowrap",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    user_block: {
        fontFamily: [BigNoodle],
        background: "#d86a04",
        height: 30,
        width: 30,
        borderRadius: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
        userSelect: "none",
    },
    dd_block: {
        position: "absolute",
        top: "100%",
        right: 0,
        padding: 12,
        wordBreak: "keep-all",
        fontFamily: "Roboto",
        backgroundColor: "white",
        color: "black",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        textShadow: "none",
        borderRadius: 6,
        fontWeight: 400,
        textAlign: "right",
        cursor: "default",
        fontSize: 13,
        listStyle: "none",
        margin: 0,
    },
    sign_out: {
        fontWeight: 500,
        marginTop: 10,
        wordBreak: "keep-all",
        borderTop: `1px solid gray`,
        paddingTop: 10,
        cursor: "pointer",
        userSelect: "none",
        ":hover": {
            textDecoration: "underline",
        },
    },
    menu_icon: {
        fill: "white",
        height: 30,
        width: 30,
    },
    menu_item: {
        color: "black",
        fontWeight: 500,
        padding: "5px 0",
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        ":hover": {
            textDecoration: "underline",
        },
        ":last-child": {
            paddingBottom: 0,
            borderBottom: "none",
        },
    },
    ft: {
        margin: "0 5px",
        color: "white",
        fontSize: 12,
        marginTop: 13,
    },
    delta: {
        height: 28,
        marginTop: 5,
    },
});
