import * as r from 'runtypes';

export const MiniCampaignRuntype = r.Record({
    club_id:     r.Number,
    campaign_id: r.Number,
    name:        r.String,
    club_name:   r.String,
})
export type MiniCampaign = r.Static<typeof MiniCampaignRuntype>

export const MiniCampaignsRuntype = r.Array(MiniCampaignRuntype)
export type MiniCampaigns = r.Static<typeof MiniCampaignsRuntype>