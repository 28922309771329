import { CreateScheetWithTheme, css, useTheme } from 'packages/aphrodite/aphrodite';
import React, { Fragment, JSXElementConstructor, ReactElement, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip, { Effect, Place, Type } from 'react-tooltip';
import './Tooltip.css';
import { InternalTheme, TooltipLayout, TooltipTheme } from './Tooltip.theme';

export default function Tooltip({
    theme,
    layout,
    event,
    place,
    color,
    effect,
    children,
    content,
    disabled,
    clickable,
}:React.PropsWithChildren<{
    theme:      TooltipTheme,
    event?:     "hover" | "click"
    layout?:    TooltipLayout,
    effect?:    Effect,
    place?:     Place,
    color?:     Type,
    content:    ReactElement<any, string | JSXElementConstructor<any>> | string,
    disabled?:  boolean,
    clickable?: boolean,
}>) {
    const Styles = useTheme({ theme, layout }, StylesWithTheme);

    const availableTypes = [
        'span',
        'div',
        'svg',
        'button',
        'small',
        '[object Object]',
    ]

    const childrenWithProps = React.Children.map(children, child => {
        if(React.isValidElement(child)){
            if (!availableTypes.includes(child.type.toString())) {
                throw new Error(`Child shoud be one of elements: ${availableTypes.join(", ")}`);
            }
            return React.cloneElement(child, {
                "data-tip":         typeof content === "string" ? content : ReactDOMServer.renderToStaticMarkup(content),
                "data-html":        typeof content !== "string",
                "data-place":       place || "top",
                "data-type":        color || "dark",
                "data-effect":      effect || "solid",
                "data-event":       event,
                "data-class":       css(Styles.base),
                "data-tip-disable": disabled,
            });
        }
        return child;
    });

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return (
        <Fragment>
            { childrenWithProps }
        </Fragment>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: InternalTheme) => { return {
    base: {
        color:           theme?.theme.color,
        fontSize:        "0.85714rem",
        fontWeight:      600,
        backgroundColor: theme?.theme.backgroundColor,
        borderColor:     theme?.theme.borderColor,
        boxShadow:       theme?.theme.boxShadow,
        padding:         theme?.layout?.padding || "4px 10px",
        borderRadius:    theme?.layout?.borderRadius || 4,
        borderWidth:     theme?.layout?.borderWidth || 0,
        zIndex:          10000,
        "&.place-top:after":        {
            borderTopColor: theme?.theme.backgroundColor,
        },
        "&.place-bottom:after":        {
            borderBottomColor: theme?.theme.backgroundColor,
        },
        "&.place-left:after":        {
            borderLeftColor: theme?.theme.backgroundColor,
        },
        "&.place-right:after":        {
            borderRightColor: theme?.theme.backgroundColor,
        },
    }
}})