import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import SmoothLine from "packages/motion/SmoothLine.react";
import { WHCResponse } from "packages/whc/model";
import { useState } from "react";
import RootStyles, { Colors } from "../BaseStyles";

export default function Header({
    payload,
    reload,
}: {
    payload: WHCResponse;
    reload: () => void;
}) {
    const [showMore, setShowMore] = useState(false);
    const phaseStart = payload.phases[10];
    const phaseEnd = payload.phases[100];

    return (
        <div
            className={css(
                Styles.description,
                RootStyles.card,
                !showMore && Styles.no_more
            )}
        >
            <p>
                Welcome to Hot Pursuit, a trackmania weekly hunting cup hosted
                by TMA & Project Delta.{" "}
                {!showMore && (
                    <span
                        className={css(Styles.more)}
                        onClick={() => {
                            setShowMore(true);
                        }}
                    >
                        rules and more...
                    </span>
                )}
            </p>
            <SmoothLine timeout={300}>
                {showMore ? (
                    <div>
                        <p>
                            <i>
                                Cup is in beta status at the moment, if you
                                spotted an issue please DM{" "}
                                <code>Juice#7454</code> or{" "}
                                <code>Ski Freak#8783</code>
                            </i>
                        </p>
                        <p>
                            Every Sunday at {phaseStart.start_on.slice(0, 9)}{" "}
                            UTC a new map will be released and the competition
                            begins. You need to hold the World Record for
                            specific time in order to win. Whole week seprated
                            by several phases and each phase has it's own "time
                            to hold" value. Improving your record doesn't reset
                            the timer - it's based on the player, not the
                            record.
                        </p>
                        <p>
                            Phase can switch to the next one only of there is a
                            new record after next phase start time or current
                            "time left" is greater then the next phase "time to
                            hold"
                        </p>
                        <p>
                            If there still isn't a winner by{" "}
                            {phaseEnd.start_on.slice(0, 9)} UTC, the current
                            record holder wins.
                        </p>
                        <p>Then 24 hours to chill and we go again.</p>
                        <br />
                        <h4>Prize pool</h4>
                        <p>
                            The prize pool of <strong>$15 every week</strong>{" "}
                            goes to the weekly winner. You must be at least 18
                            years old to be eligible for receiving prize money.{" "}
                            <strong>
                                At the moment the only way to receive the money
                                is through PayPal
                            </strong>
                        </p>
                        <h4>Map submission</h4>
                        <p>
                            We are open for map submissions, please check rules
                            and submit your maps to{" "}
                            <a
                                href="https://trackmania.exchange/mappack/view/2323/hot-pursuit-map-request"
                                target="_blank"
                                rel="noreferrer"
                            >
                                this TMX map pack
                            </a>
                        </p>
                        <h4>Questions?</h4>
                        <p>
                            <a href="https://discord.gg/tma">TMA discord</a>
                        </p>
                    </div>
                ) : undefined}
            </SmoothLine>
        </div>
    );
}

const Styles = CreateScheet({
    description: {
        padding: "10px 0 10px 0",
        "& p": {
            margin: 0,
            padding: "10px 30px",
        },
        "& h4": {
            margin: 0,
            padding: "15px 30px 0 30px",
            marginBottom: 0,
        },
    },
    no_more: {
        paddingBottom: 10,
    },
    more: {
        textDecoration: "underline",
        color: Colors.Secondary,
        cursor: "pointer",
        paddingLeft: 5,
        fontSize: 12,
    },
});
