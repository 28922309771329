export function GetQueryParam(param:string):[string | boolean,  string[] | null] {
    const data = (new URL(window.location.href)).searchParams.get(param);
    if (typeof data === "string") {
        return [ typeof data === "string" ? data : true, null ]
    } else {
        return [ false, null ];
    }
};

export function GetQueryParams(params:string[]):Array<string | null> {
    const res:Array<string | null> = [];
    params.forEach(param => {
        let [parsed] = GetQueryParam(param);
        res.push(typeof parsed === "string" ? parsed : null);
    })

    return res;
};

export function RecordToQuerystring(obj:Record<string, any>):string {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}