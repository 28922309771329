import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { User } from "packages/competitions/model";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import PrintNumber from "packages/utils/PrintNumber";
import { Fragment } from "react";
import { CampaignTable } from "src/themes/tables";
import { BaseStyles } from "./BaseStyles";
import moment from "moment";

export default function Landing({
    leaderboard,
    last_updated,
}: {
    leaderboard: User[];
    last_updated: number;
}) {
    return (
        <Fragment>
            <div className={css(Styles.leaderboard)}>
                <p className={css(Styles.description)}>
                    Compete on 25 maps in various styles and difficulties, built
                    by the members of TMA community. To compete you simply have
                    to drive your best times on the maps – your records will be
                    collected automatically from Nadeo servers.
                    <br />
                    <br />
                    Price pool of at least <strong>600 €</strong>!<br />
                    <br />
                    Competition took place from <strong>
                        October 29th
                    </strong>{" "}
                    until <strong>November 14th 23:59 CET</strong>.
                    <br />
                    <br />
                    <strong>Competition is over</strong>
                    <br />
                    <br />
                    <a
                        {...{
                            className: css(Styles.rules),
                            href: `campaign-vol-1/rules`,
                            onClick: OpenPage("campaign-vol-1/rules"),
                        }}
                    >
                        Rules and details
                    </a>
                </p>
                <PrettyTable
                    {...{
                        title: () => (
                            <span>
                                Leaderboard
                                <span className={css(Styles.updated_when)}>
                                    frozen{" "}
                                    <strong>
                                        {moment.unix(last_updated).fromNow()}
                                    </strong>
                                </span>
                            </span>
                        ),
                        thead: [
                            "position",
                            "player",
                            "score",
                            "records",
                            "WRs",
                        ],
                        tbody: leaderboard.map((user, i) => {
                            return [
                                i + 1,
                                () => (
                                    <strong>
                                        <a
                                            {...{
                                                href: `/campaign-vol-1/users/${user.account.name}`,
                                                onClick: OpenPage(
                                                    `campaign-vol-1/users/${user.account.name}`
                                                ),
                                                className: css(BaseStyles.link),
                                            }}
                                        >
                                            {user.account.name}
                                        </a>
                                    </strong>
                                ),
                                PrintNumber(user.score),
                                user.total_records,
                                user.total_wrs,
                            ];
                        }),
                        columnsSizes: [100, "auto", "auto", "auto", "auto"],
                        theme: CampaignTable,
                        layout: {
                            theadColumnFontSize: 15,
                            tbodyColumnFontSize: 13,
                            tbodyPadding: "0 10px",
                        },
                    }}
                />
                {leaderboard.length === 0 && (
                    <h3 className={css(BaseStyles.no_data)}>
                        No leaderboard yet
                    </h3>
                )}
            </div>
        </Fragment>
    );
}

const Styles = CreateScheet({
    leaderboard: {
        padding: "0 0 10px 0",
        "& h2": {
            margin: 0,
            padding: "20px 20px 0px 20px",
            color: "#525252",
            fontSize: 16,
        },
    },
    description: {
        padding: "20px 20px 0 20px",
        fontSize: 14,
        marginBottom: 0,
    },
    rules: {
        textDecoration: "underline",
        fontSize: 15,
        cursor: "pointer",
        color: "#38342f",
        fontWeight: 500,
        display: "block",
        textAlign: "center",
    },
    updated_when: {
        float: "right",
        fontSize: 13,
        fontWeight: 400,
    },
});
