import { CreateScheet } from "packages/aphrodite/aphrodite";
import { Danger, Gray, Primary } from "src/themes/colors";

const GenericStyles = CreateScheet({
    // container for multiple fields in line
    sections: {
        display:      "flex",
        columnGap:    "30px",
        rowGap:       "20px",
        marginBottom: 20,
        flexWrap:     "wrap",
    },
    // container for one field in line
    section: {
        width:         "100%",
        paddingBottom: 20,
    },

    switch: {
        display:    "flex",
        alignItems: "center",
        cursor:     "pointer",
    },
    switch_label: {
        paddingLeft: 10,
    },

    section_title: {
        margin:       "0 0 7px 0",
        fontSize:     18,
        fontFamily:   "Montserrat",
        color:        "white",
        borderBottom: `1px solid ${Primary.default}`,
        fontWeight:   600,
    },
    section_title_description: {
        fontSize:     12,
        color:        Gray.default,
        marginBottom: 10,
    },
    
    // layouts
    thirds_larger: {
        flexGrow: 1,
        minWidth: 300,
    },
    thirds_smaller: {
        flexGrow: 1,
        minWidth: 250,
        maxWidth: "40%",
    },

    // section components
    section_label: {
        display:      "flex",
        marginBottom: 6,
        fontSize:     14,
        color:        Gray.default,
        fill:         Gray.default,
        fontWeight:   500,
        alignItems:   "center",
        userSelect:   "none",
        fontFamily:   "Montserrat"
    },
    section_label_small: {
        marginLeft: 5,
        fontSize:   12,
        color:      Gray.default,
    },
    checkbox_label: {
        fontSize:     14,
        color:        "#555",
        fontWeight:   "bold",
        display:      "block",
        userSelect:   "none",
    },
    divider: {
        padding: "10px 0",
        height:  1,
        border:  `none`,
    },
    error: {
        color: Danger.default,
        fontSize: 13,
    }
})

export default GenericStyles;
