import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { Competition, CompetitionRunime } from "./model";

export default async function LoadTMACompetition():Promise<ResultWithError<Competition>> {
    const res = await get<Competition>({ url: "competitions/tma" });
    return CheckResultRuntime(CompetitionRunime, res);
}

export const useLoadTMACompetition = makeAPIHook(LoadTMACompetition);