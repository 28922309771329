import * as runtypes from 'runtypes';

export const SessionUserRuntime = runtypes.Record({
    guid:      runtypes.String,
    full_name: runtypes.String,
    image:     runtypes.String,
    has_uplay: runtypes.Boolean,
});

export const SessionExtraRuntime = runtypes.Record({});

export const UsersRuntype = runtypes.Array(SessionUserRuntime)
export type Users = runtypes.Static<typeof UsersRuntype>

export const SessionRuntime = runtypes.Record({
    user:       SessionUserRuntime.Or(runtypes.Null),
    extra:      SessionExtraRuntime,
})

export type SessionUser = runtypes.Static<typeof SessionUserRuntime>
export type SessionExtra = runtypes.Static<typeof SessionExtraRuntime>
export type SessionRaw = runtypes.Static<typeof SessionRuntime>
export type Session = runtypes.Static<typeof SessionRuntime> & {}