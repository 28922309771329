import { CreateScheet } from "packages/aphrodite/aphrodite";
import { Dark } from "src/themes/colors";

export const BaseStyles = CreateScheet({
    link: {
        color: "#436da9",
    },
    meta: {
        padding:  "20px 10px 10px 10px",
        color:    Dark.default,
        fontSize: 20,
    },
    meta_link: {
        color:          "#436da9",
        textDecoration: "none",
        ":hover": {
            textDecoration: "underline",
        }
    },
    no_data: {
        textAlign: "center",
        marginTop: 40,
        color:     "#525252",
        opacity:   0.7,
    }
})