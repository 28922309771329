import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { Users, UsersRuntype } from "packages/session/model";

export default async function SearchUsers(search_key: string):Promise<ResultWithError<Users>> {
    const res = await get<Users>({ url: `users`, query: { q: search_key } });
    return CheckResultRuntime(UsersRuntype, res);
}

export const useSearchUsers = makeAPIHook(SearchUsers);