import {CompositeDecorator, Editor, EditorState, getDefaultKeyBinding, RichUtils, DraftDecorator, convertFromRaw, convertToRaw, ContentState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { CreateScheetWithTheme, css, StyleDeclarationValue, useTheme } from 'packages/aphrodite/aphrodite';
import React, { createRef, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { DraftLayout, DraftTheme, DefaultLayout } from './Draft.theme';
import DraftNavigation from './DraftNavigation.react';
import { Mods } from './Mods.react';

export type DraftEditorProps = {
    theme:            DraftTheme,
    layout?:          Partial<DraftLayout>,
    hideNavigation?:  boolean,
    readOnly?:        boolean,
    placeholder?:     string,
    defaultValue?:    string,
    onContentChange?: (content:string) => void,
    classNames?:      {
        root?: StyleDeclarationValue,
    }
}

export interface DraftEditorFunctions {
    focus:    () => void,
    blur:     () => void,
    onChange: (editorState:EditorState) => void,
    getState: () => EditorState
}

const DraftEditor:React.ForwardRefRenderFunction<DraftEditorFunctions, DraftEditorProps> = ({
    theme,
    layout,
    hideNavigation,
    readOnly,
    placeholder,
    defaultValue,
    onContentChange,
    classNames,
}, ref) => {
    const EditorRef = createRef<Editor>()
    const [ State, SetState ] = useState<EditorState>(() => {
        const Decorators = new CompositeDecorator(
            Object.values(Mods).filter(m => m.decorator !== undefined).map(m => m.decorator) as DraftDecorator[]
        )
        if (!defaultValue) {
            return EditorState.createEmpty(Decorators)
        } else {
            try {
                return EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue)), Decorators)
            } catch (error) {
                return EditorState.createWithContent(ContentState.createFromText(defaultValue), Decorators)
            }
        }
    })

    const Styles = useTheme(theme, StylesWithTheme, {...DefaultLayout, ...(layout || {})})

    const onChange = useCallback((editorState:EditorState) => {
        SetState(editorState)
        
        if (onContentChange) {
            const content = editorState.getCurrentContent()
            onContentChange(content.hasText() ? JSON.stringify(convertToRaw(content)) : "");
        }
    }, [ onContentChange ])

    function handleKeyCommand(command:string, editorState:EditorState, eventTimeStamp:number) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    function onTab(e:React.KeyboardEvent<{}>) {
        e.preventDefault();

        const newState = RichUtils.onTab(e, State, 4)

        if (newState) {
            SetState(newState)
            return 'handled'
        } else {
            return 'not-handled'
        }
    }

    function keyBindingFn(e:React.KeyboardEvent<{}>) {
        e.persist()

        if (e.key === "Tab") {
            return onTab(e)
        }

        return getDefaultKeyBinding(e);
    }

    const focus = useCallback(() => {
        EditorRef.current?.focus()
    }, [ EditorRef ])    
    
    const blur = useCallback(() => {
        EditorRef.current?.focus()
    }, [ EditorRef ])

    const InitRef = useCallback(() => {
        return {
            focus,
            blur,
            onChange,
            getState: () => State,
        }
    }, [ State, focus, blur, onChange ])

    useImperativeHandle(ref, InitRef)
    let NavigationRef = useRef<DraftEditorFunctions>(InitRef())
    NavigationRef.current = InitRef()

    if (readOnly && defaultValue?.length === 0) {
        return null;
    }
    
    return (
        <section className={css(Styles.main, classNames?.root)}>
            {!hideNavigation && !readOnly  ? 
                <DraftNavigation {...{
                    theme,
                    layout,
                    state:     State,
                    editorRef: NavigationRef,
                }}/>
            : null}
            <Editor {...{
                editorState: State,
                onChange,
                handleKeyCommand,
                keyBindingFn,
                ref:      EditorRef,
                readOnly: !!readOnly,
                placeholder,
            }}/>
        </section>
    );
}

export default forwardRef(DraftEditor)

const StylesWithTheme = CreateScheetWithTheme((theme?: DraftTheme, layout?:DraftLayout) => { return {
    main: {
        ":nth-child(1n) .DraftEditor-root": {
            color:                   theme?.editor_color,
            padding:                 layout?.editor_padding,
            background:              theme?.editor_background,
            border:                  theme?.editor_border,
            borderTopLeftRadius:     layout?.editor_border_top_left_radius,
            borderTopRightRadius:    layout?.editor_border_top_right_radius,
            borderBottomLeftRadius:  layout?.editor_border_bottom_left_radius,
            borderBottomRightRadius: layout?.editor_border_bottom_right_radius,
            minHeight:               layout?.editor_min_height,
            fontSize:                layout?.editor_font_size,
            cursor:                  "text",
        },
        ":nth-child(1n) .DraftEditor-editorContainer, :nth-child(1n) .public-DraftEditor-content": {
            minHeight: "inherit",
        },
        ":nth-child(1n) h1": {
            fontSize:      26,
            fontWeight:    800,
            margin:        '0 0 16px',
            textAlign:     'center',
        },
        ":nth-child(1n) h2": {
            fontSize:      22,
            fontWeight:    800,
            margin:        '0 0 12px',
            textAlign:     'center',
        },
        ":nth-child(1n) h3": {
            fontSize:      18,
            fontWeight:    600,
            margin:        '0 0 8px',
            textAlign:     'center',
        },
    },
}})