import { Record, String, Number, Static, Array } from 'runtypes';

export const TrackRuntime = Record({
    id:               Number,
    name:             String,
    link:             String,
    author:           String,
    author_link:      String,
    image:            String,
    screenshot:       String,
    background_image: String,
})
export type Track = Static<typeof TrackRuntime>

export const TracksRuntime = Array(TrackRuntime);
export type Tracks = Static<typeof TracksRuntime>