export type ErrorData<E = unknown> = {
    code:       number
    text:       string
    payload?:   E
    in:         string
}

export type ResultWithError<R, E = unknown> = [ R, null ] | [ null, ErrorData<E> ];

export function CreateError<E = unknown>(text: string):ErrorData<E>
export function CreateError(text: Error):ErrorData<Error>
export function CreateError(code: number, text: string):ErrorData
export function CreateError<E>(code: number, text: string, payload: E):ErrorData<E>

export function CreateError<E = unknown>(first: number | string | Error, text?: string, payload?: E):ErrorData<E> {
    if (first instanceof Error) {
        return {
            code:       400,
            text:       JSON.stringify(first),
            payload:    undefined,
            in:         first.stack || ""
        }
    }
    
    return {
        code:       typeof first === "string" ? 400 : first,
        text:       typeof first === "string" ? first : text ? text : "",
        payload:    payload,
        in:         Error().stack || ""
    }
}

export function isError(data: ErrorData | any): data is ErrorData {
    return (data as ErrorData).code !== undefined;
}