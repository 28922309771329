// styles
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
// components
import Dropdown, { DropdownBaseFunctions } from "packages/dropdowns/dropdownBase.react";
import { useLoadFormats } from "packages/tournaments/API/LoadFormats";
import { Format } from "packages/tournaments/model";
import { useRef } from "react";
// icons
import { ReactComponent as ArrowIcon } from 'src/icons/down-arrow.svg';
import { Danger, Dark, White } from "src/themes/colors";
import { MakeInput, PrimaryInput } from "src/themes/input";

export default function FormatPicker({
    active,
    setActive,
}: {
    active:   Format,
    setActive: (format: Format) => void,
}) {
    const [ State ] = useLoadFormats(true);
    const triggers = useRef<DropdownBaseFunctions>(null);

    const onItemClick = (format: Format) => {
        triggers.current?.toggleDropElement(false);
        setActive(format);
    }

    function onHeaderClick() {
        triggers.current?.toggleDropElement(true);
    }

    return (
        <Dropdown {...{
            layout: {
                wrapper: {
                    zIndex: 5,
                }
            },
            triggers:  triggers,
            animation: "fade",
            header:    (
                <div className={ css(Styles.header) } onClick={ onHeaderClick }>
                    <div className={ css(MakeInput(PrimaryInput), Styles.header) }>
                        <div className={ css(MakeInput(PrimaryInput), Styles.input, Styles.format) }>
                            <span>{active.name}</span>
                        </div>
                    </div>
                    <ArrowIcon width="16" height="16" className={css(Styles.headerIcon, Styles.arrowDown)}/>
                </div>
            ),
            dropElement: () => {
                return (
                    <div>
                        { State.payload && (
                            <ul className={css(Styles.dropElement)}>
                                { State.payload && Object.entries(State.payload).map(([format_id, format]) => (
                                    <li {...{
                                        key:       format_id,
                                        className: css(Styles.dropItem, Styles.format),
                                        onClick:   () => {
                                            onItemClick(format);
                                        },
                                    }}>
                                        <span>{format.name}</span>
                                    </li>
                                )) }
                            </ul>
                        )}
                    </div>
                )
            }
        }}/>
    )
}

const Styles = CreateScheet({
    header: {
        position: "relative",
        width:    "100%",
        padding:  0,
    },
    error: {
        borderColor: Danger.default,
    },
    header_elm: {
        flexGrow: 1,
        display:  "flex",
        position: "relative",
    },
    arrowDown: {
        fill:       Dark.default,
    },
    input: {
        width: "100%",
        padding: "10px 40px 10px 15px",
        border: "none",
        margin: 0,
        background: "transparent",
        ":focus": {
            outline: "none",
            boxShadow: "none",
        }
    },
    headerIcon: {
        position: "absolute",
        top: "50%",
        right: 15,
        marginTop: -8,
        cursor: "pointer",
    },
    dropElement: {
        position:     "absolute",
        top:          "100%",
        left:         0,
        right:        0,
        background:   White.default,
        listStyle:    "none",
        boxShadow:    `0px 10px 20px rgba(48, 48, 48, 0.15)`,
        borderRadius: 12,
        margin:       0,
        padding:      "10px 15px",
        overflow:     "auto",
        maxHeight:    "auto",
        zIndex:       5,
        userSelect:   "none",
    },
    dropItem: {
        padding:      "10px 15px",
        fontSize:     "1rem",
        fontWeight:   600,
        borderRadius: 5,
        cursor:       "pointer",
        color:        "black",
        ":hover":     {
            background: "#F5F5F5",
        }
    },
    no_resuts: {
        fontStyle: "italic",
        fontWeight: 400,
    },
    format: {
        display:        "flex",
        alignItems:     "center",
        justifyContent: "space-between",
        fontFamily:     "Montserrat",
        color:          "black",
        fontSize:       14,
        userSelect:     "none",
    },
    formatSmall: {
        fontSize: 12,
        color:    "#C1C1C1"
    }
})
