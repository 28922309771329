import { CreateScheetWithTheme, css, useTheme } from "packages/aphrodite/aphrodite";
import useOutsideClick from "packages/hooks/useOutsideClick";
import { ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState, Ref } from "react";
import { Layout } from "./dropdownBase.model";
import { fadeAnimation } from './dropdownBase.css';
import { CSSTransition } from "react-transition-group";

const DropDownAnimationTime = 150;

export interface DropdownBaseFunctions {
    toggleDropElement: (status?: boolean) => void,
}

const DropdownBase = ({
    header,
    dropElement,
    disabled,
    animation,
    layout,
    triggers,
    preventCloseOnClick,
}:{
    header:               ReactNode,
    dropElement:          (functions:DropdownBaseFunctions) => ReactNode,
    disabled?:            boolean,
    animation?:           "fade",
    layout?:              Layout,
    preventCloseOnClick?: boolean,
    triggers?:            Ref<DropdownBaseFunctions>,
}) => {
    const Styles = useTheme(layout, StylesWithTheme);

    const wrapperRef = useRef<HTMLDivElement>(null)
    const [ show, setShow ] = useState(false)

    function toggleDropElement(status?: boolean) {
        if (typeof status !== "undefined") {
            status !== show && setShow(status)
        } else if (!disabled) {
            setShow(!show)
        }
    }

    useEffect(() => {
        if (disabled && show) {
            setShow(false)
        }
    }, [ show, disabled ])

    const escapeHandler = useCallback((e:KeyboardEvent) => {
        if (e.code === "Escape" && show) {
            setShow(false)
        }
    }, [ show ])

    useOutsideClick(wrapperRef, () => {
        if (show) {
            setShow(false)
        }
    })

    useEffect(() => {
        document.addEventListener("keydown", escapeHandler)
        
        return () => {
            document.removeEventListener("keydown", escapeHandler)
        }
    })

    useImperativeHandle(triggers, () => ({
        toggleDropElement,
    }));

    return (
        <div {...{
            className: css(
                Styles.wrapper, 
                disabled && Styles.disabled, 
                animation === "fade" && fadeAnimation(DropDownAnimationTime)
            ),
            onClick:   () => {
                if (!show || !preventCloseOnClick) {
                    toggleDropElement()
                }
            },
            ref:       wrapperRef,
        }}>
            {typeof header === "function" ? header() : header }
            <CSSTransition {...{
                classNames:    animation === "fade" ? `dropdown-fade` : undefined,
                timeout:       animation === undefined ? 0 : DropDownAnimationTime,
                in:            show,
                mountOnEnter:  true,
                unmountOnExit: true,
            }}>
                {dropElement({
                    toggleDropElement,
                })}
            </CSSTransition>
        </div>
    );
}

export default DropdownBase;

const StylesWithTheme = CreateScheetWithTheme((layout?: Layout) => { return {
    wrapper: {
        position: "relative",
        cursor:   "pointer",
        margin:   layout?.wrapper?.margin ? layout.wrapper.margin : 0,
        width:    layout?.wrapper?.width ? layout.wrapper.width : undefined,
    },
    disabled: {
        cursor: "default",
        opacity: 0.8,
    },
}})
