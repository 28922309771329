import { ContentState, DraftDecorator, EditorState, RichUtils } from "draft-js"
import { DraftEditorFunctions } from "./DraftEditor.react"
import InsertLink, { LinkComponent, LinkStrategy } from "./ModComponents/Link.react"
import ClearInlineStyles from "./utils/ClearInlineStyles"
import { ReactComponent as BoldIcon } from './icons/bold.svg';
import { ReactComponent as ItalicIcon } from './icons/italic.svg';
import { ReactComponent as UnderlineIcon } from './icons/underline.svg';
import { ReactComponent as OrderedListIcon } from './icons/ordered-list.svg';
import { ReactComponent as UnorderedListIcon } from './icons/unordered-list.svg';
import { ReactComponent as H1Icon } from './icons/format-header-1.svg';
import { ReactComponent as H2Icon } from './icons/format-header-2.svg';
import { ReactComponent as H3Icon } from './icons/format-header-3.svg';
import { ReactComponent as UndoIcon } from './icons/undo.svg';
import { ReactComponent as RedoIcon } from './icons/redo.svg';
import { ReactComponent as LinkIcon } from './icons/link.svg';
import { ReactComponent as RemoveLinkIcon } from './icons/remove-link.svg';
import { ReactComponent as ClearIcon } from './icons/clear.svg';
import { showLightbox } from "packages/lightbox/lightbox";
import { LightTheme } from "src/themes/lightbox";

export type ModProps = {
    entityKey:    string
    contentState: ContentState
}

export type Mod = {
    component:  React.ReactNode,
    tooltip?:   string,
    onClick:    (draft:DraftEditorFunctions) => void,
    decorator?: DraftDecorator
}

export const Mods:Record<string, Mod> = {
    "header-one": {
        tooltip: "H1",
        component: <H1Icon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleBlockType(draft.getState(), "header-one"))
        } 
    },
    "header-two": {
        tooltip: "H2",
        component: <H2Icon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleBlockType(draft.getState(), "header-two"))
        } 
    },
    "header-three": {
        tooltip: "H3",
        component: <H3Icon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleBlockType(draft.getState(), "header-three"))
        } 
    },
    "BOLD": {
        tooltip: "Bold",
        component: <BoldIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleInlineStyle(draft.getState(), "BOLD"))
        } 
    },
    "ITALIC": {
        tooltip: "Italic",
        component: <ItalicIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleInlineStyle(draft.getState(), "ITALIC"))
        } 
    },
    "UNDERLINE": {
        tooltip: "Underline",
        component: <UnderlineIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleInlineStyle(draft.getState(), "UNDERLINE"))
        } 
    },
    "ordered-list-item": {
        tooltip: "Ordered list",
        component: <OrderedListIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleBlockType(draft.getState(), "ordered-list-item"))
        } 
    },
    "unordered-list-item": {
        tooltip: "Unordered list",
        component: <UnorderedListIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(RichUtils.toggleBlockType(draft.getState(), "unordered-list-item"))
        } 
    },
    "LINK": {
        tooltip: "Insert link",
        component: <LinkIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            showLightbox({
                selector: "InsertLink",
                Component: InsertLink,
                theme: LightTheme,
                content: {
                    onInsert: (url) => {
                        const contentState = draft.getState().getCurrentContent();
                        const contentStateWithEntity = contentState.createEntity(
                            'LINK',
                            'MUTABLE',
                            {url}
                        );
                        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                        const newEditorState = EditorState.set(draft.getState(), { currentContent: contentStateWithEntity });
                        
                        draft.onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
                        setTimeout(() =>  draft.focus(), 0)
                    }
                }
            })
        },
        decorator: {
            strategy: LinkStrategy,
            component: LinkComponent,
        }
    },
    "remove_link": {
        tooltip: "Remove link",
        component: <RemoveLinkIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            const selection = draft.getState().getSelection();
            if (!selection.isCollapsed()) {
                draft.onChange(RichUtils.toggleLink(draft.getState(), selection, null))
            }
        }
    },
    "clear_formatting": {
        tooltip: "Clear formatting",
        component: <ClearIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(ClearInlineStyles(draft.getState()))
        }
    },
    "undo": {
        tooltip: "Undo",
        component: <UndoIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(EditorState.undo(draft.getState()))
        } 
    },
    "redo": {
        tooltip: "Redo",
        component: <RedoIcon height="15" width="15" />,
        onClick: (draft:DraftEditorFunctions) => {
            draft.onChange(EditorState.redo(draft.getState()))
        } 
    },
} 
