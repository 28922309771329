import { CreateScheetWithTheme, css, useTheme } from "packages/aphrodite/aphrodite";
import { EditorState } from 'draft-js';
import Tooltip from "packages/tooltip/Tootip.react";
import { DraftLayout, DraftTheme, DefaultLayout } from "./Draft.theme";
import { DraftEditorFunctions } from "./DraftEditor.react";
import { Mod, Mods } from "./Mods.react";

export default function DraftNavigation({
    editorRef,
    theme,
    layout,
    state,
}:{
    editorRef: React.RefObject<DraftEditorFunctions>,
    theme:     DraftTheme,
    layout?:   Partial<DraftLayout>,
    state:     EditorState,
}) {
    const Styles = useTheme(theme, StylesWithTheme, {...DefaultLayout, ...(layout || {})})

    const ModClick = (mod:Mod) => (e:React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (editorRef.current) {
            mod.onClick(editorRef.current)
        }
    }

    const selection    = state.getSelection();
    const blockType    = state.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
    const currentStyle = state.getCurrentInlineStyle();

    return(
        <nav className={css(Styles.nav)}>
            {Object.entries(Mods).map(([key, mod]) => {
                return(
                    <Tooltip {...{
                        key,
                        theme:    {
                            color:           "white",
                            backgroundColor: `gray`,
                            borderColor:     `gray`,
                            boxShadow:       undefined
                        },
                        content:  mod.tooltip || "",
                        disabled: !mod.tooltip,
                    }}>
                        <div {...{
                            className:   css(Styles.navItem, key === blockType || currentStyle.has(key) ? Styles.active : null),
                            onMouseDown: ModClick(mod),
                        }}>
                            {mod.component}
                        </div>
                    </Tooltip>
                );
            })}
        </nav>
    );
}

const StylesWithTheme = CreateScheetWithTheme((theme?:DraftTheme, layout?: DraftLayout) => { return {
    nav: {
        display:                 "flex",
        alignItems:              "center",
        paddingLeft:             10,
        background:              theme?.nav_background,
        border:                  theme?.nav_border,
        borderBottom:            theme?.nav_border_bottom,
        borderTopLeftRadius:     layout?.nav_border_top_left_radius,
        borderTopRightRadius:    layout?.nav_border_top_right_radius,
        borderBottomLeftRadius:  layout?.nav_border_bottom_left_radius,
        borderBottomRightRadius: layout?.nav_border_bottom_right_radius,
    },
    navItem: {
        color:        theme?.nav_item_color,
        margin:       3,
        padding:      3,
        cursor:       "pointer",
        background:   theme?.nav_item_background,
        borderBottom: `1px solid transparent`,
        transition:   "border-bottom 0.2s",
        ":hover":     {
            background: theme?.nav_item_hover_background,
        },
        ":nth-child(1n) svg": {
            display: "block",
            fill: theme?.nav_item_color,
        }
    },
    active: {
        borderBottom: `1px solid ${theme?.nav_item_color}`
    }
}})
