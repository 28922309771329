import { EditorState, Modifier } from "draft-js";

export default function ClearInlineStyles(editorState:EditorState):EditorState {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    //Then based on the docs for SelectionState -
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    // Selected Text
    const selectedText = currentBlock.getText().slice(start, end);

    const contentWithoutStyles = Modifier.replaceText(
        editorState.getCurrentContent(),
        selectionState,
        selectedText,
    );

    return EditorState.push(
        editorState,
        contentWithoutStyles,
        'change-inline-style',
    );
}