import { css } from "packages/aphrodite/aphrodite";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import { GetTableTheme } from "packages/themes/components";
import { PrimaryReadOnluDraft } from "src/themes/draftjs";
import { GetPath, PageProps } from "../model";
import GeneralStyles from "../modules/GeneralStyles";

export default function Tracks({ tournament, theme, prefix }: PageProps) {
    return (
        <section className={css(GeneralStyles.content)}>
            <DraftEditorReact
                {...{
                    theme: PrimaryReadOnluDraft,
                    defaultValue: tournament.tracks_description || "",
                    readOnly: true,
                }}
            />
            {tournament.tracks.length > 0 ? (
                <PrettyTable
                    {...{
                        thead: [
                            "map",
                            "author",
                            //"records",
                            //"WR"
                        ],
                        tbody: tournament.tracks.map((track) => {
                            return [
                                () => (
                                    <strong>
                                        <a
                                            {...{
                                                href: `/${GetPath(
                                                    tournament.uri,
                                                    "tracks",
                                                    track.uid,
                                                    prefix
                                                )}`,
                                                onClick: OpenPage(
                                                    GetPath(
                                                        tournament.uri,
                                                        "tracks",
                                                        track.uid,
                                                        prefix
                                                    )
                                                ),
                                                className: css(
                                                    theme.color("secondary")
                                                ),
                                            }}
                                        >
                                            {track.clear_name}
                                        </a>
                                    </strong>
                                ),
                                track.author.username,
                            ];
                        }),
                        theme: GetTableTheme(theme),
                        layout: {
                            theadColumnFontSize: 15,
                            tbodyColumnFontSize: 13,
                            titlePadding: "3px 10px 13px 10px",
                        },
                    }}
                />
            ) : null}
        </section>
    );
}
