import { Record, Array, String, Number, Static } from "runtypes";

export const AccountRuntime = Record({
    name:        String,
})
export type Account = Static<typeof AccountRuntime>

export const UserRuntime = Record({
    score:         Number,
    total_records: Number,
    total_wrs:     Number,
    account:       AccountRuntime,
});
export type User = Static<typeof UserRuntime>

export const ReplayRuntime = Record({
    time:       Number,
    account:    AccountRuntime,
    score:      Number,
    time_score: Number,
    pos_score:  Number,
})
export type Replay = Static<typeof ReplayRuntime>


export const TrackRuntime = Record({
    name:       String,
    clear_name: String,
    uid:        String,
    file_url:   String,
    tmx_url:    String,
    image:      String,
    creator:    AccountRuntime,
    records:    Array(ReplayRuntime),
    wr_time:    Number,
    wr_account: AccountRuntime,
})
export type Track = Static<typeof TrackRuntime>

export const CompetitionRunime = Record({
    name:         String,
    tracks:       Array(TrackRuntime),
    leaderboard:  Array(UserRuntime),
    last_updated: Number,
});
export type Competition = Static<typeof CompetitionRunime>