export default function PrintTime(time: number) {
  function pad(n: number, z?: number) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  let ms = time % 1000;
  time = (time - ms) / 1000;
  let secs = time % 60;
  time = (time - secs) / 60;
  let mins = time % 60;

  return pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
}