import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithBG } from "packages/aphrodite/WithBG";
import Header from "../modules/Header.react";
import ShowcaseList from "../maps/ShowcaseList.react";

export default function Showcase() {
    return (
        <article className={ css(Styles.page, WithBG("#191716", "linear-gradient(rgba(31, 36, 37, 0.96), rgba(31, 36, 37, 1)), url(/images/penta_map.jpg)")) }>
            <Header />
            <div className={ css(Styles.body) }>
                <h1 className={ css(Styles.title) }>Showcase maps</h1>
                <ShowcaseList />
            </div>
        </article>
    )
}

const Styles = CreateScheet({
    page: {
        minHeight:       "100vh",
        color:           "white",
        display:         "flex",
        flexDirection:   "column",
    },
    body: {
        flexGrow:        1,
        paddingBottom:   30,
        position:        "relative",
        maxWidth:        900,
        width:           "100%",
        margin:          "0 auto",
        padding:         "20px",
    },
    title: {
        marginBottom: 40,
    }
});