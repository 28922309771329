import EventEmitter from 'eventemitter3';
import { LoadSessionAPI } from './LoadSessionAPI';
import { Session, SessionRaw } from './model';
import { SignOutAPI } from './SignOutAPI';

export const Events = new EventEmitter<{
    "session-changed": () => void,
}>();

const SessionEmpty:Session = {
    user: {
        guid:      "",
        full_name: "",
        image:     "",
        has_uplay: false,
    },
    extra: {}
}

let session:Session = {...SessionEmpty};

function SetSession(newSession: SessionRaw) {
    session = {...session, ...newSession};
}

// usually such functions should not be located here
// but session is a bit different case
export async function LoadSession():Promise<Session> {
    const res = await LoadSessionAPI();
    if (res[1] !== null) {
        console.error("session error: ", res[1].text);
    } else {
        SetSession(res[0]);
    }

    return session;
}

export async function SignOut() {
    const res = await SignOutAPI();
    if (res[1] !== null) {
        console.error("session error: ", res[1].text);
    } else {
        window.location.href = "/"
        SetSession(res[0]);
    }
    Events.emit("session-changed");
}

export function listenOnSessionChanges(func:() => void) {
    Events.addListener("session-changed", func)
}

export function unlistenOnSessionChanges(func:() => void) {
    Events.removeListener("session-changed", func)
}

export { session };
