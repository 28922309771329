import { CreateScheetWithTheme, css, useTheme } from "packages/aphrodite/aphrodite"
import CreateGUID from "packages/helpers/CreateGUID"
import { Dark, Primary } from "src/themes/colors"
import { SwitchTheme } from "./Switch.theme"

export type SwitchProps = {
    defaultChecked?: boolean,
    checked?:        boolean,
    theme?:          SwitchTheme,
    onChange?:       (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function Switch({
    theme,
    defaultChecked,
    checked,
    onChange,
}: SwitchProps) {
    const Styles = useTheme(theme, StylesWithTheme)
    const Id = CreateGUID();

    return (
        <>
            <input {...{
                id: Id,
                type: "checkbox",
                defaultChecked,
                checked,
                className: css(Styles.switch),
                onClick: e => {
                    e.stopPropagation();
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (onChange) {
                        onChange(e)
                    }
                }
            }}/>
            <label htmlFor={Id} className={css(Styles.switchLabel)} />
        </>
    )
}

const StylesWithTheme = CreateScheetWithTheme((theme?: SwitchTheme) => { return {
    switch: {
        position: "absolute",
        height: 0,
        width: 0,
        visibility: "hidden",
        ":checked + label": {
            background: theme?.background || Primary.default,
            ":after": {
                left: "calc(100% - 2px)",
                transform: "translateX(-100%)",
            },
        },
    },
    switchLabel: {
        position: "relative",
        display: "block",
        width: 44,
        height: 24,
        textIndent: -9999,
        background: theme?.inactiveBackground || Dark.default,
        borderRadius: 20,
        cursor: "pointer",
        ":after": {
            content: "' '",
            position: "absolute",
            top: 2,
            left: 2,
            width: 20,
            height: 20,
            background: "#fff",
            borderRadius: 20,
            transition: "all 0.3s",
        },
        ":active": {
            ":after": {
                width: 20,
            }
        }
    },
}})