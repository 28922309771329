import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCog } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import { showLightbox } from "packages/lightbox/lightbox";
import { DarkTheme } from "src/themes/lightbox";
import TournamentEditor from "../forms/EditTournament.react";
import TimeAttackEditor from "../forms/EditTimeAttack.react";
import PlayOffsEditor from "../forms/EditPlayoffs.react";
import TournamentTracksEditor from "../forms/EditTracks.react";
import TournamentPlayersEditor from "../forms/EditPlayers.react";
import TournamentAdministration from "../forms/Administration.react";
import { PageProps } from "../model";
import ShowDetailedTA, { Styles as TAStyles } from "./ShowDetailedTA";

export default function ManagerPanel({ tournament, theme, prefix }: PageProps) {
    const [show, setShow] = useState(false);

    function OpenMainEditor() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: {
                tournament,
            },
            Component: TournamentEditor,
            theme: DarkTheme,
        });
    }

    function OpenTAEditor() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: {
                tournament,
            },
            Component: TimeAttackEditor,
            theme: DarkTheme,
        });
    }

    function OpenPlayoffsEditor() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: {
                tournament,
            },
            Component: PlayOffsEditor,
            theme: DarkTheme,
        });
    }

    function OpenTracksEditor() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: {
                tournament,
            },
            Component: TournamentTracksEditor,
            theme: DarkTheme,
        });
    }

    function OpenPlayersEditor() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: {
                tournament,
            },
            Component: TournamentPlayersEditor,
            theme: DarkTheme,
        });
    }

    function OpenAdministration() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: {
                tournament,
            },
            Component: TournamentAdministration,
            theme: DarkTheme,
        });
    }

    function OpenDetailedPlayers() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: {
                theme: theme,
                prefix: prefix,
                tournament,
            },
            Component: ShowDetailedTA,
            theme: DarkTheme,
            prevent: {
                closeOnOverlay: true,
            },
            styles: {
                wrapper: TAStyles.wrapper,
            },
        });
    }

    return (
        <aside className={css(Styles.panel)}>
            {show ? (
                <Fragment>
                    <h3
                        className={css(Styles.title)}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <div style={{ flexGrow: 1, marginRight: 10 }}>
                            Management
                        </div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className={css(Styles.icon, theme.color("primary"))}
                        />
                    </h3>
                    <ul className={css(Styles.list)}>
                        <li
                            {...{
                                className: css(
                                    Styles.item,
                                    theme.background("primary", {
                                        on_hover: true,
                                        opacity: 10,
                                    })
                                ),
                                onClick: OpenMainEditor,
                            }}
                        >
                            Tournament
                        </li>
                        <li
                            {...{
                                className: css(
                                    Styles.item,
                                    theme.background("primary", {
                                        on_hover: true,
                                        opacity: 10,
                                    })
                                ),
                                onClick: OpenTracksEditor,
                            }}
                        >
                            Tracks
                        </li>
                        <li
                            {...{
                                className: css(
                                    Styles.item,
                                    theme.background("primary", {
                                        on_hover: true,
                                        opacity: 10,
                                    })
                                ),
                                onClick: OpenPlayersEditor,
                            }}
                        >
                            Registartion
                        </li>
                        <li
                            {...{
                                className: css(
                                    Styles.item,
                                    theme.background("primary", {
                                        on_hover: true,
                                        opacity: 10,
                                    })
                                ),
                                onClick: OpenDetailedPlayers,
                            }}
                        >
                            Detailed players list
                        </li>
                        <li
                            {...{
                                className: css(
                                    Styles.item,
                                    theme.background("primary", {
                                        on_hover: true,
                                        opacity: 10,
                                    })
                                ),
                                onClick: OpenTAEditor,
                            }}
                        >
                            Time attack
                        </li>
                        <li
                            {...{
                                className: css(
                                    Styles.item,
                                    theme.background("primary", {
                                        on_hover: true,
                                        opacity: 10,
                                    })
                                ),
                                onClick: OpenPlayoffsEditor,
                            }}
                        >
                            Playoffs
                        </li>
                        <li
                            {...{
                                className: css(
                                    Styles.item,
                                    theme.background("primary", {
                                        on_hover: true,
                                        opacity: 10,
                                    })
                                ),
                                onClick: OpenAdministration,
                            }}
                        >
                            Administration
                        </li>
                    </ul>
                </Fragment>
            ) : (
                <div
                    className={css(Styles.collapsed)}
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faCog}
                        className={css(Styles.icon, theme.color("primary"))}
                        size="lg"
                    />
                </div>
            )}
        </aside>
    );
}

const Styles = CreateScheet({
    panel: {
        position: "fixed",
        top: 120,
        right: 0,
        backgroundColor: "white",
        borderRadius: "6px 0 0 6px",
        fontSize: 14,
        userSelect: "none",
        zIndex: 10,
    },
    list: {
        listStyle: "none",
        margin: 0,
        padding: "10px 0",
    },
    item: {
        padding: "8px 20px",
        cursor: "pointer",
    },
    collapsed: {
        padding: "10px",
        cursor: "pointer",
    },
    icon: {
        display: "block",
    },
    title: {
        padding: "10px 10px 0 20px",
        margin: 0,
        fontWeight: 500,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    icon_small: {
        marginLeft: 5,
    },
});
