import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { WHCResponse, WHCResponseRuntype } from "./../model";

export default async function LoadWHC():Promise<ResultWithError<WHCResponse>> {
    const res = await get<WHCResponse>({ url: `cups/whc` });
    return CheckResultRuntime(WHCResponseRuntype, res);
}

export const useLoadWHC = makeAPIHook(LoadWHC);