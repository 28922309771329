import { css } from "aphrodite";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { Tournament } from "packages/tournaments/model";
import { PrimaryReadOnluDraft } from "src/themes/draftjs";
import GeneralStyles from "../modules/GeneralStyles";

export default function Playoffs({tournament}:{
    tournament:        Tournament
}) {
    return (
        <div className={css(GeneralStyles.content)}>
            <DraftEditorReact {...{
                theme:        PrimaryReadOnluDraft,
                defaultValue: tournament.playoffs_description || "",
                readOnly:     true,
            }}/>
        </div>
    )
}