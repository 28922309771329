import { MakeAnimation } from "packages/aphrodite/aphrodite";

const fade = MakeAnimation({
    '0%': {
        opacity:    0,
    },
    '100%': {
        opacity:    1,
    },
}, {
    '0%': {
        opacity:    1,
    },
    '100%': {
        opacity:    0,
    }
});

export default fade;