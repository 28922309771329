import { css } from "aphrodite";
import PrettyTable from "packages/tables/PrettyTable.react";
import RegistrationBlock from "../modules/RegistrationBlock.react";
import GeneralStyles from "../modules/GeneralStyles";
import { GetPath, PageProps } from "../model";
import { OpenPage } from "packages/history/history";
import moment from "moment";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { PrimaryReadOnluDraft } from "src/themes/draftjs";
import { GetTableTheme } from "packages/themes/components";
import { session } from "packages/session/session";
import { DangerFilled, LoadButton, MakeButton } from "src/themes/button";
import BanPlayer from "packages/tournaments/API/BanPlayer";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme } from "src/themes/lightbox";
import { useState } from "react";

export default function Players(props: PageProps) {
    const [isBanning, setIsBanning] = useState(false);

    return (
        <div className={css(GeneralStyles.content)}>
            <DraftEditorReact
                {...{
                    theme: PrimaryReadOnluDraft,
                    defaultValue: props.tournament.players_description || "",
                    readOnly: true,
                }}
            />
            {props.tournament.players_description &&
                props.tournament.players_description.length > 0 && <br />}
            <RegistrationBlock {...props} />
            <br />
            {props.tournament.players.length > 0 ? (
                <PrettyTable
                    {...{
                        thead: [
                            "username",
                            "registered at",
                            props.tournament.is_manager ? "actions" : undefined,
                        ].filter((v) => Boolean(v)),
                        tbody: props.tournament.players.map((player) => {
                            return [
                                () => (
                                    <strong>
                                        <a
                                            {...{
                                                href: `/${GetPath(
                                                    props.tournament.uri,
                                                    "players",
                                                    player.user.username,
                                                    props.prefix
                                                )}`,
                                                onClick: OpenPage(
                                                    GetPath(
                                                        props.tournament.uri,
                                                        "players",
                                                        player.user.username,
                                                        props.prefix
                                                    )
                                                ),
                                                className: css(
                                                    props.theme.color(
                                                        "secondary"
                                                    )
                                                ),
                                            }}
                                        >
                                            {player.user.username}
                                        </a>
                                    </strong>
                                ),
                                moment
                                    .unix(player.user.created_at)
                                    .format("MMMM Do YYYY"),
                                props.tournament.is_manager
                                    ? () => {
                                          if (
                                              player.user.guid ===
                                              session.user?.guid
                                          ) {
                                              return <span>you</span>;
                                          }
                                          return (
                                              <LoadButton
                                                  {...{
                                                      theme: DangerFilled,
                                                      loading: isBanning,
                                                      layout: {
                                                          padding: "3px 10px",
                                                      },
                                                      onClick: () => {
                                                          showWarning(
                                                              `Are you sure you want to ban "${player.user.username}"`,
                                                              DarkTheme,
                                                              {
                                                                  buttons: [
                                                                      {
                                                                          text: "YES",
                                                                          className:
                                                                              css(
                                                                                  MakeButton(
                                                                                      DangerFilled
                                                                                  )
                                                                              ),
                                                                          onClick:
                                                                              async () => {
                                                                                  setIsBanning(
                                                                                      true
                                                                                  );
                                                                                  const err =
                                                                                      await BanPlayer(
                                                                                          props
                                                                                              .tournament
                                                                                              .uri,
                                                                                          player
                                                                                              .user
                                                                                              .guid
                                                                                      );
                                                                                  if (
                                                                                      err !=
                                                                                      null
                                                                                  ) {
                                                                                      alert(
                                                                                          err.text
                                                                                      );
                                                                                  }
                                                                                  setIsBanning(
                                                                                      false
                                                                                  );
                                                                              },
                                                                      },
                                                                  ],
                                                              }
                                                          );
                                                      },
                                                  }}
                                              >
                                                  Ban
                                              </LoadButton>
                                          );
                                      }
                                    : undefined,
                            ].filter((v) => Boolean(v));
                        }),
                        columnsSizes: ["auto", 150, 80],
                        theme: GetTableTheme(props.theme),
                        layout: {
                            theadColumnFontSize: 15,
                            tbodyColumnFontSize: 13,
                            titlePadding: "3px 10px 13px 10px",
                        },
                    }}
                />
            ) : null}
        </div>
    );
}
