import { CreateScheet, CSSProperties, StyleDeclaration } from "../aphrodite/aphrodite";

export type InputLayout = {
    width?:             CSSProperties["width"]
    height?:            CSSProperties["height"]
    textTransform?:     CSSProperties["textTransform"]
    fontWeight?:        CSSProperties["fontWeight"]
    fontFamily?:        CSSProperties["fontFamily"]
    fontSize?:          CSSProperties["fontSize"]
    lineHeight?:        CSSProperties["lineHeight"]
    borderRadius?:      CSSProperties["borderRadius"]
    padding?:           CSSProperties["padding"]
    border?:            CSSProperties["border"]
    textAlign?:         CSSProperties["textAlign"]
    transition?:        CSSProperties["transition"]
    valid?:             boolean,
    error?:             boolean,
    noIcon?:            boolean,
}

export type InputTheme = {
    plain: {
        background:         CSSProperties["background"]
        color:              CSSProperties["color"]
        borderColor:        CSSProperties["borderColor"]
        boxShadow:          CSSProperties["boxShadow"]
    }
    placeholder?: {
        color:              CSSProperties["color"]
    },
    label?: {
        color:              CSSProperties["color"]
    },
    focus?: {
        background:         CSSProperties["background"]
        color:              CSSProperties["color"]
        borderColor:        CSSProperties["borderColor"]
        boxShadow:          CSSProperties["boxShadow"]
    },
    valid?: {
        background:         CSSProperties["background"]
        color:              CSSProperties["color"]
        borderColor:        CSSProperties["borderColor"]
        boxShadow:          CSSProperties["boxShadow"]
    }
    error?: {
        background:         CSSProperties["background"]
        color:              CSSProperties["color"]
        borderColor:        CSSProperties["borderColor"]
        boxShadow:          CSSProperties["boxShadow"]
    },
    disabled?: {
        background:         CSSProperties["background"]
        color:              CSSProperties["color"]
        borderColor:        CSSProperties["borderColor"]
        boxShadow:          CSSProperties["boxShadow"]
    }
}

const Input = ({ valid, error, noIcon, ...styles }: InputLayout, theme: InputTheme) => {
    let css:StyleDeclaration = {
        ...styles,
        ...theme.plain,
        backgroundPosition: 'right calc(.3625em + .219rem) center',
        "::placeholder": {
            color:      theme.placeholder?.color,
            transition: "padding 0.2s",
        },
        ":focus": {
            outline: "none",
            ...{
                ...theme.focus,
                borderColor: error ? theme.error?.borderColor : theme.focus?.borderColor,
            },
            "::placeholder": {
                paddingLeft: 5,
            },
        }
    }

    if (valid && theme.valid) {
        css = {
            ...css, 
            ...theme.valid,
            ...(noIcon && {
                paddingRight:       32,
                backgroundImage:    `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E")`,
                backgroundRepeat:   'no-repeat',
                backgroundSize:     'calc(.725em + .438rem) calc(.725em + .438rem)',
                backgroundPosition: 'right calc(.3625em + .219rem) center',
            })
        };
    }
    
    if (error && theme.error) {
        css = {
            ...css, 
            ...theme.error,
            ...(noIcon && {
                paddingRight:       32,
                backgroundImage:    `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3E%3C/svg%3E")`,
                backgroundRepeat:   'no-repeat',
                backgroundSize:     'calc(.725em + .438rem) calc(.725em + .438rem)',
                backgroundPosition: 'right calc(.3625em + .219rem) center',
            }),
        };
    }

    if (theme.disabled) {
        css = {
            ...css,
            ":disabled": theme.disabled,
        };
    }

    return CreateScheet({ base: css }).base;
};

export default Input;