import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import PrettyTable from "packages/tables/PrettyTable.react";
import { WHCResponse } from "packages/whc/model";
import { WHCTable } from "src/themes/tables";
import { Colors } from "../BaseStyles";

export default function TimeSheet({ payload }: { payload: WHCResponse }) {
    return (
        <div className={css(Styles.block)}>
            <PrettyTable
                {...{
                    thead: [
                        "name",
                        "start at (UTC)",
                        "ends at (UTC)",
                        "description",
                    ],
                    tbody: Object.values(payload.phases).map((phase, i) => {
                        return [
                            <span
                                className={css(
                                    Styles.noWrap,
                                    phase.id ===
                                        payload.cup.current_state
                                            .phase_type_id && Styles.active
                                )}
                            >
                                {phase.title}
                            </span>,
                            <span
                                className={css(
                                    Styles.noWrap,
                                    phase.id ===
                                        payload.cup.current_state
                                            .phase_type_id && Styles.active
                                )}
                            >
                                {phase.start_on.slice(0, 9)}
                            </span>,
                            <span
                                className={css(
                                    Styles.noWrap,
                                    phase.id ===
                                        payload.cup.current_state
                                            .phase_type_id && Styles.active
                                )}
                            >
                                {phase.end_on.slice(0, 9)}
                            </span>,
                            <span
                                className={css(
                                    phase.id ===
                                        payload.cup.current_state
                                            .phase_type_id && Styles.active
                                )}
                                dangerouslySetInnerHTML={{
                                    __html: phase.description,
                                }}
                            />,
                        ];
                    }),
                    theme: WHCTable,
                    layout: {
                        theadColumnFontSize: 15,
                        tbodyColumnFontSize: 13,
                        tbodyPadding: "0 10px",
                        titlePadding: "3px 10px",
                    },
                }}
            />
        </div>
    );
}

const Styles = CreateScheet({
    block: {
        paddingTop: 20,
        fontSize: 12,
        color: Colors.LightGray,
        "@media (max-width: 600px)": {
            flexDirection: "column",
        },
        "& table": {
            padding: 0,
        },
        "& thead tr th": {
            fontSize: 12,
            ":first-child": {
                paddingLeft: 30,
            },
        },
        "& tbody tr td": {
            fontSize: 12,
            ":first-child": {
                paddingLeft: 30,
            },
        },
    },
    noWrap: {
        whiteSpace: "nowrap",
    },
    active: {
        color: "white",
    },
});
