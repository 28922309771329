import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { PlayersMetaRuntype, PlayersMeta } from "../model";

export default async function LoadManagers(
    uri: string
): Promise<ResultWithError<PlayersMeta>> {
    const res = await get<PlayersMeta>({ url: `tournaments/${uri}/managers` });
    return CheckResultRuntime(PlayersMetaRuntype, res);
}

export const useLoadManagers = makeAPIHook(LoadManagers);
