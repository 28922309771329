import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { OpenPage } from "packages/history/history";
import { BigNoodle } from "src/jsx/fonts";

export default function Header() {
    return (
        <header className={css(Styles.header)}>
            <h1
                className={css(Styles.title)}
                onClick={OpenPage("campaign-vol-1")}
            >
                TMA <span className={css(Styles.title_black)}>Campaign</span>
            </h1>
        </header>
    );
}

const Styles = CreateScheet({
    header: {
        textAlign: "center",
        position: "relative",
    },
    title: {
        cursor: "pointer",
        display: "inline-block",
        fontFamily: [BigNoodle, "Roboto"],
        padding: 0,
        opacity: 0.9,
        fontWeight: 600,
        fontSize: 80,
        lineHeight: "70px",
        color: "#ffb100",
        margin: "70px 0 0 0",
        position: "relative",
        textShadow:
            "1px 1px rgb(0 0 0 / 10%), 2px 2px rgb(0 0 0 / 9%), 3px 3px rgb(0 0 0 / 8%), 4px 4px rgb(0 0 0 / 7%), 5px 5px rgb(0 0 0 / 6%), 6px 6px rgb(0 0 0 / 5%), 7px 7px rgb(0 0 0 / 4%), 8px 8px rgb(0 0 0 / 3%), 9px 9px rgb(0 0 0 / 2%), 10px 10px rgb(0 0 0 / 1%)",
    },
    title_black: {
        color: "#ffedd1",
    },
});
