import moment from "moment";
import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import PrettyTable from "packages/tables/PrettyTable.react";
import PrintTime from "packages/utils/PrintTime";
import { WHCResponse } from "packages/whc/model";
import { WHCTable } from "src/themes/tables";

export default function Top10({ payload }: { payload: WHCResponse }) {
    return (
        <div className={css(Styles.block)}>
            <PrettyTable
                {...{
                    thead: ["player", "when", "race time"],
                    tbody: payload.top10.map((record, i) => {
                        return [
                            record.player_username,
                            moment
                                .unix(record.created_at)
                                .format("MMM Do H:mm"),
                            PrintTime(record.time),
                        ];
                    }),
                    theme: WHCTable,
                    layout: {
                        theadColumnFontSize: 15,
                        tbodyColumnFontSize: 13,
                        tbodyPadding: "0 10px",
                        titlePadding: "3px 10px",
                    },
                    columnsSizes: ["auto", "auto", "auto", 150],
                }}
            />
            {payload.top10.length === 0 && (
                <div className={css(Styles.empty)}>no records yet</div>
            )}
        </div>
    );
}

const Styles = CreateScheet({
    block: {
        paddingTop: 20,
        fontSize: 12,
        "@media (max-width: 600px)": {
            flexDirection: "column",
        },
        "& table": {
            padding: 0,
        },
        "& thead tr th": {
            fontSize: 12,
            ":first-child": {
                paddingLeft: 30,
            },
        },
        "& tbody tr td": {
            fontFamily: "'IBM Plex Mono', monospace",
            ":first-child": {
                paddingLeft: 30,
            },
        },
        "& tbody tr": {
            ":first-child": {
                fontWeight: 600,
                color: "white",
            },
        },
    },
    empty: {
        color: "white",
        paddingLeft: 30,
    },
});
