import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { useState, Fragment, useEffect, useRef } from "react";
import Landing from "./parts/Landing.react";
import Tracks from "./parts/Tracks.react";
import SingleTrack from "./parts/Track.react";
import Description from "./parts/Description.react";
import Header from "./Header.react";
import PageHeader from "src/jsx/modules/Header.react";
import { history, OpenPage } from "packages/history/history";
import { useParams } from "react-router-dom";
import { WithBG } from "packages/aphrodite/WithBG";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { Orange } from "src/themes/spinner";
import { useLoadTMACompetition } from "packages/competitions/LoadTMACompetition";
import User from "./parts/User.react";

export default function TMA() {
    const [competition] = useLoadTMACompetition(true);
    const [Page, SetPage] = useState<
        "landing" | "tracks" | "track" | "user" | "rules" | "leaderboard"
    >("landing");
    const { id } = useParams<{ id: string }>();
    const pageRef = useRef<HTMLElement>(null);

    useEffect(() => {
        const parts = history.location.pathname.split("/");
        if (parts[2]) {
            if (parts[2] === "tracks") {
                if (parts[3]) {
                    SetPage("track");
                    return;
                } else {
                    SetPage("tracks");
                    return;
                }
            } else if (parts[2] === "users") {
                if (parts[3]) {
                    SetPage("user");
                    return;
                }
            } else if (parts[2] === "rules") {
                SetPage("rules");
                return;
            } else if (parts[2] === "leaderboard") {
                SetPage("leaderboard");
                return;
            }
        }

        SetPage("landing");
        // eslint-disable-next-line
    }, [history.location.pathname]);

    return (
        <article
            className={css(
                Styles.page,
                WithBG(
                    "#191716",
                    "linear-gradient(rgba(31, 36, 37, 0.4), rgba(31, 36, 37, 0.4)), url(/images/campaign-bg.jpg)"
                )
            )}
            ref={pageRef}
        >
            <PageHeader />
            <Header />
            <section className={css(Styles.description)}>
                25 maps, 20 mappers, different styles and difficulties
                <br />
                All in one Trackmania Mappers Assembly Campaign!
            </section>
            <section className={css(Styles.body)}>
                <nav>
                    <ul className={css(Styles.menu)}>
                        <li
                            className={css(
                                Styles.item,
                                (Page === "landing" ||
                                    Page === "user" ||
                                    Page === "rules") &&
                                    Styles.active
                            )}
                            onClick={OpenPage("campaign-vol-1")}
                        >
                            Competition
                        </li>
                        <li
                            className={css(
                                Styles.item,
                                (Page === "tracks" || Page === "track") &&
                                    Styles.active
                            )}
                            onClick={OpenPage("campaign-vol-1/tracks")}
                        >
                            Campaign
                        </li>
                    </ul>
                </nav>
                <WithSpinner theme={Orange} suspense={competition.isLoading}>
                    {competition.payload && (
                        <Fragment>
                            {Page === "tracks" ? (
                                <Tracks tracks={competition.payload.tracks} />
                            ) : Page === "track" ? (
                                <SingleTrack
                                    tracks={competition.payload.tracks}
                                    id={id}
                                />
                            ) : Page === "user" ? (
                                <User
                                    competition={competition.payload}
                                    id={id}
                                />
                            ) : Page === "rules" ? (
                                <Description />
                            ) : (
                                <Landing
                                    leaderboard={
                                        competition.payload.leaderboard
                                    }
                                    last_updated={
                                        competition.payload.last_updated
                                    }
                                />
                            )}
                        </Fragment>
                    )}
                </WithSpinner>
            </section>
        </article>
    );
}

const Styles = CreateScheet({
    page: {
        minHeight: "100vh",
        color: "white",
        display: "flex",
        flexDirection: "column",
    },
    description: {
        margin: "30px 0 0 0",
        fontSize: 20,
        textAlign: "center",
        lineHeight: "40px",
        padding: "0 20px",
        textShadow:
            "1px 1px rgb(0 0 0 / 10%), 2px 2px rgb(0 0 0 / 9%), 3px 3px rgb(0 0 0 / 8%)",
        color: "#fff3e1",
        fontWeight: 400,
    },
    body: {
        flexGrow: 1,
        paddingBottom: 70,
        position: "relative",
        background: "white",
        width: "100%",
        maxWidth: 700,
        padding: "0",
        borderRadius: 10,
        margin: "30px auto 30px auto",
        color: "#38342f",
    },
    menu: {
        display: "flex",
        margin: "10px 0 0 0",
        padding: 0,
        listStyle: "none",
        color: "#6f5636",
        borderBottom: "1px solid #ffedd1",
        justifyContent: "center",
        userSelect: "none",
    },
    item: {
        margin: 0,
        padding: "10px 30px",
        fontWeight: 500,
        fontSize: "18",
        cursor: "pointer",
        borderBottom: "2px solid transparent",
        transition: "border 200ms",
        ":hover": {
            borderColor: "#ffb100",
        },
    },
    active: {
        color: "#38342f",
        borderColor: "#ffb100",
    },
});
