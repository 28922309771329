import { css } from "aphrodite";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import RegistrationBlock from "../modules/RegistrationBlock.react";
import { PrimaryReadOnluDraft } from "src/themes/draftjs";
import GeneralStyles from "../modules/GeneralStyles";
import { PageProps } from "../model";
import { TimeAttackLeaderboard } from "./TimeAttack.react";
import moment from "moment";

export default function Landing(props: PageProps) {
    return (
        <div className={css(GeneralStyles.content)}>
            <DraftEditorReact
                {...{
                    theme: PrimaryReadOnluDraft,
                    defaultValue: props.tournament.description || "",
                    readOnly: true,
                }}
            />
            {props.tournament.has_registration && (
                <RegistrationBlock {...props} />
            )}
            {props.tournament.has_time_attack &&
                props.tournament.tracks.length > 0 &&
                (!props.tournament.time_attack_start_at ||
                    moment().unix() >
                        props.tournament.time_attack_start_at) && (
                    <>
                        <h2
                            className={css(
                                GeneralStyles.h2,
                                props.theme.color("secondary")
                            )}
                        >
                            Time Attack leaderboard (top5)
                        </h2>
                        <TimeAttackLeaderboard
                            {...{
                                uri: props.tournament.uri,
                                theme: props.theme,
                                players: props.tournament.players,
                                tracks: props.tournament.tracks,
                                prefix: props.prefix,
                                format_id:
                                    props.tournament.time_attack_format_id,
                                total_tracks: props.tournament.tracks.length,
                                limit: 5,
                            }}
                        />
                    </>
                )}
            {props.prefix !== "campaign" && (
                <div className={css(GeneralStyles.no_tma)}>
                    tma.gg is not responsible for the organization of the
                    tournament, the payment of prizes etc. please contact the
                    tournament manager with any questions
                </div>
            )}
        </div>
    );
}
