import { MiniCampaignRuntype } from "packages/nadeo/model";
import { ColorsRuntype } from "packages/themes/theme";
import { TracksRuntype } from "packages/trackmania/model";
import PrintNumber from "packages/utils/PrintNumber";
import PrintTime from "packages/utils/PrintTime";
import * as r from "runtypes";

export const TimeAttackFormats = {
    pb_per_track: {
        id: 1,
        score_title: "total PBs",
        score_func: PrintNumber,
        has_single_score: false,
        has_double_score: false,
    },
    pb_sum: {
        id: 2,
        score_title: "PBs sum",
        score_func: PrintTime,
        has_single_score: false,
        has_double_score: false,
    },
    time_score: {
        id: 3,
        score_title: "Score",
        score_func: PrintNumber,
        has_single_score: true,
        has_double_score: false,
    },
    position_score: {
        id: 4,
        score_title: "Score",
        score_func: PrintNumber,
        has_single_score: true,
        has_double_score: false,
    },
    time_position_score: {
        id: 5,
        score_title: "Score (Position + Time)",
        score_func: PrintNumber,
        has_single_score: true,
        has_double_score: true,
    },
    ats_only: {
        id: 6,
        score_title: "ATs / tracks",
        score_func: PrintNumber,
        has_single_score: false,
        has_double_score: false,
    },
};

export function GetFormat(id: number) {
    return Object.values(TimeAttackFormats).find((format) => format.id === id);
}

export const FormatRuntype = r.Record({
    id: r.Number,
    name: r.String,
    description: r.String,
    has_time_coef: r.Boolean,
    has_position_coef: r.Boolean,
});
export type Format = r.Static<typeof FormatRuntype>;

export const PlayerMetaRuntype = r.Record({
    guid: r.String,
    username: r.String,
    created_at: r.Number,
    account_id: r.String,
});
export type PlayerMeta = r.Static<typeof PlayerMetaRuntype>;

export const PlayersMetaRuntype = r.Array(PlayerMetaRuntype);
export type PlayersMeta = r.Static<typeof PlayersMetaRuntype>;

export const TournamentPlayerRuntype = r.Record({
    user: r.Record({
        guid: r.String,
        username: r.String,
        created_at: r.Number,
        account_id: r.String,
        zone_name: r.String.optional(),
    }),
    position: r.Number,
    score: r.Number,
    position_score: r.Number,
    time_score: r.Number,
    total_records: r.Number,
    total_wrs: r.Number,
    tracks: r
        .Dictionary(
            r.Record({
                track_uid: r.String,
                score: r.Record({
                    position: r.Number,
                    score: r.Number,
                    time_score: r.Number,
                    position_score: r.Number,
                }),
            }),
            r.String
        )
        .Or(r.Null),
});
export type TournamentPlayer = r.Static<typeof TournamentPlayerRuntype>;

export const TournamentPlayersRuntype = r.Array(TournamentPlayerRuntype);
export type TournamentPlayers = r.Static<typeof TournamentPlayersRuntype>;

export const ImagesRuntype = r.Record({
    logo: r.String,
    background: r.String,
});
export type Images = r.Static<typeof ImagesRuntype>;

export const RecordRuntype = r.Record({
    account_id: r.String,
    track_id: r.String,
    track_uid: r.String,
    time: r.Number,
    file_url: r.String,
    player: r.Record({
        username: r.String,
    }),
    score: r.Record({
        position: r.Number,
        score: r.Number,
        time_score: r.Number,
        position_score: r.Number,
    }),
});
export type Record = r.Static<typeof RecordRuntype>;

export const RecordsRuntype = r.Array(RecordRuntype);
export type Records = r.Static<typeof RecordsRuntype>;

export const TournamentRuntype = r.Record({
    uri: r.String,
    title: r.String,
    is_public: r.Boolean,
    is_player: r.Boolean,
    is_manager: r.Boolean,
    start_at: r.Number.Or(r.Null),
    end_at: r.Number.Or(r.Null),
    short_description: r.String.Or(r.Null),
    description: r.String.Or(r.Null),
    rules: r.String.Or(r.Null),
    has_registration: r.Boolean,
    registration_start_at: r.Number.Or(r.Null),
    registration_end_at: r.Number.Or(r.Null),
    tracks_description: r.String.Or(r.Null),
    club_id: r.Number.Or(r.Null),
    campaign_id: r.Number.Or(r.Null),
    campaign: MiniCampaignRuntype.Or(r.Null),
    has_time_attack: r.Boolean,
    time_attack_description: r.String.Or(r.Null),
    time_attack_start_at: r.Number.Or(r.Null),
    time_attack_end_at: r.Number.Or(r.Null),
    time_attack_format_id: r.Number,
    time_attack_format: FormatRuntype,
    time_attack_time_coef: r.Number,
    time_attack_position_coef: r.Number,
    has_playoffs: r.Boolean,
    players_description: r.String.Or(r.Null),
    playoffs_description: r.String.Or(r.Null),
    images: ImagesRuntype.Or(r.Null),
    theme: ColorsRuntype.Or(r.Null),
    tracks: TracksRuntype,
    players: TournamentPlayersRuntype,
    records: r.Dictionary(RecordsRuntype, r.String),
    snapshots_created_at: r.Number.Or(r.Null),
    is_upading_snapshots_stopped: r.Boolean,
});
export type Tournament = r.Static<typeof TournamentRuntype>;
