import { CreateScheet, css } from "aphrodite";
import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import GenericStyles from "src/jsx/forms/GenericStyles";
import useSafeState from "packages/hooks/useSafeState";
import { PrimaryFilled, LoadButton } from "src/themes/button";
import { useState } from "react";
import { Cup } from "packages/whc/model";
import { MakeInput, PrimaryInput } from "src/themes/input";
import { useLoadNextWHC } from "packages/whc/API/LoadNextCup";
import UpdateNextCupAPI from "packages/whc/API/UpdateNextCup";
import { DarkTheme } from "src/themes/lightbox";
import showWarning from "packages/lightbox/warning.react";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { PurpleithPath } from "src/themes/spinner";
import PrettyTable from "packages/tables/PrettyTable.react";
import { DarkTable } from "src/themes/tables";
import moment from "moment";
import { Danger } from "src/themes/colors";

export default function NextWeekEditor({
    close,
}: ComponentProps<{
    cup: Cup;
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header>Edit next week settings</Header>
            <Body>
                <NextWeekEditorForm close={close} />
            </Body>
        </Lightbox>
    );
}

function NextWeekEditorForm({ close }: { close: () => void }) {
    const [nextCupState, reloadNextCup] = useLoadNextWHC(true);

    const [state, setState] = useSafeState<{
        tmx_id: string;
    }>({
        tmx_id: "",
    });
    const [isSaving, setIsSaving] = useState(false);

    async function OnClickSave() {
        setIsSaving(true);

        if (state.tmx_id.trim().length > 0) {
            const err = await UpdateNextCupAPI(parseInt(state.tmx_id.trim()));
            setIsSaving(false);
            if (err !== null) {
                showWarning(err.text, DarkTheme);
            } else {
                reloadNextCup();
                setState({ tmx_id: { $set: "" } });
            }
        } else {
            close();
        }
    }

    return (
        <WithSpinner
            {...{
                suspense: nextCupState.isLoading,
                theme: PurpleithPath,
            }}
        >
            <section>
                <h2 className={css(GenericStyles.section_title)}>Next week</h2>
                <section className={css(GenericStyles.section)}>
                    <label className={css(GenericStyles.section_label)}>
                        General
                    </label>
                    {nextCupState.payload ? (
                        <PrettyTable
                            {...{
                                tbody: [
                                    [
                                        "track name",
                                        <strong>
                                            {nextCupState.payload.track.title}
                                        </strong>,
                                    ],
                                    [
                                        "tmx",
                                        <strong>
                                            <a
                                                style={{ color: "white" }}
                                                href={
                                                    "https://trackmania.exchange/mappack/manage/2323"
                                                    //nextCupState.payload.track
                                                    //    .tmx_link
                                                }
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                link
                                            </a>
                                        </strong>,
                                    ],
                                    [
                                        "start at",
                                        <strong>
                                            {moment
                                                .unix(
                                                    nextCupState.payload
                                                        .current_state.start_at
                                                )
                                                .utc()
                                                .format("MMMM Do YYYY, H:mm")}
                                        </strong>,
                                    ],
                                    [
                                        "end at",
                                        <strong>
                                            {moment
                                                .unix(
                                                    nextCupState.payload
                                                        .current_state.end_at
                                                )
                                                .utc()
                                                .format("MMMM Do YYYY, H:mm")}
                                        </strong>,
                                    ],
                                ],
                                theme: DarkTable,
                                layout: {
                                    theadColumnFontSize: 13,
                                    tbodyColumnFontSize: 12,
                                    titlePadding: "3px 10px 13px 10px",
                                },
                            }}
                        />
                    ) : (
                        <h2 style={{ fontSize: 18, color: Danger.default }}>
                            Next cup is not prepared
                        </h2>
                    )}
                </section>
                <section className={css(GenericStyles.section)}>
                    <label className={css(GenericStyles.section_label)}>
                        New next week track TMX ID
                    </label>
                    <input
                        {...{
                            placeholder: "tmx track id",
                            className: css(
                                MakeInput(PrimaryInput, { width: "100%" })
                            ),
                            value: state.tmx_id,
                            onChange: (e) => {
                                setState({ tmx_id: { $set: e.target.value } });
                            },
                        }}
                    />
                </section>
                <hr className={css(GenericStyles.divider)} />

                <footer className={css(Styles.footer)}>
                    <LoadButton
                        {...{
                            theme: PrimaryFilled,
                            loading: isSaving,
                            className: css(Styles.button),
                            onClick: OnClickSave,
                        }}
                    >
                        Save
                    </LoadButton>
                </footer>
            </section>
        </WithSpinner>
    );
}

const Styles = CreateScheet({
    footer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: 15,
    },
});
