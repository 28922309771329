import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import PageHeader from "src/jsx/modules/Header.react";
import { Colors } from "./BaseStyles";
import { OpenPage } from "packages/history/history";
import { useLoadWHC } from "packages/whc/API/LoadCurrentCup";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { PurpleithPath } from "src/themes/spinner";
import ManagerPanel from "./components/ManagerPanel.react";
import Header from "./components/Header";
import CurrentState from "./components/CurrentState";
import CupInfos from "./components/CupInfos";
import { Benn } from "../fonts";

export default function HotPursuit() {
    const [state, reload] = useLoadWHC(true);


    return (
        <article className={css(Styles.page)}>
            {state.payload?.cup.is_manager && (
                <ManagerPanel
                    {...{
                        cup: state.payload.cup,
                        tournament: state.payload,
                    }}
                />
            )}
            <PageHeader simple={true} dark={true} withDelta={true} />
            <WithSpinner
                {...{
                    theme: PurpleithPath,
                    suspense: state.isLoading,
                    grow: true,
                }}
            >
                <header className={css(Styles.header)}>
                    <h1
                        className={css(Styles.title)}
                        onClick={OpenPage("hot-pursuit")}
                    >
                        <div className={css(Styles.title_content)}>
                            Hot Pursuit
                            <small className={css(Styles.beta)}>beta</small>
                        </div>
                    </h1>
                    <div className={css(Styles.chips)}>
                        <div className={css(Styles.chip)}>
                            <div>1 WEEK</div>
                        </div>
                        <div className={css(Styles.chip)}>
                            <div>1 MAP</div>
                        </div>
                        <div className={css(Styles.chip)}>
                            <div>1 WINNER</div>
                        </div>
                    </div>
                </header>
                <div className={css(Styles.body)}>
                    {state.payload ? (
                        <>
                            <Header payload={state.payload} reload={reload} />
                            <div className={css(Styles.bodyState)}>
                                <CurrentState payload={state.payload} />
                            </div>
                            <CupInfos payload={state.payload} />
                        </>
                    ) : (
                        <p className={css(Styles.soon)}>COMING SOON...</p>
                    )}
                </div>
            </WithSpinner>
        </article>
    );
}

const Styles = CreateScheet({
    page: {
        minHeight: "100vh",
        color: "white",
        display: "flex",
        flexDirection: "column",
        transition: "background-image 1s, background 1s",
        fontFamily: "Ubuntu",
        paddingBottom: 100,
        position: "relative",
        backgroundColor: "black",
    },
    header: {
        padding: "50px 20px 40px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    beta: {
        fontSize: 12,
        fontFamily: "'IBM Plex Mono', monospace",
        position: "absolute",
        bottom: 2,
        fontStyle: "italic",
    },
    title: {
        fontFamily: [Benn],
        fontSize: 40,
        margin: 0,
        padding: 0,
        fontWeight: 700,
        textAlign: "center",
        cursor: "pointer",
    },
    title_content: {
        position: "relative",
        textShadow:
            "1px 1px rgb(0 0 0 / 10%), 2px 2px rgb(0 0 0 / 9%), 3px 3px rgb(0 0 0 / 8%), 4px 4px rgb(0 0 0 / 7%), 5px 5px rgb(0 0 0 / 6%), 6px 6px rgb(0 0 0 / 5%), 7px 7px rgb(0 0 0 / 4%), 8px 8px rgb(0 0 0 / 3%), 9px 9px rgb(0 0 0 / 2%), 10px 10px rgb(0 0 0 / 1%)",
        zIndex: 1,
        ":after": {
            position: "absolute",
            zIndex: -2,
            left: 0,
            top: 0,
            content: `"Hot Pursuit"`,
            filter: "blur(1px)",
            color: "#ff0093",
            transform: "scale(1.01, 1)",
        },
        ":before": {
            position: "absolute",
            zIndex: -1,
            left: 0,
            top: 0,
            content: `"Hot Pursuit"`,
            filter: "blur(5px)",
            color: "rgb(120 31 198)",
            transform: "scale(1.01, 1) translate(0.5px, 0px)",
        },
    },
    body: {
        fontSize: 14,
        maxWidth: 800,
        margin: "0 auto",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        color: Colors.LightGray,
        fontFamily: "'IBM Plex Mono', monospace",
        opacity: 0.8,
    },
    chip: {
        fontSize: 14,
        fontWeight: 600,
        margin: "10px 20px",
        flexGrow: 1,
        textShadow:
            "1px 1px rgb(0 0 0 / 10%), 2px 2px rgb(0 0 0 / 9%), 3px 3px rgb(0 0 0 / 8%), 4px 4px rgb(0 0 0 / 7%), 5px 5px rgb(0 0 0 / 6%), 6px 6px rgb(0 0 0 / 5%), 7px 7px rgb(0 0 0 / 4%), 8px 8px rgb(0 0 0 / 3%), 9px 9px rgb(0 0 0 / 2%), 10px 10px rgb(0 0 0 / 1%)",
        position: "relative",
    },
    bodyState: {
        position: "relative",
    },
    spinner: {
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 2,
        padding: 30,
    },
    soon: {
        textAlign: "center",
        fontFamily: "'IBM Plex Mono', monospace",
        fontSize: 20,
    },
});
