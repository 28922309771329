import React, { ComponentType, Fragment } from 'react';
import { css, StyleDeclaration } from 'aphrodite';
import { MakeTableCSS, MakeColumnStyles, SortableHeaderStyles } from './PrettyTable.css';
import { TableLayout, TableTheme } from './table.theme';
import { DefaultLayout } from 'src/themes/tables';

export type TableHeader = Array<string|number|undefined|null|ComponentType|JSX.Element>
export type TableBodyCol = string|number|undefined|null|ComponentType|JSX.Element;
export type TableBodyRow = Array<TableBodyCol>;
export type TableBody = Array<TableBodyRow>

interface props<T> {
    title?:          string|ComponentType
    thead?:          TableHeader
    tbody:           TableBody
    columnsSizes?:   Array<number|"auto">
    tRowClassnames?: Array<StyleDeclaration | null>
    onItemClick?:    (item: T, e: React.MouseEvent) => void,
    items?:          Array<T>,
    theme:           TableTheme,
    layout?:         TableLayout,
}

// !!!
// PLEASE DON'T JUST CHANGE STYLES
// YOU CAN MAKE THEM CONFIGURABLE
// E.G. IF YOU NEED TO HIDE BORDER
// MAKE A NEW FLAG INSIDE CFG
// AND CHANGE MakeTableCSS FUNCTION

export default function PrettyTable<T>({
    title,
    thead,
    tbody,
    columnsSizes,
    items,
    onItemClick,
    theme,
    layout,
    tRowClassnames,
}:props<T>) {
    if (!layout) {
        layout = DefaultLayout
    }
    
    const TSize = tbody.length > 0 ? Math.max(...tbody.map(trows => trows.length)) : 0;
    const Styles = MakeTableCSS(theme, layout);

    const Title = title;
    
    return (
        <section className={ css(Styles.container) }>
            { Title ? (
                <h2 className={ css(Styles.title) }>
                { typeof Title == "string" ? Title : <Title />}
                </h2>
            ) : null }
            <table className={ css(Styles.table) } cellPadding="0" cellSpacing="0" >
                <thead className={ css(Styles.thead) } >
                    <tr>
                        { thead && thead.map((HColumn, key) => (
                            <th {...{
                                key,
                                className: css(
                                    MakeColumnStyles(columnsSizes && columnsSizes[key] ? columnsSizes[key] : "auto"),
                                    Styles.theadColumn,
                                )
                            }}>
                                { HColumn ? (
                                    <Fragment>
                                        { typeof HColumn == "string" || typeof HColumn == "number" || typeof HColumn !== "function" ? HColumn : <HColumn /> }
                                    </Fragment>
                                ) : "" }
                            </th>
                        )) }
                    </tr>
                </thead>
                <tbody className={ css(Styles.tbody) } >
                    { tbody && tbody.map((TColumns, key) => (
                        <tr {...{
                            key,
                            className:  css(
                                Styles.trow,
                                typeof onItemClick === "function" && Styles.clickable,
                                tRowClassnames && tRowClassnames[key],
                            ),
                            onClick:    (e) => {
                                if (items && typeof onItemClick === "function") {
                                    e.persist();
                                    onItemClick(items[key], e)
                                }
                            },
                        }} >
                            { [...new Array(TSize)].map((k,i) => {
                                const TColumn = TColumns[i];
                                return (
                                    <td {...{
                                        className: css(
                                            MakeColumnStyles(columnsSizes && columnsSizes[i] ? columnsSizes[i] : "auto"),
                                            Styles.tbodyColumn,
                                        ),
                                        key: i,
                                    }}>
                                        { TColumn || TColumn === 0 ? (
                                            <Fragment>
                                                { typeof TColumn == "string" || typeof TColumn == "number" ? (
                                                    <Fragment>
                                                        { TColumn }
                                                    </Fragment>
                                                ) : (
                                                    typeof TColumn === "function" ? <TColumn /> : TColumn
                                                )}
                                            </Fragment>
                                        ) : "" }
                                    </td>
                                )
                            } ) }
                        </tr>
                    ))}          
                </tbody>
            </table>
        </section>
    )
}

export function SortableHeader({ 
    children,
    direction,
    onClick,
    theme,
}:React.PropsWithChildren<{
    direction: "ASC" | "DESC" | false,
    onClick:   () => void,
    theme:     TableTheme,
}>) {

    const Styles = SortableHeaderStyles(theme, direction);
    return(
        <span onClick={onClick} className={css(Styles.main)}>
            {children}
            <span className={css(Styles.indicator)} />
        </span>
    );
}