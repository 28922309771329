import { Fragment, useEffect, useState } from "react";
import Renderer from "packages/lightbox/ModalRoot.react";
import RouterApp from "./router.react";
import { listenOnSessionChanges, unlistenOnSessionChanges, LoadSession } from "packages/session/session";

export default function App() {
    const [ Loading, SetLoading ] = useState(true);
    const [ Reload, SetReload ] = useState(false);

    function onSessionChange() {
        SetReload(!Reload);
    }

    useEffect(() => {
        listenOnSessionChanges(onSessionChange)

        return () => {
            unlistenOnSessionChanges(onSessionChange);
        }
    });

    useEffect(() => {
        async function init() {
            try {
                await LoadSession();

                SetLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        init();
    }, []);

    if (Loading) {
        return null;
    }

    return (
        <Fragment>
            <Renderer />
            <RouterApp />
        </Fragment>
    )
}