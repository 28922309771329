import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { Competition, Track, Replay } from "packages/competitions/model";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import PrintNumber from "packages/utils/PrintNumber";
import PrintTime from "packages/utils/PrintTime";
import { Fragment } from "react";
import { BigNoodle } from "src/jsx/fonts";
import { CampaignTable } from "src/themes/tables";
import { BaseStyles } from "./BaseStyles";

export default function User({
    competition,
    id,
}: {
    competition: Competition;
    id: string;
}) {
    const user = competition.leaderboard.find((t) => t.account.name === id);
    // BAD
    const userPos = competition.leaderboard.findIndex(
        (t) => t.account.name === id
    );
    let tracks: { track: Track; replay: Replay; pos: number }[] = [];

    competition.tracks.forEach((track) => {
        const replay = track.records.find((r) => r.account.name === id);
        const replayPos = track.records.findIndex((r) => r.account.name === id);
        if (replay) {
            tracks.push({
                track,
                replay,
                pos: replayPos + 1,
            });
        }
    });

    tracks = tracks.sort((a, b) => b.replay.score - a.replay.score);

    return (
        <Fragment>
            <div className={css(Styles.leaderboard)}>
                {user ? (
                    <Fragment>
                        <div className={css(BaseStyles.meta, Styles.user)}>
                            <strong className={css(Styles.username)}>
                                {user.account.name}
                            </strong>
                            <div className={css(Styles.pos)}>
                                <div className={css(Styles.pos_num)}>
                                    #{userPos + 1}
                                </div>
                                <div className={css(Styles.pos_label)}>
                                    score
                                </div>
                                <div className={css(Styles.pos_score)}>
                                    {PrintNumber(user.score)}
                                </div>
                            </div>
                        </div>
                        <PrettyTable
                            {...{
                                thead: [
                                    "map",
                                    "position",
                                    "score (pos/time)",
                                    "time",
                                ],
                                tbody: tracks
                                    .sort((a, b) => (a.pos > b.pos ? 1 : -1))
                                    .map((track) => {
                                        return [
                                            () => (
                                                <strong>
                                                    <a
                                                        {...{
                                                            href: `/campaign-vol-1/tracks/${track.track.uid}`,
                                                            onClick: OpenPage(
                                                                `campaign-vol-1/tracks/${track.track.uid}`
                                                            ),
                                                            className: css(
                                                                BaseStyles.link
                                                            ),
                                                        }}
                                                    >
                                                        {track.track.clear_name}
                                                    </a>
                                                </strong>
                                            ),
                                            track.pos,
                                            <span>
                                                {PrintNumber(
                                                    track.replay.score
                                                )}{" "}
                                                (
                                                {PrintNumber(
                                                    track.replay.pos_score
                                                )}
                                                /
                                                {PrintNumber(
                                                    track.replay.time_score
                                                )}
                                                )
                                            </span>,
                                            PrintTime(track.replay.time),
                                        ];
                                    }),
                                theme: CampaignTable,
                                layout: {
                                    theadColumnFontSize: 15,
                                    tbodyColumnFontSize: 13,
                                    tbodyPadding: "0 10px",
                                },
                            }}
                        />
                    </Fragment>
                ) : (
                    <div>no such player</div>
                )}
            </div>
        </Fragment>
    );
}

const Styles = CreateScheet({
    leaderboard: {
        padding: "0 0 10px 0",
        zIndex: 1,
        minHeight: 200,
        "& h2": {
            margin: 0,
            padding: "0 20px",
            color: "#525252",
            fontSize: 16,
        },
    },
    user: {
        fontSize: 22,
        position: "relative",
        margin: "20px 20px 20px 20px",
        padding: 0,
        height: 67,
        display: "flex",
        alignItems: "center",
        color: "#6f5636",
    },
    username: {
        fontFamily: [BigNoodle, "Roboto"],
        fontSize: 30,
        color: "#436da9",
    },
    pos: {
        position: "absolute",
        right: 10,
        top: 0,
        background: "#4c1b67",
        color: "white",
        textAlign: "center",
        height: 67,
        width: 67,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
    },
    pos_num: {
        fontSize: 29,
        fontWeight: 600,
    },
    pos_label: {
        fontSize: 9,
    },
    pos_score: {
        fontSize: 12,
    },
});
