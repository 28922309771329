import { CSSPropertiesComplete } from "aphrodite-source/lib/index";
import { CreateScheet } from "packages/aphrodite/aphrodite";
import ColorShadeBlendConvert from "packages/helpers/ColorShadeBlendConvert";
import { PrstToHex } from "packages/helpers/HexToDec";
import * as r from "runtypes";

export const ColorsRuntype = r.Record({
    primary: r.String,
    secondary: r.String,
    text: r.String,
    background_one: r.String,
    background_two: r.String,
});
export type Colors = r.Static<typeof ColorsRuntype>;

type ColorsList = keyof Colors;
export const ColorsKeys: Array<ColorsList> = Object.keys(
    ColorsRuntype.fields
) as Array<ColorsList>;

interface ColorSettings {
    opacity?: number; // VALUES: 0.01 -> 1.00 OR 1 - 100%
    lighter?: number; // VALUES: 0.01 -> 1.00 OR 1 - 100%
    darker?: number; // VALUES: 0.01 -> 1.00 OR 1 - 100%
}

interface ModifierSettings extends ColorSettings {
    on_active?: boolean;
    on_focus?: boolean;
    on_hover?: boolean;
}

export type Theme = {
    get_color: (color: ColorsList, settings?: ColorSettings) => string;
    border: (color: ColorsList, settings?: ModifierSettings) => object;
    color: (color: ColorsList, settings?: ModifierSettings) => object;
    background: (color: ColorsList, settings?: ModifierSettings) => object;
    fill: (color: ColorsList, settings?: ModifierSettings) => object;
    gradient: (color1: ColorsList, color2: ColorsList, angle: string) => object;
};

export default function CreateThemeStyles(colors: Colors): Theme {
    return {
        get_color: (color: ColorsList, settings?: ColorSettings) => {
            return ApplyColorSettings(colors, color, settings);
        },
        border: CreateSingleStyle(colors, "borderColor"),
        color: CreateSingleStyle(colors, "color"),
        background: CreateSingleStyle(colors, "backgroundColor"),
        fill: CreateSingleStyle(colors, "fill"),
        gradient: (color1: ColorsList, color2: ColorsList, angle: string) =>
            CreateScheet({
                base: {
                    backgroundColor: color1,
                    backgroundImage: `linear-gradient(${angle}, ${colors[color1]} 0%, ${colors[color2]} 100%)`,
                },
            }).base,
    };
}

function CreateSingleStyle(colors: Colors, prop: keyof CSSPropertiesComplete) {
    return (color: ColorsList, settings?: ModifierSettings) => {
        let colorToUse = ApplyColorSettings(colors, color, settings);

        const props = { [prop]: colorToUse };

        if (settings?.on_hover) {
            return CreateScheet({
                base: {
                    ":hover": props,
                },
            }).base;
        } else if (settings?.on_active) {
            return CreateScheet({
                base: {
                    ":active": props,
                },
            }).base;
        } else if (settings?.on_focus) {
            return CreateScheet({
                base: {
                    ":focus": props,
                },
            }).base;
        }

        return CreateScheet({ base: props }).base;
    };
}

function ApplyColorSettings(
    colors: Colors,
    color: ColorsList,
    settings?: ColorSettings
) {
    let colorToUse: string = colors[color],
        opacity = "";

    if (settings) {
        if (settings.opacity !== undefined) {
            if (settings.opacity < 1) {
                settings.opacity = settings.opacity / 100;
            }
            opacity = PrstToHex(Math.abs(settings.opacity)) || "";
        }

        if (settings.lighter !== undefined || settings.darker !== undefined) {
            let prst =
                settings.lighter !== undefined
                    ? settings.lighter
                    : settings.darker !== undefined
                    ? -settings.darker
                    : 0;
            if (prst > 1 || prst < -1) {
                prst = prst / 100;
            }
            prst = prst > 1 ? 1 : prst < -1 ? -1 : prst;
            colorToUse = ColorShadeBlendConvert(prst, colorToUse);
        }
    }

    return `${colorToUse}${opacity}`;
}
