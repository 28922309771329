import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { Tracks, TracksRuntime } from "./model";

export default async function LoadShowcaseTracks(): Promise<
    ResultWithError<Tracks>
> {
    // hardcoded for now
    let res = await get<Tracks>({
        url: "tracks",
        query: { list_type: "showcase" },
    });
    res = CheckResultRuntime(TracksRuntime, res);

    return res;
}

export const useLoadShowcaseTracks = makeAPIHook(LoadShowcaseTracks);
