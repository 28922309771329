/* Use lazy loading for huge pages please, just wrap your page into React.Suspense */
/* keep in mind that pages w/ lazy loading may brake page switch animation */
/* maybe we need a flap on page level to which would be use to enable page switch animation */
import LazyLanding from "src/jsx/landing/Landing.react";
import Branding from "src/jsx/branding/Branding.react";
import TMA from "src/jsx/competitions/tma/TMA.react";
import PentaMap from "src/jsx/maps/Pentagruelica.react";
import Showcase from "src/jsx/showcase/Showcase.react";
import LoopCamchange from "src/jsx/guides/LoopCamchange.react";
import Triangles from "src/jsx/guides/3dTriangles.react";
import Awards from "src/jsx/awards/Awards.react";
import BIPC from "src/jsx/archive/BIPC.react";
import WHC from "src/jsx/whc/HotPursuit";

import Page404React from "src/jsx/errors/404page.react";
import { ComponentType } from "react";
import SafeUpdate from "packages/helpers/SafeUpdate";
import { matchPath, RedirectProps } from "react-router-dom";
import * as runtypes from "runtypes";
import Tournament, {
    TournamentPageMetaRuntype,
} from "src/jsx/tournaments/Tournament.react";
import TMACampaign from "src/jsx/tournaments/TMACampaign";

export const EmptyMeta = runtypes.Null;

export type PageProps<T = null> = {
    id: string;
    childRoutes: Page[];
    meta: {
        is_loading: boolean;
        is_error: boolean;
        payload: T;
        redirect_source?: string;
    };
};

export const pages: Page<any>[] = [];
export const redirects: RedirectProps[] = [];

export const Page404: Page = {
    id: "404error",
    path: "",
    exact: true,
    Component: Page404React,
    meta: EmptyMeta,
};

/* KEEP IN MIND THAT PAGE ADDED JUST HERE W/O Go HANDLER WILL BE SOFT 404 */
/* meta should be used to pass only very important and small amount of data e.g. access error otherwise page loading will be slow */
export const RoutesObject = {
    // arhcive
    bipc: {
        path: ["bipc", "bipc/ta", "bipc/playoffs"],
        exact: true,
        Component: BIPC,
        meta: EmptyMeta,
    },
    tma: {
        path: [
            "campaign-vol-1",
            "campaign-vol-1/tracks/:id",
            "campaign-vol-1/users/:id",
            "campaign-vol-1/tracks",
            "campaign-vol-1/rules",
            "campaign-vol-1/leaderboard",
        ],
        exact: true,
        Component: TMA,
        meta: EmptyMeta,
    },
    // real
    landing: { path: "", exact: true, Component: LazyLanding, meta: EmptyMeta },
    branding: {
        path: "graphics",
        exact: true,
        Component: Branding,
        meta: EmptyMeta,
    },
    pentagruelica: {
        path: "maps/pentagruelica",
        exact: true,
        Component: PentaMap,
        meta: EmptyMeta,
    },
    showcase: {
        path: "showcase",
        exact: true,
        Component: Showcase,
        meta: EmptyMeta,
    },
    LoopCamchange: {
        path: "guides/loop_camchange",
        exact: true,
        Component: LoopCamchange,
        meta: EmptyMeta,
    },
    Triangles: {
        path: "guides/3d_triangles",
        exact: true,
        Component: Triangles,
        meta: EmptyMeta,
    },
    awards: { path: "tma", exact: true, Component: Awards, meta: EmptyMeta },
    whc: {
        path: ["hot-pursuit", "hot-pursuit/:id"],
        exact: true,
        Component: WHC,
        meta: EmptyMeta,
    },
    cups: {
        path: ["cups/:uri", "cups/:uri/*"],
        exact: true,
        Component: Tournament,
        meta: TournamentPageMetaRuntype,
    },
    campaign: {
        path: ["campaign", "campaign/*"],
        exact: true,
        Component: TMACampaign,
        meta: TournamentPageMetaRuntype,
    },
};

type PageEntry = Omit<Page, "id" | "subRoutes"> & {
    subRoutes?: Record<string, PageEntry>;
};

function MakePage(key: PageID, obj: PageEntry): Page {
    let page: Page = { ...obj, id: key, subRoutes: [] };
    if (obj.subRoutes) {
        page.subRoutes = Object.entries(obj.subRoutes).map((entry) =>
            MakePage(entry[0] as PageID, entry[1])
        );
    }

    return page;
}

const Routes: Page[] = Object.entries(RoutesObject).map((entry) =>
    MakePage(entry[0] as PageID, entry[1])
);
AddRoutes(Routes);

export type PageID = "404error" | keyof typeof RoutesObject;

export interface Page<T = null> {
    id: PageID;
    parent_id?: string;
    path: string | string[];
    exact: boolean;
    Component: ComponentType<PageProps<T>>;
    meta: runtypes.Runtype;
    subRoutes?: Page<T>[];
}

export function AddRoutes<T = null>(routes: Page<T>[], parent?: Page<T>) {
    routes.forEach((route) => {
        if (parent) {
            route = SafeUpdate(route, { parent_id: { $set: parent.id } });
            route = SafeUpdate(route, {
                path: { $set: `${parent.path}/${route.path}` },
            });
        }

        pages.push(route);
        if (route.subRoutes?.length) {
            AddRoutes<T>(route.subRoutes, route);
        }
    });
}

export function AddRedirect(redirect: RedirectProps) {
    redirects.push(redirect);
}

export function FindPage(Pages: Page[], path: string) {
    return Pages.find((page) => {
        return matchPath(path, {
            path:
                typeof page.path === "string"
                    ? `/${page.path}`
                    : page.path.map((p) => `/${p}`),
            exact: page.exact,
        });
    });
}
