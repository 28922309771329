import React from "react";
import ReactDOM from "react-dom";
import { StyleDeclarationValue, css } from "aphrodite";

const modalRoot = document.getElementById("modal-root") as HTMLElement;

class Portal extends React.Component<{styles?: StyleDeclarationValue | undefined}> {
    el: HTMLDivElement | false = document.createElement("div");
    
    componentDidMount() {
        if (modalRoot && this.el) {
            modalRoot.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (modalRoot && this.el) {
            modalRoot.removeChild(this.el);
        }
    }

    render() {
        if (this.el) {
            this.el.className = css(this.props.styles);
        }
        return this.el ? ReactDOM.createPortal(this.props.children, this.el) : null;
    }
}

export default Portal;