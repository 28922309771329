import { Tournament } from "packages/tournaments/model";
import { ComponentType } from "react";
import { matchPath } from "react-router-dom";
import Landing from "./pages/Landing.react";
import Player from "./pages/Player.react";
import Players from "./pages/Players.react";
import PlayOffs from "./pages/PlayOffs.react";
import Rules from "./pages/Rules.react";
import TimeAttack from "./pages/TimeAttack.react";
import Track from "./pages/Track.react";
import Tracks from "./pages/Tracks.react";
import { Theme } from "../../../packages/themes/theme";

export type PageMarkers =
    | "landing"
    | "player"
    | "players"
    | "playoffs"
    | "time-attack"
    | "track"
    | "tracks"
    | "rules";

export type Page = {
    title: string;
    url: string;
    marker: PageMarkers;
    is_menu?: boolean;
};

export const Pages: Array<Page> = [
    {
        title: "Home",
        url: "",
        marker: "landing",
        is_menu: true,
    },
    {
        title: "Time Attack",
        url: "time-attack",
        marker: "time-attack",
        is_menu: true,
    },
    {
        title: "Playoffs",
        url: "playoffs",
        marker: "playoffs",
        is_menu: true,
    },
    {
        title: "Tracks",
        url: "tracks",
        marker: "tracks",
        is_menu: true,
    },
    {
        title: "Players",
        url: "players",
        marker: "players",
        is_menu: true,
    },
    {
        title: "Rules",
        url: "rules",
        marker: "rules",
        is_menu: true,
    },
    {
        title: "Player",
        url: "players/:username",
        marker: "player",
    },
    {
        title: "Track",
        url: "tracks/:uid",
        marker: "track",
    },
];

export type PageProps = {
    tournament: Tournament;
    theme: Theme;
    prefix?: string;
};

export const PageComponents: Record<PageMarkers, ComponentType<PageProps>> = {
    landing: Landing,
    players: Players,
    player: Player,
    playoffs: PlayOffs,
    "time-attack": TimeAttack,
    tracks: Tracks,
    track: Track,
    rules: Rules,
};

export function GetPathPage(path: string, prefix?: string) {
    return Pages.find((page) => {
        return matchPath(path, {
            path: `/${prefix ? prefix : "cups/:uri"}${
                page.url ? `/${page.url}` : ""
            }`,
            exact: true,
        });
    })?.marker;
}

export function GetPath(
    uri: string,
    part?: string,
    part_id?: string,
    prefix?: string
) {
    return `${prefix ? prefix : `cups/${uri}`}${
        part ? `/${part}${part_id ? `/${part_id}` : ""}` : ""
    }`;
}

export const PageMarkersList: Array<PageMarkers> = Object.keys(
    Pages
) as Array<PageMarkers>;

export function FilterPages(tournament: Tournament | null) {
    return Pages.filter((page) => {
        if (!tournament) {
            return page.marker === "landing";
        } else {
            if (page.marker === "rules" && !Boolean(tournament.rules)) {
                return false;
            } else if (
                page.marker === "time-attack" &&
                !Boolean(tournament.has_time_attack)
            ) {
                return false;
            } else if (
                page.marker === "playoffs" &&
                !Boolean(tournament.has_playoffs)
            ) {
                return false;
            } else if (
                page.marker === "players" &&
                !Boolean(tournament.has_registration) &&
                !Boolean(tournament.players_description)
            ) {
                return false;
            } else if (
                page.marker === "tracks" &&
                !Boolean(tournament.campaign) &&
                !Boolean(tournament.tracks_description)
            ) {
                return false;
            }
        }

        return true;
    });
}
