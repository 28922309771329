import { CreateScheet } from "packages/aphrodite/aphrodite";

export const Colors = {
    Primary: "#320968",
    Dark: "#221037",
    Secondary: "#af86ff",
    LightGray: "#b8b8b8",
};

const Styles = CreateScheet({
    card: {
        background: "#0e0e0e",
        color: Colors.LightGray,
        borderTop: `1px solid ${Colors.LightGray}`,
        position: "relative",
    },
});

export default Styles;
