import { SpinnerTheme } from "packages/spinners/spinner.theme";
import { Theme } from "./theme";

export function GetTableTheme(theme: Theme) {
    return {
        trowBackground: "transparent",
        trowEvenBackground: theme.get_color("primary", { opacity: 10 }),
        trowHoverBackground: theme.get_color("primary", { opacity: 20 }),
        theadBackground: "transparent",
        theadColor: theme.get_color("primary"),
        tbodyColor: "inherit",
        sortIndicatorColor: theme.get_color("primary"),
    };
}

export function GetSpinnerTheme(theme: Theme): SpinnerTheme {
    return {
        color: theme.get_color("primary"),
        pathColor: theme.get_color("primary", { darker: 70 }),
    };
}
