import { MakeAnimation } from "packages/aphrodite/aphrodite";

const wobbleVertical = MakeAnimation({
    '0%': {
        transform: 'translate(0, 0)',
        opacity:    0,
        transformOrigin: '50% 100%',
    },
    '5%': {
        transform: 'translate(0, 0)',
        opacity:   1,
    },
    '10%': {
        transform: 'translate(0, 0)',
        opacity:   1,
    },
    '14%': {
        transform: 'translate(0, 0) rotate(-24deg)'
    },
    '16%': {
        transform: 'translate(0, 0) rotate(24deg)'
    },
    '18%': {
        transform: 'translate(0, 0) rotate(-15deg)'
    },
    '22%': {
        transform: 'translate(0, 0) rotate(10deg)'
    },
    '26%': {
        transform: 'translate(0, 0) rotate(-5deg)'
    },
    '30%': {
        transform: 'translate(0, 0)',
        opacity:   1,
    },
    '95%': {
        transform: 'translate(0, 0)',
        opacity:   1,
    },
    '100%': {
        transform: 'translate(30px, 0)',
        opacity:   0,
    },
});

export default wobbleVertical;