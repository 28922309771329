import Button, { ButtonTheme, ButtonLayout } from "packages/buttons/button";
import LoadButtonReact from "packages/buttons/LoadButton.react";
import { Danger, Primary } from "./colors";

export const DefaultLayout: ButtonLayout = {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    borderRadius: 6,
    padding: "9px 21px",
    cursor: "pointer",
    textAlign: "center",
    transition: "all .15s ease-in-out",
    fontWeight: "bolder",
};

export const PrimaryFilled: ButtonTheme = {
    plain: {
        background: Primary.default,
        color: "white",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 25px -8px ${Primary.hover}`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const BIPCFilled: ButtonTheme = {
    plain: {
        background: "#2e0052",
        color: "white",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 25px -8px #2e0052`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const BIPCTransparent: ButtonTheme = {
    plain: {
        background: "transparent",
        color: "#2e0052",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const PrimaryTransparent: ButtonTheme = {
    plain: {
        background: "transparent",
        color: Primary.default,
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const DiscordFilled: ButtonTheme = {
    plain: {
        background: "#5865f2",
        color: "white",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 10px -8px #5865f2`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const UbiFilled: ButtonTheme = {
    plain: {
        background: "#006ef5",
        color: "white",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 10px -8px #006ef5`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const DangerFilled: ButtonTheme = {
    plain: {
        background: Danger.default,
        color: "white",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 10px -8px ${Danger.hover}`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const WHCFilled: ButtonTheme = {
    plain: {
        background: "rgb(120 31 198)",
        color: "#f7f3ff",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: `0 8px 25px -8px rgb(120 31 198)`,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export const WHCTransparent: ButtonTheme = {
    plain: {
        background: "transparent",
        color: "rgb(120 31 198)",
        borderColor: undefined,
        boxShadow: undefined,
    },
    hover: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: undefined,
    },
    active: {
        background: undefined,
        color: undefined,
        borderColor: undefined,
        boxShadow: "none",
    },
};

export function IsStyleTheme(pet: any | ButtonTheme): pet is ButtonTheme {
    return (pet as ButtonTheme).plain !== undefined;
}

export function MakeButton(theme: ButtonTheme, layout?: ButtonLayout) {
    return Button({ ...DefaultLayout, ...layout }, theme);
}

type props = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    loading: boolean;
    theme: ButtonTheme;
    layout?: ButtonLayout;
};

export function LoadButton(props: props) {
    return (
        <LoadButtonReact
            {...{
                ...props,
                layout: { ...DefaultLayout, ...props.layout },
            }}
        />
    );
}
