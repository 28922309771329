import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithLocalBG } from "packages/aphrodite/WithBG";
import { Track } from "packages/competitions/model";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import PrintNumber from "packages/utils/PrintNumber";
import PrintTime from "packages/utils/PrintTime";
import { Fragment } from "react";
import { BigNoodle } from "src/jsx/fonts";
import { CampaignTable } from "src/themes/tables";
import { BaseStyles } from "./BaseStyles";

export default function SingleTrack({
    tracks,
    id,
}: {
    tracks: Track[];
    id: string;
}) {
    const track = tracks.find((t) => t.uid === id);

    function OpenTrack() {
        if (track && track.tmx_url.length > 0) {
            window.open(track.tmx_url);
        }
    }

    return (
        <Fragment>
            <div className={css(Styles.track)}>
                {track ? (
                    <Fragment>
                        <div
                            onClick={OpenTrack}
                            className={css(
                                Styles.meta,
                                WithLocalBG(
                                    "white",
                                    `linear-gradient(to right, rgba(255,255,255, 0.9) 30%, rgba(255,255,255, 0.6)), url(${track?.image})`
                                )
                            )}
                        >
                            <strong>
                                <a
                                    {...{
                                        href: track.tmx_url,
                                        target: "_blank",
                                        className: css(
                                            BaseStyles.meta_link,
                                            Styles.track_name
                                        ),
                                    }}
                                >
                                    {track.clear_name}
                                </a>
                            </strong>
                            {" by "}
                            {track.creator.name}
                        </div>
                        <PrettyTable
                            {...{
                                title: "Leaderboard",
                                thead: [
                                    "position",
                                    "player",
                                    "score (pos/time)",
                                    "time",
                                ],
                                tbody: track.records.map((record, i) => {
                                    return [
                                        i + 1,
                                        () => (
                                            <strong>
                                                <a
                                                    {...{
                                                        href: `/campaign-vol-1/users/${record.account.name}`,
                                                        onClick: OpenPage(
                                                            `campaign-vol-1/users/${record.account.name}`
                                                        ),
                                                        className: css(
                                                            BaseStyles.link
                                                        ),
                                                    }}
                                                >
                                                    {record.account.name}
                                                </a>
                                            </strong>
                                        ),
                                        <span>
                                            {PrintNumber(record.score)} (
                                            {PrintNumber(record.pos_score)}/
                                            {PrintNumber(record.time_score)})
                                        </span>,
                                        PrintTime(record.time),
                                    ];
                                }),
                                columnsSizes: [100, "auto", "auto", "auto"],
                                theme: CampaignTable,
                                layout: {
                                    theadColumnFontSize: 15,
                                    tbodyColumnFontSize: 13,
                                    tbodyPadding: "0 10px",
                                    titlePadding: "3px 10px",
                                },
                            }}
                        />
                        {track.records.length === 0 && (
                            <h3 className={css(BaseStyles.no_data)}>
                                No leaderboard yet
                            </h3>
                        )}
                    </Fragment>
                ) : (
                    <div>no such track</div>
                )}
            </div>
        </Fragment>
    );
}

const Styles = CreateScheet({
    track: {
        padding: "0 0 10px 0",
        zIndex: 1,
        "& h2": {
            margin: "15px 0 5px 0",
            padding: "0 20px",
            color: "#525252",
            fontSize: 16,
        },
    },
    meta: {
        padding: "30px 20px",
        fontSize: 20,
        color: "#6f5636",
        textShadow: "1px 1px rgb(0 0 0 / 10%)",
        cursor: "pointer",
    },
    track_name: {
        fontFamily: [BigNoodle, "Roboto"],
        fontSize: 30,
    },
});
