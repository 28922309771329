import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { Cup, CupRuntype } from "./../model";

export default async function LoadNextWHC(): Promise<ResultWithError<Cup>> {
    const res = await get<Cup>({ url: `cups/whc/next` });
    return CheckResultRuntime(CupRuntype, res);
}

export const useLoadNextWHC = makeAPIHook(LoadNextWHC);
