import { Fragment, useEffect, useState } from 'react';
import SmoothHeight, { props } from "./SmoothHeight.react";

export default function SmoothAppear(props: Omit<props, "status"> & { ignore?: boolean }) {
    const [ status, setStatus ] = useState(false);
    useEffect(() => {
        setStatus(true);
    }, []);

    return (
        <Fragment>
            { props.ignore ? (
                <div className={ props.className }>{props.children}</div>
            ) : (
                <SmoothHeight {...props} {...{
                    height: status ? typeof props.height !== "undefined" ? props.height : "auto" : 0,
                }} />
            )}
        </Fragment>
    )
}