import { LightboxTheme } from 'packages/lightbox/lightbox';
import { White, Dark, Secondary, Gray } from './colors';

export const LightTheme:LightboxTheme = {
    overlay: {
        backgroundColor:    "rgba(0, 0, 0, 0.5)",
        closeIconColor:     White.default,
        hoverIconColor:     "rgba(196, 196, 196, 0.2)"
    },
    lightbox: {
        backgroundColor:    White.default,
        color:              Dark.default,
        boxShadow:          "0 5px 20px 0 rgba(34,41,47,.1)",
        closeIconColor:     Secondary.default,
        hoverIconColor:     "rgba(196, 196, 196, 0.2)"
    },
    header: {
        backgroundColor:    Gray.default,
        color:              undefined,
    },
    body: {
        backgroundColor:    undefined,
        color:              undefined,
    },
    footer: {
        backgroundColor:    undefined,
        color:              undefined,
        botderTop:          "1px solid rgba(34,41,47,.05)",
    }
}

export const DarkTheme:LightboxTheme = {
    overlay: {
        backgroundColor:    "rgba(0, 0, 0, 0.5)",
        closeIconColor:     White.default,
        hoverIconColor:     "rgba(196, 196, 196, 0.2)"
    },
    lightbox: {
        backgroundColor:    "#283046",
        color:              "#d0d2d6",
        boxShadow:          "0 5px 20px 0 rgba(34,41,47,.1)",
        closeIconColor:     White.default,
        hoverIconColor:     "rgba(196, 196, 196, 0.2)"
    },
    header: {
        backgroundColor:    "#161d31",
        color:              undefined,
    },
    body: {
        backgroundColor:    undefined,
        color:              White.default,
    },
    footer: {
        backgroundColor:    undefined,
        color:              undefined,
        botderTop:          "1px solid #3b4253",
    }
}

export const WarningLight:LightboxTheme = {
    ...LightTheme,
    header: {
        ...LightTheme.header,
        color: "#ea5455",
    },
}