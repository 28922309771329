import { CreateScheet, css } from "aphrodite";
import moment from "moment";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import {
    GetFormat,
    TimeAttackFormats,
    TournamentPlayer,
    TournamentPlayers,
} from "packages/tournaments/model";
import { PrimaryReadOnluDraft } from "src/themes/draftjs";
import { GetPath, PageProps } from "../model";
import GeneralStyles from "../modules/GeneralStyles";
import RegisterBlock from "../modules/RegistrationBlock.react";
import { GetSpinnerTheme, GetTableTheme } from "packages/themes/components";
import { Theme } from "packages/themes/theme";
import { Fragment, useEffect, useState } from "react";
import useInterval from "packages/hooks/useInterval";
import pluralize from "pluralize";
import { LoadButton } from "src/themes/button";
import { Tracks } from "packages/trackmania/model";
import LoadAllPlayers, {
    useLoadAllPlayers,
} from "packages/tournaments/API/LoadAllPlayers";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import useOnce from "packages/hooks/useOnce";

export default function TimeAttack(props: PageProps) {
    const [, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDetailed, setShowDetailed] = useState(false);
    const [data, , setPayload] = useLoadAllPlayers(false, props.tournament.uri);

    function setBodyStyle(detailed: boolean) {
        const el = document.getElementById("tournament-body");
        if (el) {
            el.setAttribute(
                "style",
                `max-width: ${detailed ? "95vw" : "700px"} !important`
            );
        }
    }

    function toggleDetailed() {
        setBodyStyle(!showDetailed);
        setShowDetailed((s) => !s);
    }

    useInterval(() => {
        setReload((reload) => !reload);
    }, 60 * 1000);

    useEffect(() => {
        async function load() {
            setIsLoading(true);
            const res = await LoadAllPlayers(props.tournament.uri);
            if (res[0]) {
                setPayload(res[0]);
            }
            setIsLoading(false);
        }

        if (showDetailed) {
            load();
        }
    }, [
        showDetailed,
        props.tournament.uri,
        setPayload,
        props.tournament.snapshots_created_at,
    ]);

    useOnce(() => {
        return () => {
            setBodyStyle(false);
        };
    });

    return (
        <div className={css(GeneralStyles.content)}>
            <DraftEditorReact
                {...{
                    theme: PrimaryReadOnluDraft,
                    defaultValue:
                        props.tournament.time_attack_description || "",
                    readOnly: true,
                }}
            />
            {props.tournament.has_registration && <RegisterBlock {...props} />}
            {props.tournament.is_player && <br />}
            {props.tournament.time_attack_start_at &&
            moment().unix() < props.tournament.time_attack_start_at ? (
                <>
                    <div className={css(Styles.start_info)}>
                        Time attack starts{" "}
                        {moment
                            .unix(props.tournament.time_attack_start_at)
                            .fromNow()}
                    </div>
                </>
            ) : (
                <>
                    {props.tournament.time_attack_end_at && (
                        <div className={css(Styles.start_info)}>
                            Time attack{" "}
                            {props.tournament.time_attack_end_at <
                            moment().unix() ? (
                                <strong>ended</strong>
                            ) : (
                                "ends"
                            )}{" "}
                            at{" "}
                            <strong>
                                {moment
                                    .unix(props.tournament.time_attack_end_at)
                                    .utc()
                                    .format("MMMM Do YYYY, H:mm")}
                            </strong>{" "}
                            UTC
                        </div>
                    )}
                    {props.tournament.tracks.length > 0 && (
                        <Fragment>
                            <div className={css(Styles.updated_at)}>
                                {props.tournament
                                    .is_upading_snapshots_stopped &&
                                    props.tournament.snapshots_created_at && (
                                        <>
                                            last updated{" "}
                                            {moment
                                                .unix(
                                                    props.tournament
                                                        .snapshots_created_at
                                                )
                                                .fromNow()}
                                            {" | "}
                                        </>
                                    )}
                                <span
                                    className={css(Styles.toggle_show)}
                                    onClick={toggleDetailed}
                                >
                                    show {showDetailed ? "compact" : "detailed"}
                                </span>
                            </div>
                            <WithSpinner
                                suspense={isLoading}
                                theme={GetSpinnerTheme(props.theme)}
                            >
                                <div
                                    className={css(
                                        Styles.ta_table,
                                        showDetailed && Styles.ta_table_detailed
                                    )}
                                >
                                    <TimeAttackLeaderboard
                                        {...{
                                            total_tracks:
                                                props.tournament.tracks.length,
                                            uri: props.tournament.uri,
                                            prefix: props.prefix,
                                            theme: props.theme,
                                            tracks: props.tournament.tracks,
                                            players:
                                                showDetailed && data.payload
                                                    ? data.payload
                                                    : props.tournament.players,
                                            show_full: showDetailed,
                                            format_id:
                                                props.tournament
                                                    .time_attack_format_id,
                                        }}
                                    />
                                </div>
                            </WithSpinner>
                        </Fragment>
                    )}
                </>
            )}
        </div>
    );
}

type PlayerWithTracksFlag = TournamentPlayer & { has_all_tracks?: boolean };

export function TimeAttackLeaderboard({
    theme,
    uri,
    players,
    tracks,
    format_id,
    total_tracks,
    limit,
    prefix,
    show_full,
}: {
    uri: string;
    players: TournamentPlayers;
    tracks: Tracks;
    format_id: number;
    theme: Theme;
    total_tracks: number;
    limit?: number;
    prefix?: string;
    show_full?: boolean;
}) {
    const [showAll, setShowAll] = useState(false);

    const format = GetFormat(format_id);
    if (!format) {
        return null;
    }

    let playersList: PlayerWithTracksFlag[] = players;
    if (format.id === TimeAttackFormats.pb_sum.id) {
        playersList = [];

        const not_all_tracks: PlayerWithTracksFlag[] = [];

        players.forEach((player) => {
            if (player.total_records === total_tracks) {
                playersList.push({ ...player, has_all_tracks: true });
            } else {
                not_all_tracks.push({ ...player, has_all_tracks: false });
            }
        });

        playersList = [...playersList, ...not_all_tracks];
    }

    playersList = playersList.filter((p, i) =>
        limit ? i < limit : !showAll ? i < 100 : true
    );

    return (
        <Fragment>
            <PrettyTable
                {...{
                    thead: [
                        "position",
                        "player",
                        ...(show_full ? ["zone"] : []),
                        ...(show_full
                            ? tracks.map((track) => (
                                  <span className={css(Styles.track_name)}>
                                      {track.clear_name}
                                  </span>
                              ))
                            : []),
                        format.score_title,
                        format.id !== TimeAttackFormats.ats_only.id
                            ? "records"
                            : undefined,
                        format.id !== TimeAttackFormats.ats_only.id
                            ? "WRs"
                            : undefined,
                    ].filter(Boolean),
                    tbody: playersList.map((player, i) => {
                        return [
                            i + 1,
                            () => (
                                <strong>
                                    {show_full ? (
                                        player.user.username
                                    ) : (
                                        <a
                                            {...{
                                                href: `/${GetPath(
                                                    uri,
                                                    "players",
                                                    player.user.username,
                                                    prefix
                                                )}`,
                                                onClick: OpenPage(
                                                    GetPath(
                                                        uri,
                                                        "players",
                                                        player.user.username,
                                                        prefix
                                                    )
                                                ),
                                                className: css(
                                                    theme.color("secondary")
                                                ),
                                            }}
                                        >
                                            {player.user.username}
                                        </a>
                                    )}
                                </strong>
                            ),
                            ...(show_full
                                ? [player.user.zone_name || "unkown"]
                                : []),
                            ...(show_full
                                ? tracks.map((track) =>
                                      getTrackScore(
                                          format_id,
                                          player,
                                          track.uid
                                      )
                                  )
                                : []),
                            getScore(
                                format_id,
                                player,
                                total_tracks,
                                show_full
                            ),
                            format.id !== TimeAttackFormats.ats_only.id
                                ? player.total_records
                                : undefined,
                            format.id !== TimeAttackFormats.ats_only.id
                                ? player.total_wrs
                                : undefined,
                        ].filter(Boolean);
                    }),
                    columnsSizes: show_full
                        ? [10, 100, 50]
                        : [100, "auto", "auto", "auto", "auto"],
                    theme: GetTableTheme(theme),
                    layout: {
                        theadColumnFontSize: 15,
                        tbodyColumnFontSize: 13,
                        titlePadding: "3px 10px 13px 10px",
                    },
                }}
            />
            {!limit && !showAll && players.length > 100 && (
                <Fragment>
                    <br />
                    <LoadButton
                        {...{
                            theme: {
                                plain: {
                                    background: theme.get_color("primary"),
                                    color: "white",
                                },
                                hover: {
                                    boxShadow: `0 8px 25px -8px ${theme.get_color(
                                        "primary"
                                    )}`,
                                },
                                active: {
                                    boxShadow: "none",
                                },
                            },
                            loading: false,
                            layout: {
                                width: "100%",
                                fontSize: 16,
                                fontWeight: 500,
                                fontFamily: "Montserrat",
                            },
                            onClick: () => {
                                setShowAll(true);
                            },
                        }}
                    >
                        show all players
                    </LoadButton>
                </Fragment>
            )}
        </Fragment>
    );
}

function getScore(
    format_id: number,
    player: PlayerWithTracksFlag,
    total_tracks: number,
    show_full?: boolean
) {
    const format = GetFormat(format_id);
    if (!format) {
        return "";
    }

    return format.id === TimeAttackFormats.ats_only.id ? (
        `${format.score_func(player.score)} / ${total_tracks}`
    ) : typeof player.has_all_tracks !== "undefined" &&
      !player.has_all_tracks &&
      !show_full ? (
        <span>
            don't have PB on {total_tracks - player.total_records}{" "}
            {pluralize("track", total_tracks - player.total_records)} yet
        </span>
    ) : format.has_double_score ? (
        <span>
            <strong>{format.score_func(player.score)}</strong> (
            {format.score_func(player.position_score)} + {player.time_score})
        </span>
    ) : (
        format.score_func(player.score)
    );
}

function getTrackScore(
    format_id: number,
    player: PlayerWithTracksFlag,
    track_uid: string
) {
    const format = GetFormat(format_id);
    if (!format) {
        return "";
    }

    const track_data =
        player.tracks && player.tracks[track_uid]
            ? player.tracks[track_uid]
            : null;
    if (!track_data) {
        return "-";
    }

    return format.id === TimeAttackFormats.ats_only.id ? (
        `+`
    ) : format.has_double_score ? (
        <span>
            <strong>{format.score_func(track_data.score.score)}</strong> (
            {format.score_func(track_data.score.position_score)} +{" "}
            {track_data.score.time_score})
        </span>
    ) : (
        format.score_func(track_data.score.score)
    );
}

const Styles = CreateScheet({
    start_info: {
        padding: "16px 0",
        fontSize: 14,
        fontWeight: 600,
        textAlign: "center",
        fontFamily: "Montserrat",
        "& strong": {
            fontWeight: 700,
        },
    },
    updated_at: {
        fontFamily: "Montserrat",
        fontSize: 12,
        textAlign: "right",
        marginTop: 10,
    },
    track_name: {
        display: "block",
        whiteSpace: "nowrap",
        maxWidth: 100,
        wordBreak: "keep-all",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    toggle_show: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    ta_table: {
        overflow: "auto",
    },
    ta_table_detailed: {
        maxHeight: 400,
    },
});
