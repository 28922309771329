import * as r from 'runtypes';

export const PlayerRuntype = r.Record({
    username: r.String,
})
export type Player = r.Static<typeof PlayerRuntype>

export const TrackRuntype = r.Record({
    name:       r.String,
    clear_name: r.String,
    uid:        r.String,
    file_url:   r.String,
    tmx_url:    r.String,
    image_url:  r.String,
    author:     PlayerRuntype,
})
export type Track = r.Static<typeof TrackRuntype>

export const TracksRuntype = r.Array(TrackRuntype)
export type Tracks = r.Static<typeof TracksRuntype>