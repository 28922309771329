import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithBG } from "packages/aphrodite/WithBG";
import Header from "../modules/Header.react";


export default function Triangles() {
    return (
        <article className={ css(Styles.page, WithBG("#191716", "linear-gradient(rgba(31, 36, 37, 0.8), rgba(31, 36, 37, 0.9)), url(/images/bg.jpg)"))}>
            <Header />

         <div>
         <h1 className={ css(Styles.head)}>3D-Triangles</h1>
         <h2 className={ css(Styles.subhead)}>Import .svg files (Scalable Vector Graphics) for making 3D-text appear in game</h2>
     </div>
     <div className={ css(Styles.linkcontainer)}>
         <h3 className={ css(Styles.subhead2)}>Jump to:</h3>
         <ol >
             <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link1">Create an .svg  file</a></li>
             <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link2">Place Custom Camera for<br></br>3D-Triangle location</a></li>
             <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link3">Add 3D-triangle block + control the depth</a></li>
             <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link4">Import the .svg and edit color+position</a></li>
             <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link5">Finished 3D-Triangle</a></li>
             <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link6">Video Tutorial</a></li>
         </ol>
     </div>
     <section className={ css(Styles.body)}>  
         <div  className={ css(Styles.content)}>
             <ol>
                 <h3 id="link1" className={ css(Styles.spananchor)}> &nbsp; </h3>
                 <li className={ css(Styles.list)}>
                    <p>Open your graphics software of choice (I use the open source software Inkscape for my 3D-triangles), create a text or another object you want in your map.</p>
                    <p>To make your object scalable, there should be an option to convert it to a path, the image below shows where to find this in Inkscape.</p>
                    <img alt="preview" src="https://i.imgur.com/ASRmHDI.png" className={ css(Styles.image)} />
                 </li>
                 <h3 id="link2" className={ css(Styles.spananchor)}> &nbsp;</h3>
                 <li className={ css(Styles.list)}>
                    <p>Add a Custom Camera block to your timeline and choose a location for your 3D-Text. If you need an exact alignment use the Rotation controls.</p>
                    <p>The size of your 3D-Text will be conrolled by the savailable space on screen. 
                     So for a smaller Text zoom in with your scrollwheel, for a bigger one zoom out.<br></br>This can be a bit of trial and error, but sadly there is no easier way to control the size.</p>
                    <img alt="preview" src="https://i.imgur.com/sp0bIij.jpg" className={ css(Styles.image)} />
                 </li>
                 <h3 id="link3" className={ css(Styles.spananchor)}> &nbsp;</h3>
                 <li className={ css(Styles.list)}>
                    <p>Add a 3D-Triagle block to your timeline where you put the custom cam.</p>
                    <p>Click on the first keyframe of the new block. Then press ALT+LeftClick to control the depth of your placement. A green cross will appear for indication.</p>
                    <img alt="preview" src="https://i.imgur.com/5PykAbs.jpg" className={ css(Styles.image)} />
                 </li>
                 <h3 id="link4" className={ css(Styles.spananchor)}> &nbsp; </h3>
                 <li className={ css(Styles.list)}>
                    <p>We're now set to import the .svg, so click the + next to "Import SVG" and choose your file. If your not happy with the size go back to step 2. </p>
                    <p>Now we can edit the color and position. Make sure you are on the keyframe, change the Edit mode to "edit vertex".<br></br>
                        LeftClick+drag the area with the vertices (the "dots") you want to edit. You can change the color with the color picker and control the position with the arrows.</p>
                    <p>For animating you can edit the position in another Keyframe (sadly you can't create color fades) If you dont want your text to move, simply copy and paste (ctrl+c & ctrl+v) the 1st keyframe to the 2nd one.</p>   
                     <img alt="preview" src="https://i.imgur.com/dGYANdL.jpg" className={ css(Styles.image)} />
                 </li>
                 <h3 id="link5" className={ css(Styles.spananchor)}> &nbsp; </h3>
                 <li className={ css(Styles.list)}>
                     <p>The finished product will look something like this. You can use it in Intro shots, for shoutouts or a title card with the mapname or maybe indicating a GPS in a bit fancier way than just using normal text.</p>
                     <p>There are a lot of other creative possibilities to get fancy with this. Create animated rain/snow, put a plane in the sky, make fish move through water. Be creative and try stuff out!</p>  
                     <img alt="preview" src="https://i.imgur.com/XlJ7zm3.jpg" className={ css(Styles.image)} />
                 </li>
                 <h3 id="link6" className={ css(Styles.spananchor)}> &nbsp; </h3>                               
                 <li className={ css(Styles.list)}>
                     Short Video Tutorial
                 </li>
                 <video className={ css(Styles.image)} src="https://cdn.discordapp.com/attachments/838393219330080798/863511481218891796/3d.mp4" controls> </video>
             </ol>
         </div>
     </section>
     </article>
   )    
}
const Styles = CreateScheet({
    page: {
        minGeight    : "100vh",
        color        : "white",
        display      : "flex",
        flexDirection: "column",
    },
    body: {
        display      : "flex",
        flexGrow     : 1,
        flexDirection: "column",
        alignItems   : "center",
        paddingLeft   : "15%",
        '@media (max-width: 1000px)': {
            paddingLeft: "0",
        }
    },      
    linkcontainer: {                        
        margin        : "20px",
        display       : "flex",
        position      : "fixed",
        top           : "418px",
        width         : "15%",
        flexDirection : "column",
        padding       : "20px",
        justifyContent: "center",
        '@media (max-width: 1000px)': {
            display: "none",
        }
    },
    content: {
        margin        : "auto",
        display       : "flex",
        flexGrow      : 1,
        flexDirection : "row",
        justifyContent: "space-around",
        alignItems    : "flex-start",
        paddingBottom : "100px",
        fontSize      : 28
    },
    head: {
        fontSize : 80,
        textAlign: "center",
        color    : "#ADD8E6",
        '@media (max-width: 1000px)': {
            fontSize: 40,
        }
    },
    subhead: {
        fontSize : 40,
        textAlign: "center",
        '@media (max-width: 1000px)': {
            fontSize: 20,
        }    

    },
    subhead2: {
        fontSize: 28,
        color   : "#ADD8E6",

    },
    list: {
        padding : "10px",
        fontSize: 20
    },
    spananchor:{
        marginTop    : "-50px",
        paddingBottom: "50px",
        display      : "block",
    },

    links: {
        textDecoration: "none",
        color         : "#E0FFFF",

    },
    image: {
        maxWidth: "60%"
    }
}) 