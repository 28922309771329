import { CreateScheet, css } from "aphrodite";
import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import { Tournament } from "packages/tournaments/model";
import GenericStyles from "src/jsx/forms/GenericStyles";
import useSafeState from "packages/hooks/useSafeState";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { PrimaryDraft } from "src/themes/draftjs";
import { PrimaryFilled, LoadButton, DangerFilled } from "src/themes/button";
import { useState } from "react";
import UpdateTournament from "packages/tournaments/API/UpdateTournament";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme } from "src/themes/lightbox";
import moment from "moment";
import { MakeInput, PrimaryInput } from "src/themes/input";
import { useLoadBannedPlayers } from "packages/tournaments/API/LoadBannedPlayers";
import PrettyTable from "packages/tables/PrettyTable.react";
import { session } from "packages/session/session";
import UnbanPlayer from "packages/tournaments/API/UnbanPlayer";
import { DarkTable } from "src/themes/tables";
import GeneralStyles from "../modules/GeneralStyles";
import RegisterPlayer from "packages/tournaments/API/RegisterPlayer";
import Switch from "packages/forms/Switch.react";
import SmoothLine from "packages/motion/SmoothLine.react";

export default function TournamentPlayersEditor({ content, close }:ComponentProps<{
    tournament: Tournament,
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header>
                Manage players
            </Header>
            <Body>
                <TournamentPlayersEditorForm {...content} {...{
                    onClose: close,
                    onSave:  close,
                }} />
            </Body>
        </Lightbox>
    )
}

function TournamentPlayersEditorForm({
    tournament,
    onSave,
    onClose,
}:{
    tournament: Tournament,
    onSave:     () => void,
    onClose:    () => void,
}) {
    const [ isUnbanning, setIsUnbanning ] = useState(false);
    const [ bannedPlayers, reloadBannedPlayers ] = useLoadBannedPlayers(true, tournament.uri);
    const [ accountID, setAccountID ] = useState("");

    const [ state, setState ] = useSafeState<{
        players_description:   string | null
        registration_start_at: number | null
        registration_end_at:   number | null
        has_registration:      boolean
    }>({
        players_description:   tournament.players_description,
        registration_start_at: tournament.registration_start_at,
        registration_end_at:   tournament.registration_end_at,
        has_registration:      tournament.has_registration,
    });
    const [ isAdding, setIsAdding ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    async function OnClickSave() {
        setIsSaving(true);

        const err = await UpdateTournament(tournament.uri, state)

        setIsSaving(false);
        if (err !== null) {
            showWarning(err.text, DarkTheme);
        } else {
            onSave();
        }
    }

    return (
        <section>
            <h2 className={css(GenericStyles.section_title)}>General</h2>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>Description</label>
                <DraftEditorReact {...{
                    layout:          {editor_min_height:  100},
                    theme:           PrimaryDraft,
                    defaultValue:    state.players_description || "",
                    onContentChange: draft => {
                        setState({players_description: {$set: draft }})
                    },
                }}/>
            </section>
            <hr className={css(GenericStyles.divider)} />

            <h2 className={css(GenericStyles.section_title)}>Registration</h2>
            <section className={css(GenericStyles.section)}>
                <div className={css(GenericStyles.section_title_description)}>
                    If not required script will be pulling top 300 players per track automatically 
                </div>
                <div className={css(GenericStyles.switch)} onClick={() => {
                    setState({
                        has_registration: {$set: !state.has_registration}
                    })
                }}>
                    <Switch checked={state.has_registration} />
                    <div className={css(GenericStyles.switch_label)}>Registration required</div>
                </div>
            </section>
            
            <SmoothLine>
                { state.has_registration ? (
                    <div>
                        <div className={css(GenericStyles.section_title_description)}>
                            Always allowed if dates not provided
                        </div>
                        <section className={css(GenericStyles.section)}>
                            <label className={css(GenericStyles.section_label)}>Start at (UTC)</label>
                            <input {...{
                                type:      "datetime-local",
                                min:       moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
                                className: css(MakeInput(PrimaryInput, { width: "100%" })),
                                value:     state.registration_start_at ? moment.unix(state.registration_start_at).utc().format(moment.HTML5_FMT.DATETIME_LOCAL): "",
                                onChange:  (e) => {
                                    setState({registration_start_at: {$set: moment.utc(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL).unix() }}) 
                                }
                            }}/>
                        </section>
                        <section className={css(GenericStyles.section)}>
                            <label className={css(GenericStyles.section_label)}>End at (UTC)</label>
                            <input {...{
                                type:      "datetime-local",
                                min:       moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
                                className: css(MakeInput(PrimaryInput, { width: "100%" })),
                                value:     state.registration_end_at ? moment.unix(state.registration_end_at).utc().format(moment.HTML5_FMT.DATETIME_LOCAL): "",
                                onChange:  (e) => {
                                    setState({registration_end_at: {$set: moment.utc(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL).unix()}}) 
                                }
                            }}/>
                        </section>
                    </div>
                ) : undefined }
            </SmoothLine>
            <hr className={css(GenericStyles.divider)} />

            <h2 className={css(GenericStyles.section_title)}>Add player</h2>
            <div className={css(GenericStyles.section_title_description)}>
                you can add players by their AccountID (you can find it on tm.io)
            </div>
            <section className={css(GenericStyles.section, GeneralStyles.add_player)}>
                <input {...{
                    className: css(MakeInput(PrimaryInput, { width: "100%" })),
                    value:     accountID,
                    onChange:  (e) => {
                        setAccountID(e.target.value); 
                    }
                }}/>
                <LoadButton {...{
                    theme:     PrimaryFilled,
                    loading:   isAdding,
                    className: css(Styles.button),
                    disabled:  accountID.length === 0,
                    onClick:   async () => {
                        setIsAdding(true);
                        const err = await RegisterPlayer(tournament.uri, accountID);
                        if (err !== null) {
                            alert(err.text)
                        } else {
                            setAccountID("");
                            alert("player added")
                        }
                        setIsAdding(false);
                    },
                }}>
                    Add
                </LoadButton>
            </section>
            <hr className={css(GenericStyles.divider)} />

            <h2 className={css(GenericStyles.section_title)}>Banned players</h2>
            <section className={css(GenericStyles.section)}>
                <PrettyTable {...{
                    thead: [
                        "username",
                        "actions"
                    ].filter(a => Boolean(a)),
                    tbody: bannedPlayers.isLoading ? [["loading", ""]] : bannedPlayers.payload ? bannedPlayers.payload.map((player, i) => {
                        return [
                            player.username,
                            () => {
                                if (player.guid === session.user?.guid) {
                                    return <span>you</span>
                                }
                                return (
                                    <LoadButton {...{
                                        theme:   PrimaryFilled,
                                        loading: isUnbanning,
                                        layout: {
                                            padding: "3px 10px",
                                        },
                                        onClick: async () => {
                                            setIsUnbanning(true);
                                            const err = await UnbanPlayer(tournament.uri, player.guid);
                                            if (err != null) {
                                                alert(err.text);
                                            } else {
                                                reloadBannedPlayers(tournament.uri)
                                            }
                                            setIsUnbanning(false);
                                        },
                                    }}>
                                        Unban
                                    </LoadButton>
                                );
                            }
                        ]
                    }) : [],
                    theme:        DarkTable,
                    columnsSizes: ["auto", 140],
                    layout:       {
                        theadColumnFontSize: 13,
                        tbodyColumnFontSize: 12,
                        titlePadding:        "3px 10px 13px 10px",
                    },
                }} />
            </section>
            <hr className={css(GenericStyles.divider)} />

            <footer className={css(Styles.footer)}>
                <LoadButton {...{
                    theme:   DangerFilled,
                    loading: isSaving,
                    onClick: onClose,
                }}>
                    Close w/o saving
                </LoadButton>
                <LoadButton {...{
                    theme:     PrimaryFilled,
                    loading:   isSaving,
                    className: css(Styles.button),
                    onClick:   OnClickSave,
                }}>
                    Save
                </LoadButton>
            </footer>
        </section>
    )
}

const Styles = CreateScheet({
    footer: {
        display:        "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: 15,
    }
});