import { TableLayout, TableTheme } from "packages/tables/table.theme";
import { Dark, Primary, White } from "./colors";

export const DefaultLayout: TableLayout = {};

export const LightTable: TableTheme = {
    trowBackground: White.default,
    trowEvenBackground: `${Primary.default}08`,
    trowHoverBackground: `${Primary.hover}20`,
    theadBackground: White.default,
    theadColor: Primary.default,
    tbodyColor: "black",
    sortIndicatorColor: Primary.default,
};

export const SOSTable: TableTheme = {
    trowBackground: White.default,
    trowEvenBackground: `#ff8e4418`,
    trowHoverBackground: `#ff8e4440`,
    theadBackground: White.default,
    theadColor: "#ff8e44",
    tbodyColor: "black",
    sortIndicatorColor: "#ff8e44",
};

export const CampaignTable: TableTheme = {
    trowBackground: White.default,
    trowEvenBackground: `#ff8e4418`,
    trowHoverBackground: `#ff8e4440`,
    theadBackground: White.default,
    theadColor: "#ff8e44",
    tbodyColor: "black",
    sortIndicatorColor: "#ff8e44",
};

export const BIPCTable: TableTheme = {
    trowBackground: White.default,
    trowEvenBackground: `#421b7f18`,
    trowHoverBackground: `#421b7f40`,
    theadBackground: "transparent",
    theadColor: "#421b7f",
    tbodyColor: "black",
    sortIndicatorColor: "#421b7f",
};

export const WHCTable: TableTheme = {
    trowBackground: "#0e0e0e",
    trowEvenBackground: `#421b7f18`,
    trowHoverBackground: `#421b7f40`,
    theadBackground: "transparent",
    theadColor: "#8e54ff",
    tbodyColor: "rgb(184, 184, 184)",
    sortIndicatorColor: "#421b7f",
};

export const FWTable: TableTheme = {
    trowBackground: White.default + "18",
    trowEvenBackground: `#0184f918`,
    trowHoverBackground: `#0184f940`,
    theadBackground: White.default + 18,
    theadColor: "#547ca0",
    tbodyColor: "black",
    sortIndicatorColor: "#547ca0",
};

export const BlackAndWhite: TableTheme = {
    trowBackground: White.default,
    trowEvenBackground: White.default,
    trowHoverBackground: White.default,
    theadBackground: White.default,
    theadColor: "black",
    tbodyColor: "black",
    sortIndicatorColor: "transparent",
};

export const DarkTable: TableTheme = {
    trowBackground: `#404040`,
    trowEvenBackground: Dark.default,
    trowHoverBackground: `rgba(0, 0, 0, 0.6)`,
    theadBackground: Dark.default,
    theadColor: White.default,
    tbodyColor: White.default,
    sortIndicatorColor: White.default,
};
