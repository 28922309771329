import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { TournamentPlayersRuntype, TournamentPlayers } from "../model";

export default async function LoadAllPlayers(
    uri: string
): Promise<ResultWithError<TournamentPlayers>> {
    const res = await get<TournamentPlayers>({
        url: `tournaments/${uri}/players`,
    });
    return CheckResultRuntime(TournamentPlayersRuntype, res);
}

export const useLoadAllPlayers = makeAPIHook(LoadAllPlayers);
