import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import { OpenPage } from "packages/history/history";
import { GetPath, Page, PageMarkers } from "../model";
import { Theme } from "../../../../packages/themes/theme";

export default function Menu({
    uri,
    pages,
    current_page,
    theme,
    prefix,
}: {
    uri: string;
    current_page: PageMarkers;
    pages: Array<Page>;
    theme: Theme;
    prefix?: string;
}) {
    const pageList = pages.filter((page) => page.is_menu);

    return (
        <nav
            className={css(
                Styles.menu,
                theme.background("primary", { opacity: 10 })
            )}
        >
            <ul className={css(Styles.list, theme.border("primary"))}>
                {pageList.map((page) => (
                    <li
                        {...{
                            key: page.marker,
                            className: css(
                                Styles.item,
                                page.marker === current_page && Styles.active,
                                page.marker === current_page &&
                                    theme.border("primary")
                            ),
                            onClick: OpenPage(
                                GetPath(uri, page.url, undefined, prefix)
                            ),
                        }}
                    >
                        {page.title}
                    </li>
                ))}
            </ul>
        </nav>
    );
}

const Styles = CreateScheet({
    menu: {
        width: "100%",
        marginBottom: 20,
        paddingTop: 10,
        borderRadius: "10px 10px 0 0",
        fontFamily: "Montserrat",
        "@media (max-width: 700px)": {
            borderRadius: 0,
        },
    },
    list: {
        display: "flex",
        margin: 0,
        padding: 0,
        listStyle: "none",
        width: "100%",
        borderBottom: "1px solid transparent",
    },
    item: {
        padding: "10px 0px",
        cursor: "pointer",
        flexShrink: 0,
        userSelect: "none",
        marginBottom: 0,
        transition: "border-color .2s",
        borderBottom: "2px solid transparent",
        textAlign: "center",
        fontWeight: 500,
        flexGrow: 1,
    },
    active: {
        fontWeight: 600,
    },
});
