import { CreateScheet, css } from "aphrodite";
import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import { Tournament } from "packages/tournaments/model";
import GenericStyles from "src/jsx/forms/GenericStyles";
import useSafeState from "packages/hooks/useSafeState";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { PrimaryDraft } from "src/themes/draftjs";
import { PrimaryFilled, LoadButton, DangerFilled } from "src/themes/button";
import { useState } from "react";
import UpdateTournament from "packages/tournaments/API/UpdateTournament";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme } from "src/themes/lightbox";
import Switch from "packages/forms/Switch.react";
import SmoothLine from "packages/motion/SmoothLine.react";

export default function PlayOffsEditor({ content, close }:ComponentProps<{
    tournament: Tournament,
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header>
                Edit Playoffs settings
            </Header>
            <Body>
                <PlayOffsEditorForm {...content} {...{ onClose: close, onSave: close }} />
            </Body>
        </Lightbox>
    )
}

function PlayOffsEditorForm({
    tournament,
    onSave,
    onClose,
}:{
    tournament: Tournament,
    onSave:     () => void,
    onClose:    () => void,
}) {
    const [ state, setState ] = useSafeState<{
        has_playoffs:         boolean
        playoffs_description: string | null
    }>({
        has_playoffs:         tournament.has_playoffs,
        playoffs_description: tournament.playoffs_description,
    });
    const [ isSaving, setIsSaving ] = useState(false);

    async function OnClickSave() {
        setIsSaving(true);

        const err = await UpdateTournament(tournament.uri, state)

        setIsSaving(false);
        if (err !== null) {
            showWarning(err.text, DarkTheme);
        } else {
            onSave();
        }
    }

    return (
        <section>
            <h2 className={css(GenericStyles.section_title)}>Playoffs</h2>
            <section className={css(GenericStyles.section)}>
                <div className={css(GenericStyles.section_title_description)}>
                    Public tournaments visible to everyone, private only for managers
                </div>
                <div className={css(GenericStyles.switch)} onClick={() => {
                    setState({
                        has_playoffs: {$set: !state.has_playoffs}
                    })
                }}>
                    <Switch checked={state.has_playoffs} />
                    <div className={css(GenericStyles.switch_label)}>Has Playoffs</div>
                </div>
            </section>
            <SmoothLine>
                { state.has_playoffs ? (
                    <div>
                        <section className={css(GenericStyles.section)}>
                            <label className={css(GenericStyles.section_label)}>Playoffs content</label>
                            <DraftEditorReact {...{
                                layout:          {editor_min_height:  100},
                                theme:           PrimaryDraft,
                                defaultValue:    state.playoffs_description || "",
                                onContentChange: draft => {
                                    setState({playoffs_description: {$set: draft }})
                                },
                            }}/>
                        </section>
                    </div>
                ) : undefined }
            </SmoothLine>
            <hr className={css(GenericStyles.divider)} />
            <footer className={css(Styles.footer)}>
                <LoadButton {...{
                    theme:   DangerFilled,
                    loading: isSaving,
                    onClick: onClose,
                }}>
                    Close w/o saving
                </LoadButton>
                <LoadButton {...{
                    theme:     PrimaryFilled,
                    loading:   isSaving,
                    className: css(Styles.button),
                    onClick:   OnClickSave,
                }}>
                    Save
                </LoadButton>
            </footer>
        </section>
    )
}

const Styles = CreateScheet({
    footer: {
        display:        "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: 15,
    }
});