import { createBrowserHistory, Location } from "history";
export const history = createBrowserHistory();

history.listen((location) => {
    OnHistoryChange(location);
});

export function OnHistoryChange(location: Location, init?: boolean) {}

export const OpenPage = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`/${path}`);
    window.scrollTo(0, 0);
};
