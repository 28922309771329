import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import PageHeader from "src/jsx/modules/Header.react";
import { WithBG } from "packages/aphrodite/WithBG";
import { BigNoodle } from "src/jsx/fonts"
import { Fragment, useState } from "react";
import { MakeButton, PrimaryFilled } from "src/themes/button";
import SmoothLine from "packages/motion/SmoothLine.react";

export default function Awards() {
    const [ showAll, setShowAll ] = useState(false);

    return (
        <article className={ css(Styles.page, WithBG("#171623", "linear-gradient(rgba(31, 36, 37, 0.6), rgba(31, 36, 37, 0.7)), url(/images/awards-bg.jpg)")) }>
            <PageHeader />
            <header className={css(Styles.header)}>
                <h1 className={css(Styles.title)}>Trackmania Mapping Awards 2021</h1>
            </header>
            <div className={css(Styles.body)}>
                <div className={css(Styles.body_meta)}>
                <p className={css(Styles.meta_p)}>
                    <strong>Trackmania Mappers Assembly (TMA)</strong> presents the inaugural 2021 edition of its most prestigious and very serious annual<i>*</i> <strong>Trackmania Mapping Awards (TMA)!</strong> Here we want to highlight and celebrate some of the best creators and creations of the last year.
                </p>
                <p className={css(Styles.meta_p)}>
                    <strong>TMA 2021 hosted by TMA</strong> starts with a nominations phase, where you can nominate your favourites to each and every category. Then, a jury picked by the TMA admins will choose a number of nominees to form a shortlist for each category. This selection process is confirmed to be most objective and none of this is at all rigged<i>**</i>. When the shortlist is selected, we proceed to community voting phase, where you can pick your favourite! After the voting phase, the winners are selected<i>***</i>.
                </p>
                <p className={css(Styles.meta_p, Styles.meta_sub)}>
                    *Not confirmed to be hosted annually.<br/>
                    **Really isn't! Stop laughing!<br/>
                    ***Mostly based on the votes cast. For most categories. Or some of them, anyway.<br/>
                </p>
                </div>
                <div className={ css(Styles.body_payload) }>
                    <h2 className={css(Styles.title_2)}>Results</h2>
                    <div>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Competitive map of the year",
                        }}>
                            <Fragment>
                                <h4 className={css(Styles.cat_winner_title)}>Actually a tie!</h4>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/26604/viridescent" rel="noreferrer" target="_blank"><strong>Viridescent</strong> by <strong>ski freak</strong></a><br />
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>Epic fullspeed grass track, featuring some of the tightest lines of any TOTD from 2021. With one of the most difficult ATs of any TOTD, you have to push every line to the absolute limit while maintaining pixel perfect grass SDs. Viridescent's difficult lines go from beginning to end, with a risky finish that ruined many a player's COTD runs. Viridinski is Schi Freak Grass at its best</p>
                                <br/>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/36270/opuntia-ft-slys" rel="noreferrer" target="_blank"><strong>Opuntia</strong> by <strong>htimh & Sláys</strong></a><br />
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>An awesome collab between HtimH and Slays and has not only a very beautiful desert atmosphere but also a clean route with many different turns which are so satisfying to get right, but its also driveable by all players without a problem. These things make for a tight race against others or the clock with a technical dirt track.</p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "LOL map of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/39445/ice-ice-bobby" rel="noreferrer" target="_blank">
                                            <strong>ICE, ICE BOBBY</strong> by <strong>deadfish</strong>
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>Big Bobsleigh, Big Quack, what more do you want from the most tightly hunted LOL map of the year?</p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      `"Other" map of the year`,
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/40477/minigolf" rel="noreferrer" target="_blank">
                                            <strong>Minigolf</strong> by <strong>Wallaby</strong>
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>You want to experience what TrackMania can offer on exotic map ideas and design? Then check out this awesome made map! You will play minigolf but with your car. And every hole is possible with your first try. Can you make it?</p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      `Best map you played once and noped out of`,
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/44031/the-great-yonder-ft-ski-freak" rel="noreferrer" target="_blank">
                                            <strong>The Great Yonder</strong> by <strong>entrylag & ski freak</strong>
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>Imagine having a beautiful fullspeed map with speedchecks, long jumps and 2 minutes of driving. Sound fun? I think so as well, but it's also a track that you say "nope not gonna finish it" or "gonna watch others to finish this one". So it's very deserving of this award and, mind you, got it with a yuuuuuuge margin of votes.</p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Mapper of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/user/profile/38291" rel="noreferrer" target="_blank">
                                            <strong>deadfish (not rigged at all)</strong>
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>Many a talented mapper really stepped up their game in 2021. There were many people pushing boundaries in route-building, in scenery, in mediatracker. But deadfish was among the more prolifc ones, building in many different styles and creating routes that were easy to learn, but very nicely calculated for faster pace, making them a joy to hunt. Many of them ended up as tracks of the day (and not all of them even got cut).</p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Item maker of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://item.exchange/user/profile/20713" rel="noreferrer" target="_blank">
                                            <strong>stupskiesel</strong>
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    Here are some of the sets that stupskiesel released in 2021: SmoothBankedCurfs, PlatformSlope Curves, Platform Loop Slopes, Loop Triangles, and Kiesel_Rocks. These sets, along with their other sets and one-off items for community members, are used in many of our favorite tracks of this year, making it easy to see why stupskiesel is our Item Maker of the Year.
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Item set of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://item.exchange/set/view/10894" rel="noreferrer" target="_blank">
                                            <strong>RoadTechToRoadDirtAdditions by slowpiou</strong>
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    Opening up new and smooth lines, this set has become all but a must-have for anyone building dirt. Also make sure to check out the other sets by slowpiou, such as the extensions to this dirt set, as well as the sausage and bobsleigh sets!
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Best finish",
                        }}>
                            <Fragment>
                                <h4 className={css(Styles.cat_winner_title)}>Another tie!</h4>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/42539/pandora-ft-osoguineapig" rel="noreferrer" target="_blank">
                                            Pandora by osoguineapig & Newtron
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/39073/conflagrate" rel="noreferrer" target="_blank">
                                            Conflagrate by entrylag
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    In their tie for the victory, Conflagrate and Pandora showcase the extremes of finishes in Trackmania. After a series of high speed SDs, Conflagrate features one of the most precise finishes as centering your car onto a tiny pole YEETS your car flying upwards into the finish. On the other hand, Pandora caps off a visually stunning track with a magnificent final reactor jump to the finish, a wonderful vista that let's you take in the grandeur as you miss a PB by 0.001 
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Best mediatracker",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/user/profile/26956" rel="noreferrer" target="_blank">
                                            Nacho
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    The media tracker is a hidden gem of the editor. There is so much stuff you can use it for and really change the experience for the better (or worse....). So most keep it simple, but there is someone who really went all-in to push the limits of the media tracker. Nacho made maps in which we could see the results of that. Having a cool rpg with a story, unique ideas and a BOSS FIGHT is awesome. They are very deserving of this award for works like isometric2.
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Cut of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://www.youtube.com/watch?v=IAR5YqYt1ss" rel="noreferrer" target="_blank">
                                        RECKLESS by RogueDawn
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                Who doesnt love a good cut? How about a cut that takes care of about half the map and lets you yeet yourself right into the finish? Reckless deservedly takes home the Cut of the Year award with not just one, but TWO massive cuts!
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Scenery of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/44120/mith-odasyne" rel="noreferrer" target="_blank">
                                            MITH ODASYNE by htimh & enysado
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    Looking at the most beautiful maps of 2021, one can't help but notice that there was certain someone who always went above and beyond of what you thought was possible - taking you to different time, dimension, or planet, and doing it with such style and attention to detail that it's almost unbeliavable. It is of course none other than Htimh we are talking about - and Tim takes this category convincingly with Mith Odasyne, a beautifully detailed map that takes you to another planet, one with huge mushrooms, space exploration tech, and even nice MT and custom music to really drive the theme home. A TM experience like no other.
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Quack of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/39445/ice-ice-bobby" rel="noreferrer" target="_blank">
                                            ICE, ICE BOBBY by deadfish
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    Quack. Quack quack, quack quack Quack quack. Quack quack!
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Rock of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        <a href="https://trackmania.exchange/maps/42575/parthenon" rel="noreferrer" target="_blank">
                                            Parthenon by Ealipse
                                        </a>
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    Nice rocks in this one. Very nice indeed. Mhm.
                                </p>
                            </Fragment>
                        </CategoryWinner>
                        <CategoryWinner {...{
                            force_show: showAll,
                            title:      "Troll of the year",
                        }}>
                            <Fragment>
                                <p className={css(Styles.winner_p)}>
                                    <h3 className={css(Styles.cat_winner_title)}>
                                        Tobilix and the hidden finish (could be a band name)
                                    </h3>
                                </p>
                                <p className={css(Styles.winner_p)}>
                                    Sometimes, something or someone so impactful and memetic emerges, and then the name will live on for what seems like forever. And this is what happened in the 'hidden finish incident' - an additional finish line, hidden from plain sight, saving multiple seconds. And with this, Tobilix's name lives on, synonymous with 'troll' for some - definitely deserving this award.
                                </p>
                            </Fragment>
                        </CategoryWinner>
                    </div>
                    <SmoothLine>
                        { !showAll ? (
                            <button className={css(MakeButton(PrimaryFilled, {width: "100%"}))} onClick={() => { setShowAll(true); window.scrollTo(0, 0);; }} >
                                show all
                            </button>
                        ) : undefined }
                    </SmoothLine>
                </div>
            </div>
        </article>
    )
}

function CategoryWinner({force_show, children, title}:React.PropsWithChildren<{force_show:boolean, title: string}>) {
    const [ show, setShow ] = useState(false);

    return (
        <div className={css(Styles.cat_item)}>
            <div className={css(Styles.cat_winner, Styles.cat_trans, show || force_show ? Styles.hide_title : Styles.show)} onClick={() => { setShow(true) }}>
                <h1 className={css(Styles.cat_title)}>{title}</h1>
            </div>
            <div className={css(Styles.cat_info, Styles.cat_trans, show || force_show ? Styles.show : Styles.hide)}>
                { children }
            </div>
        </div>
    )
}

const Styles = CreateScheet({
    page: {
        minHeight:          "100vh",
        color:              "white",
        display:            "flex",
        flexDirection:      "column",
    },
    header: {
        padding:        "70px 0",
        display:        "flex",
        alignItems:     "center",
        justifyContent: 'center',
    },
    title: {
        fontFamily: [BigNoodle],
        fontSize:   82,
        margin:     0,
        padding:    0,
        textShadow: "3px 3px 2px rgba(0,0,0,0.26)",
        fontWeight: 400,
        textAlign:  "center",
    },
    body: {
        margin:       "0 auto",
        maxWidth:     860,
        fontSize:     14,
        boxShadow:    "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: 10,
        marginBottom: 30,
    },
    body_meta: {
        width:           "100%",
        backgroundColor: "#f2f9ff",
        borderRadius:    "10px 10px 0 0",
        color:           "#171623",
        padding:         "6px 0",
        fontFamily:      "monospace",
    },
    meta_p: {
        paddingLeft:  20,
        paddingRight: 20,
    },
    meta_sub: {
        fontSize: 12,
    },
    body_payload: {
        width:           "100%",
        backgroundColor: "#171623",
        padding:         "6px 20px 20px 20px",
        borderRadius:    "0 0 10px 10px",
        fontSize:        13,
    },
    title_2: {
        paddingBottom: 6,
        textAlign:     "center",
        marginBottom:  30,
        marginTop:     30,
    },
    cat_item: {
        marginBottom:                50,
        position:                    "relative",
        width:                       "100%",
        height:                      300,
        overflow:                    "hidden",
        '@media (max-width: 600px)': {
            height: 400,
        }
    },
    cat_trans: {
        cursor:         "pointer",
        position:       "absolute",
        top:            0,
        left:           0,
        right:          0,
        bottom:         0,
        display:        "flex",
        justifyContent: 'center',
        flexDirection:  "column",
    },
    cat_winner: {
        padding:    50,
        textAlign:  "center",
        background: "#6965a2",
        zIndex:     1,
        transition: "all 0.5s",
    },
    cat_title: {
        fontFamily: "Montserrat",
        fontSize:   26,
    },
    cat_info: {
        padding:         20,
        color:           "#e9e7ff",
        backgroundColor: "#283932",
        zIndex:          5,
        transition:      "transform 0.5s",
        "& p a":         {
            color: "white",
        }
    },
    hide: {
        transform:  "rotateX(90deg)",
        opacity:    0,
    },
    hide_title: {
        transform:  "scaleY(30)",
        opacity:    0,
    },
    show: {
        transform:  "rotateX(0)",
        opacity:    1,
    },
    cat_winner_title: {
        padding: 0,
        margin:  0,
    },
    winner_p: {
        margin: "5px 0",
    }
});