import { css, CreateScheet } from "packages/aphrodite/aphrodite";
import PageHeader from "src/jsx/modules/Header.react";
import { WithBG } from "packages/aphrodite/WithBG";
import { OpenPage } from "packages/history/history";


const Styles = CreateScheet({
    page: {
        minHeight:       "100vh",
        color:           "white",
        display:         "flex",
        flexDirection:   "column",
    },
    header: {
        padding:        "70px 20px",
        display:        "flex",
        flexDirection:  "column",
        alignItems:     "center",
        justifyContent: 'center',
    },
    logo: {
        display:      "block",
        width:        "100%",
        maxWidth:     650,
        marginBottom: 20,
        marginLeft:   25,
        cursor:       "pointer",
    },
    title: {
        fontFamily: "Montserrat",
        fontSize:   32,
        margin:     0,
        padding:    0,
        fontWeight: 700,
        textAlign:  "center",
        fontStyle:  "italic",
        cursor:     "pointer",
    },
    body: {
        margin:       "0 auto",
        maxWidth:     1000,
        fontSize:     14,
        boxShadow:    "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: 10,
        marginBottom: 30,
    },
    body_meta: {
        width:           "100%",
        backgroundColor: "#f2f9ff",
        color:           "#171623",
        padding:         "6px 0",
        fontFamily:      "arial",
    },
    meta_p: {
        paddingLeft:  20,
        paddingRight: 20,
        fontWeight:   500,
        "& a": {
            color: "#421b7f",
        }
    },
});

export default function Awards() {
    return (
        <article className={ css(Styles.page, WithBG("#421b7f", "linear-gradient(rgba(31, 36, 37, 0.6), rgba(31, 36, 37, 0.7)), url(/images/bipc-bg.jpg)")) }>
            <PageHeader simple={true} />
            <header className={css(Styles.header)}>
                <img src="/images/bipc-logo.png" alt="bipc" className={css(Styles.logo)} onClick={OpenPage("bipc")} />
                <h2 className={css(Styles.title)} onClick={OpenPage("bipc")}>BRITISH & IRISH PROFESSIONAL CUP</h2>
            </header>
            <div className={css(Styles.body)}>
                <div className={css(Styles.body_meta)}>
                    <p className={css(Styles.meta_p)}>
                        British & Irish Pro Cup is finished, results can be found the <a rel="noreferrer" target="_blank" href="https://discord.gg/9UkCDxQy7r">British & Irish TrackMania discord</a>
                    </p>
                </div>
            </div>
        </article>
    )
}