import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { FormatRuntype } from "../model";
import * as r from 'runtypes';

const FormatsRuntype = r.Dictionary(FormatRuntype, r.Number);
type Formats = r.Static<typeof FormatsRuntype>


export default async function LoadFormats():Promise<ResultWithError<Formats>> {
    const res = await get<Formats>({ url: `tournaments/formats` });
    return CheckResultRuntime(FormatsRuntype, res);
}

export const useLoadFormats = makeAPIHook(LoadFormats);