import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { history } from "packages/history/history";
import {
    SmoothSteper,
    SmoothSteperItem,
} from "packages/motion/SmoothStepper.react";
import { WHCResponse } from "packages/whc/model";
import React from "react";
import CupsLog from "./CupsLog";
import RecordsLog from "./RecordsLog";
import TimeSheet from "./TimeSheet";
import Top10 from "./Top10";

type pages = "wrs" | "sheet" | "log" | "none";

function getCurrentPage(): pages {
    const parts = window.location.pathname.split("/");
    return (parts.length >= 3 ? parts[2] : "none") as pages;
}

export default function CupInfos({ payload }: { payload: WHCResponse }) {
    const currentPage = getCurrentPage();

    const onClickPage = (page: pages) => (e: React.MouseEvent) => {
        e.preventDefault();
        if (page === "none") {
            history.push(`/hot-pursuit`);
        } else {
            history.push(`/hot-pursuit/${page}`);
        }
    };

    return (
        <>
            <nav className={css(Styles.root)}>
                <div
                    className={css(
                        Styles.menuItem,
                        currentPage === "none" && Styles.active
                    )}
                    onClick={onClickPage("none")}
                >
                    Top 10
                </div>
                <div
                    className={css(
                        Styles.menuItem,
                        currentPage === "wrs" && Styles.active
                    )}
                    onClick={onClickPage("wrs")}
                >
                    Pursuit log
                </div>
                <div
                    className={css(
                        Styles.menuItem,
                        Styles.center,
                        currentPage === "sheet" && Styles.active
                    )}
                    onClick={onClickPage("sheet")}
                >
                    Phases
                </div>
                <div
                    className={css(
                        Styles.menuItem,
                        Styles.right,
                        currentPage === "log" && Styles.active
                    )}
                    onClick={onClickPage("log")}
                >
                    Previous weeks
                </div>
            </nav>
            <SmoothSteper
                duration={300}
                motion={["swipe-in-up", "fade"]}
                active={
                    currentPage === "none"
                        ? 0
                        : currentPage === "wrs"
                        ? 1
                        : currentPage === "sheet"
                        ? 2
                        : 3
                }
            >
                <SmoothSteperItem>
                    <Top10 payload={payload} />
                </SmoothSteperItem>
                <SmoothSteperItem>
                    <RecordsLog payload={payload} />
                </SmoothSteperItem>
                <SmoothSteperItem>
                    <TimeSheet payload={payload} />
                </SmoothSteperItem>
                <SmoothSteperItem>
                    <CupsLog payload={payload} />
                </SmoothSteperItem>
            </SmoothSteper>
        </>
    );
}

const Styles = CreateScheet({
    root: {
        padding: "0 30px",
        marginTop: 70,
        display: "flex",
    },
    menuItem: {
        color: "white",
        fontFamily: "'IBM Plex Mono', monospace",
        fontSize: 14,
        fontWeight: 500,
        flexGrow: 1,
        opacity: 0.5,
        transition: "opacity 0.2s",
        textDecoration: "underline",
        cursor: "pointer",
        ":hover": {
            opacity: 1,
        },
    },
    center: {
        textAlign: "center",
    },
    right: {
        textAlign: "right",
    },
    active: {
        opacity: 1,
    },
});
