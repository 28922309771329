import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { MiniCampaigns, MiniCampaignsRuntype } from "../model";

export default async function SearchCampaign(search_key: string):Promise<ResultWithError<MiniCampaigns>> {
    const res = await get<MiniCampaigns>({ url: `nadeo/campaigns`, query: { q: search_key } });
    return CheckResultRuntime(MiniCampaignsRuntype, res);
}

export const useSearchCampaign = makeAPIHook(SearchCampaign);