import { CreateScheet, css } from "aphrodite";
import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import { Tournament } from "packages/tournaments/model";
import GenericStyles from "src/jsx/forms/GenericStyles";
import useSafeState from "packages/hooks/useSafeState";
import DraftEditorReact from "packages/draftjs/DraftEditor.react";
import { PrimaryDraft } from "src/themes/draftjs";
import { PrimaryFilled, LoadButton, DangerFilled } from "src/themes/button";
import { useState } from "react";
import UpdateTournament from "packages/tournaments/API/UpdateTournament";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme } from "src/themes/lightbox";
import CampaignPicker from "packages/nadeo/modules/CampaignPicker.react";
import { MiniCampaign } from "packages/nadeo/model";

export default function TournamentTracksEditor({ content, close }:ComponentProps<{
    tournament: Tournament,
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header>
                Manage tracks
            </Header>
            <Body>
                <TournamentTracksEditorForm {...content} {...{ onClose: close, onSave: close }} />
            </Body>
        </Lightbox>
    )
}

function TournamentTracksEditorForm({
    tournament,
    onSave,
    onClose,
}:{
    tournament: Tournament,
    onSave:     () => void,
    onClose:    () => void,
}) {
    const [ state, setState ] = useSafeState<{
        campaign:           MiniCampaign | null
        tracks_description: string | null
        club_id:            number | null
        campaign_id:        number | null
    }>({
        campaign:           tournament.campaign,
        tracks_description: tournament.tracks_description,
        club_id:            tournament.club_id,
        campaign_id:        tournament.campaign_id,
    });
    const [ isSaving, setIsSaving ] = useState(false);

    async function OnClickSave() {
        setIsSaving(true);

        const {campaign, ...toSend} = state;
        const err = await UpdateTournament(tournament.uri, toSend)

        setIsSaving(false);
        if (err !== null) {
            showWarning(err.text, DarkTheme);
        } else {
            onSave();
        }
    }

    return (
        <section>
            <h2 className={css(GenericStyles.section_title)}>General</h2>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>Description</label>
                <DraftEditorReact {...{
                    layout:          {editor_min_height:  100},
                    theme:           PrimaryDraft,
                    defaultValue:    state.tracks_description || "",
                    onContentChange: draft => {
                        setState({tracks_description: {$set: draft }})
                    },
                }}/>
            </section>
            <hr className={css(GenericStyles.divider)} />

            <h2 className={css(GenericStyles.section_title)}>Tracks</h2>
            <div className={css(GenericStyles.section_title_description)}>
                To show the list of tracks select public campaign, tracks will be pulled automatically
            </div>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>Campaign</label>
                <CampaignPicker {...{
                    active:    state.campaign || undefined,
                    setActive: campaign => {
                        setState({
                            campaign:    {$set: campaign || null },
                            club_id:     {$set: campaign?.club_id || null},
                            campaign_id: {$set: campaign?.campaign_id || null},
                        })
                    }
                }} />
            </section>
            <hr className={css(GenericStyles.divider)} />
            <footer className={css(Styles.footer)}>
                <LoadButton {...{
                    theme:   DangerFilled,
                    loading: isSaving,
                    onClick: onClose,
                }}>
                    Close w/o saving
                </LoadButton>
                <LoadButton {...{
                    theme:     PrimaryFilled,
                    loading:   isSaving,
                    className: css(Styles.button),
                    onClick:   OnClickSave,
                }}>
                    Save
                </LoadButton>
            </footer>
        </section>
    )
}

const Styles = CreateScheet({
    footer: {
        display:        "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: 15,
    }
});