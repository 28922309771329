import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithBG } from "packages/aphrodite/WithBG";
import { MakeButton, PrimaryFilled } from "src/themes/button";
import Header from "../modules/Header.react";

export default function Branding() {
    return (
        <article
            className={css(
                Styles.page,
                WithBG(
                    "#191716",
                    "linear-gradient(rgba(31, 36, 37, 0.9), rgba(31, 36, 37, 0.9)), url(/images/bg-skins.jpg)"
                )
            )}
        >
            <Header />
            <section className={css(Styles.body)}>
                <h1 className={css(Styles.title)}>TMA graphics</h1>
                <div>
                    <p>Extract to Documents\Trackmania</p>
                    <a
                        className={css(MakeButton(PrimaryFilled))}
                        href="https://cdn.discordapp.com/attachments/1004639134490574878/1083846098659057685/TMA-signs-pack-v2.zip"
                    >
                        Download sign pack
                    </a>
                </div>
            </section>
        </article>
    );
}

const Styles = CreateScheet({
    page: {
        minGeight: "100vh",
        color: "white",
        display: "flex",
        flexDirection: "column",
    },
    body: {
        width: 900,
        margin: "0 auto",
        padding: "0 10px",
    },
    title: {
        fontSize: 30,
    },
    block: {
        display: "flex",
        marginBottom: 10,
    },
});
