import { put } from "packages/rest/api";
import { Colors } from "packages/themes/theme";

type UpdateRequest = {
    title?:                     string
    start_at?:                  number | null
    end_at?:                    number | null
    club_id?:                   number | null
    campaign_id?:               number | null
    short_description?:         string | null
    description?:               string | null
    rules?:                     string | null
    time_attack_description?:   string | null
    tracks_description?:        string | null
    players_description?:       string | null
    playoffs_description?:      string | null
    is_public?:                 boolean
    has_registration?:          boolean
    has_time_attack?:           boolean
    has_playoffs?:              boolean
    registration_start_at?:     number | null
    registration_end_at?:       number | null
    time_attack_start_at?:      number | null
    time_attack_end_at?:        number | null
    time_attack_time_coef?:     number | null
    time_attack_position_coef?: number | null
    time_attack_format_id?:     number | null
    images?:                    {
        logo:       string,
        background: string,
    } | null,
    theme?: Colors | null
}

export default async function UpdateTournament(uri: string, data: UpdateRequest) {
    const res = await put({ url: `tournaments/${uri}`, body: data});
    return res[1];
}