import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { OpenPage } from "packages/history/history";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { useLoadShowcaseTracks } from "packages/tmx/LoadShowcase";
import { Orange } from "src/themes/spinner";

export default function ShowcaseList({ limit }:{ limit?: number }) {
    const [ tracks ] = useLoadShowcaseTracks(true);

    return (
        <WithSpinner theme={ Orange } suspense={ tracks.isLoading }>
            <ul className={ css(Styles.list) }>
                { tracks.payload && tracks.payload.slice(0, limit).map(track => (
                    <li {...{
                        key:       track.id,
                        className: css(Styles.track),
                        style: {
                            backgroundImage: `url(${track.image}), url(${track.screenshot})`,
                        }
                    }}>
                        <a {...{
                            href:      track.link,
                            target:    "_blank",
                            className: css(Styles.track_item),
                        }}>
                            <h3 className={ css(Styles.track_title) }>{ track.name }</h3>
                            <span>
                                <span {...{
                                    className: css(Styles.track_author),
                                    onClick:   (e) => { e.stopPropagation(); window.open(track.author_link) }
                                }}>
                                    by { track.author }
                                </span>
                            </span>
                        </a>
                    </li>
                )) }
            </ul>
            { tracks.payload && limit && tracks.payload.length > limit && (
                <a href="/showcase" onClick={ OpenPage("showcase") } className={ css(Styles.show_more) }>
                    SHOW MORE
                </a>
            ) }
        </WithSpinner>
    )
}

const Styles = CreateScheet({
    list: {
        listStyle: "none",
        padding:   0,
        margin:    0,
    },
    track: {
        width:              "100%",
        height:             100,
        borderRadius:       10,
        backgroundPosition: "50% 50%",
        backgroundSize:     "cover",
        marginBottom:       20,
        transition:         "all 0.3s",
        ":hover":           {
            backgroundPosition: "52% 52%",
        },
    },
    track_item: {
        textDecoration:  "none",
        color:           "white",
        display:         "flex",
        flexDirection:   "column",
        height:          "100%",
        backgroundColor: "rgba(31, 36, 37, 0.9)",
        transition:      "all 0.3s",
        borderRadius:    10,
        padding:         10,
        textAlign:       "right",
        ":hover":        {
            backgroundColor: "rgba(31, 36, 37, 0.6)",
            boxShadow:       "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            "& h3": {
                opacity:  1,
            }
        }
    },
    track_title: {
        margin:     0,
        flexGrow:   1,
        fontSize:   24,
        opacity:    0.8,
        transition: "all 0.3s",
        textAlign:  "left",
    },
    track_author: {
        opacity:        0.8,
        fontFamily:     "Ubuntu, Arial, sans-serif",
        color:          "white",
        textDecoration: "none",
        ":hover":       {
            textDecoration: "underline",
        }
    },
    show_more: {
        fontSize:       20,
        display:        "block",
        textAlign:      "center",
        width:          "100%",
        color:          "white",
        transition:     "all 0.2s",
        textDecoration: "none",
        fontFamily:     "Ubuntu, Arial, sans-serif",
        fontWeight:     500,
        ":hover":       {
            color: "rgba(255,255,255,0.6)",
        }
    }
});