import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { Track } from "packages/competitions/model";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import PrintTime from "packages/utils/PrintTime";
import { Fragment } from "react";
import { CampaignTable } from "src/themes/tables";
import { BaseStyles } from "./BaseStyles";

export default function Tracks({ tracks }: { tracks: Track[] }) {
    return (
        <Fragment>
            <div className={css(Styles.tracks)}>
                <p className={css(Styles.description)}>
                    The TMA campaign was built in 2021 over a time span of 2
                    months. It features maps in many different styles, in
                    varying lengths and difficulties. The aim was to create a
                    diverse campaign that is fun to discover and to hunt.
                    <br />
                    <br />
                    The team included both experienced and well-known mappers as
                    well as some up-and-coming names. The team worked together
                    to test and improve each others' maps, as well as building
                    some maps together as collaborative effort - culminating in
                    the 25th map that was built in stages, with almost everyone
                    in the team participating.
                    <br />
                    <br />
                    The campaign is available in TMA club "TM Mappers Assembly"
                    and also on{" "}
                    <a
                        target="_blank"
                        href="https://trackmania.exchange/s/m/921"
                        rel="noreferrer"
                    >
                        TrackMania Exchange
                    </a>
                    !
                </p>
                <br />
                <PrettyTable
                    {...{
                        thead: ["map", "author", "records", "WR"],
                        tbody: tracks
                            .sort((a, b) => {
                                return a.clear_name > b.clear_name ? 1 : -1;
                            })
                            .map((track) => {
                                return [
                                    () => (
                                        <strong>
                                            <a
                                                {...{
                                                    href: `/campaign-vol-1/tracks/${track.uid}`,
                                                    onClick: OpenPage(
                                                        `campaign-vol-1/tracks/${track.uid}`
                                                    ),
                                                    className: css(
                                                        BaseStyles.link
                                                    ),
                                                }}
                                            >
                                                {track.clear_name}
                                            </a>
                                        </strong>
                                    ),
                                    track.creator.name,
                                    track.records.length,
                                    () =>
                                        track.wr_time > 0 ? (
                                            <span>
                                                {PrintTime(track.wr_time)}
                                                {" by "}
                                                <a
                                                    {...{
                                                        href: `/campaign-vol-1/users/${track.wr_account.name}`,
                                                        onClick: OpenPage(
                                                            `campaign-vol-1/users/${track.wr_account.name}`
                                                        ),
                                                        className: css(
                                                            BaseStyles.link
                                                        ),
                                                    }}
                                                >
                                                    {track.wr_account.name}
                                                </a>
                                            </span>
                                        ) : null,
                                ];
                            }),
                        theme: CampaignTable,
                        layout: {
                            theadColumnFontSize: 15,
                            tbodyColumnFontSize: 13,
                            titlePadding: "3px 10px 13px 10px",
                        },
                    }}
                />
            </div>
        </Fragment>
    );
}

const Styles = CreateScheet({
    tracks: {
        padding: "0 10px 10px 10px",
    },
    description: {
        padding: "0 10px",
        fontSize: 14,
        margin: "20px 0 0 0",
    },
});
