import * as r from "runtypes";

export const PhaseRuntype = r.Record({
    id: r.Number,
    title: r.String,
    description: r.String,
    time_to_hold: r.Number,
    start_on: r.String,
    end_on: r.String,
});
export type Phase = r.Static<typeof PhaseRuntype>;

export const PlayerRecordRuntime = r.Record({
    player_username: r.String,
    time: r.Number,
    created_at: r.Number,
});
export type PlayerRecord = r.Static<typeof PlayerRecordRuntime>;

export const CupTrackRuntype = r.Record({
    title: r.String,
    author: r.String,
    uid: r.String,
    image: r.String,
    tmx_link: r.String,
});
export type CupTrack = r.Static<typeof CupTrackRuntype>;

export const CupStateRuntype = r.Record({
    is_ended: r.Boolean,
    phase_type_id: r.Number,
    phase_name: r.String,
    win_range: r.Number,
    time_left: r.Number,
    start_at: r.Number,
    start_on: r.String,
    end_at: r.Number,
    end_on: r.String,
    phase_start_at: r.Number,
    phase_end_at: r.Number,
    current_wr: PlayerRecordRuntime.Or(r.Null),
});
export type CupState = r.Static<typeof CupStateRuntype>;

export const CupRuntype = r.Record({
    title: r.String,
    club_id: r.Number,
    room_id: r.Number,
    is_manager: r.Boolean,
    track: CupTrackRuntype,
    current_state: CupStateRuntype,
});
export type Cup = r.Static<typeof CupRuntype>;

export const PlayerRecordsRuntime = r.Array(PlayerRecordRuntime);
export type PlayerRecords = r.Static<typeof PlayerRecordsRuntime>;

export const CupLogRuntype = r.Record({
    id: r.Number,
    start_at: r.Number,
    end_at: r.Number,
    track_name: r.String,
    track_tmx_id: r.Number,
    track_author: r.String,
    is_ended: r.Boolean,
    ended_at: r.Number.Or(r.Null),
    current_username: r.String.Or(r.Null),
    current_player_time: r.Number.Or(r.Null),
});

export type CupLog = r.Static<typeof CupLogRuntype>;

export const CupsLogRuntype = r.Array(CupLogRuntype);
export type CupsLog = r.Static<typeof CupsLogRuntype>;

export const WHCResponseRuntype = r.Record({
    cup: CupRuntype,
    wrs: PlayerRecordsRuntime,
    top10: PlayerRecordsRuntime,
    log: CupsLogRuntype,
    phases: r.Dictionary(PhaseRuntype, r.Number),
});
export type WHCResponse = r.Static<typeof WHCResponseRuntype>;
