import { CreateScheet, css } from "aphrodite";
import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import { Images, Tournament } from "packages/tournaments/model";
import GenericStyles from "src/jsx/forms/GenericStyles";
import useSafeState from "packages/hooks/useSafeState";
import { PrimaryFilled, LoadButton, DangerFilled } from "src/themes/button";
import { useState } from "react";
import UpdateTournament from "packages/tournaments/API/UpdateTournament";
import showWarning from "packages/lightbox/warning.react";
import { DarkTheme } from "src/themes/lightbox";
import Switch from "packages/forms/Switch.react";
import { useLoadManagers } from "packages/tournaments/API/LoadManagers";
import PrettyTable from "packages/tables/PrettyTable.react";
import { DarkTable } from "src/themes/tables";
import UserPicker from "packages/users/modules/UserPicker.react";
import AddManager from "packages/tournaments/API/AddManager";
import RemoveManager from "packages/tournaments/API/RemoveManager";
import { session } from "packages/session/session";
import { MakeInput, PrimaryInput } from "src/themes/input";
import { Colors, ColorsKeys } from "packages/themes/theme";
import { BlockPicker } from "react-color";

export default function TournamentAdministration({
    content,
    close,
}: ComponentProps<{
    tournament: Tournament;
}>) {
    return (
        <Lightbox {...{ close }}>
            <Header>Manage players</Header>
            <Body>
                <TournamentAdministrationForm
                    {...content}
                    {...{ onClose: close, onSave: close }}
                />
            </Body>
        </Lightbox>
    );
}

function TournamentAdministrationForm({
    tournament,
    onSave,
    onClose,
}: {
    tournament: Tournament;
    onSave: () => void;
    onClose: () => void;
}) {
    const [managers, reloadManagers] = useLoadManagers(true, tournament.uri);

    const [state, setState] = useSafeState<{
        is_public: boolean;
        images: Images;
        theme: Colors;
    }>({
        is_public: tournament.is_public,
        images: tournament.images || {
            logo: "",
            background: "",
        },
        theme: tournament.theme || {
            primary: "",
            secondary: "",
            background_one: "",
            background_two: "",
            text: "",
        },
    });
    const [isSaving, setIsSaving] = useState(false);

    async function OnClickSave() {
        setIsSaving(true);

        const err = await UpdateTournament(tournament.uri, state);

        setIsSaving(false);
        if (err !== null) {
            showWarning(err.text, DarkTheme);
        } else {
            onSave();
        }
    }

    return (
        <section>
            <h2 className={css(GenericStyles.section_title)}>General</h2>
            <section className={css(GenericStyles.section)}>
                <div className={css(GenericStyles.section_title_description)}>
                    Public tournaments visible to everyone, private only for
                    managers
                </div>
                <div
                    className={css(GenericStyles.switch)}
                    onClick={() => {
                        setState({
                            is_public: { $set: !state.is_public },
                        });
                    }}
                >
                    <Switch checked={state.is_public} />
                    <div className={css(GenericStyles.switch_label)}>
                        Public
                    </div>
                </div>
            </section>
            <hr className={css(GenericStyles.divider)} />

            <h2 className={css(GenericStyles.section_title)}>Identity</h2>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>
                    Logo image URL
                </label>
                <input
                    {...{
                        placeholder: "logo image URL",
                        className: css(
                            MakeInput(PrimaryInput, { width: "100%" })
                        ),
                        value: state.images?.logo || "",
                        onChange: (e) => {
                            setState({
                                images: { logo: { $set: e.target.value } },
                            });
                        },
                    }}
                />
            </section>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>
                    Background image URL (1920x1080)
                </label>
                <input
                    {...{
                        placeholder: "background image URL",
                        className: css(
                            MakeInput(PrimaryInput, { width: "100%" })
                        ),
                        value: state.images?.background || "",
                        onChange: (e) => {
                            setState({
                                images: {
                                    background: { $set: e.target.value },
                                },
                            });
                        },
                    }}
                />
            </section>
            <hr className={css(GenericStyles.divider)} />

            <h2 className={css(GenericStyles.section_title)}>Theme</h2>
            {ColorsKeys.map((color, i) => {
                return (
                    <section className={css(GenericStyles.section)} key={i}>
                        <label className={css(GenericStyles.section_label)}>
                            {color}
                        </label>
                        <BlockPicker
                            {...{
                                width: "100%",
                                triangle: "hide",
                                color: state.theme[color],
                                colors: [
                                    "#F44336",
                                    "#E91E63",
                                    "#9C27B0",
                                    "#673AB7",
                                    "#3F51B5",
                                    "#2196F3",
                                    "#03A9F4",
                                    "#00BCD4",
                                    "#009688",
                                    "#4CAF50",
                                    "#8BC34A",
                                    "#CDDC39",
                                    "#FFEB3B",
                                    "#FFC107",
                                    "#FF9800",
                                    "#FF5722",
                                    "#795548",
                                    "#9E9E9E",
                                    "#607D8B",
                                    "#000000",
                                ],
                                styles: {
                                    default: {
                                        head: {
                                            height: 50,
                                        },
                                        card: {
                                            backgroundColor: "#454545",
                                        },
                                    },
                                },
                                onChange: (newColor) => {
                                    setState({
                                        theme: {
                                            [color]: { $set: newColor.hex },
                                        },
                                    });
                                },
                            }}
                        />
                    </section>
                );
            })}
            <hr className={css(GenericStyles.divider)} />

            <h2 className={css(GenericStyles.section_title)}>Managers</h2>
            <section className={css(GenericStyles.section)}>
                <PrettyTable
                    {...{
                        thead: ["username", "actions"].filter((a) =>
                            Boolean(a)
                        ),
                        tbody: managers.isLoading
                            ? [["loading", ""]]
                            : managers.payload
                            ? managers.payload.map((manager, i) => {
                                  return [
                                      manager.username,
                                      () => {
                                          if (
                                              manager.guid ===
                                              session.user?.guid
                                          ) {
                                              return <span>you</span>;
                                          }
                                          return (
                                              <LoadButton
                                                  {...{
                                                      theme: DangerFilled,
                                                      loading: false,
                                                      layout: {
                                                          padding: "3px 10px",
                                                      },
                                                      onClick: async () => {
                                                          const err =
                                                              await RemoveManager(
                                                                  tournament.uri,
                                                                  manager.guid
                                                              );
                                                          if (err != null) {
                                                              alert(err.text);
                                                          } else {
                                                              reloadManagers(
                                                                  tournament.uri
                                                              );
                                                          }
                                                      },
                                                  }}
                                              >
                                                  Delete
                                              </LoadButton>
                                          );
                                      },
                                  ];
                              })
                            : [],
                        theme: DarkTable,
                        columnsSizes: ["auto", 140],
                        layout: {
                            theadColumnFontSize: 13,
                            tbodyColumnFontSize: 12,
                            titlePadding: "3px 10px 13px 10px",
                        },
                    }}
                />
            </section>
            <section className={css(GenericStyles.section)}>
                <label className={css(GenericStyles.section_label)}>
                    Add manager
                </label>
                <div className={css(GenericStyles.section_title_description)}>
                    user must be registered on tma.gg
                </div>
                <UserPicker
                    {...{
                        setActive: async (user) => {
                            if (user) {
                                const err = await AddManager(
                                    tournament.uri,
                                    user?.guid
                                );
                                if (err != null) {
                                    alert(err.text);
                                } else {
                                    reloadManagers(tournament.uri);
                                }
                            }
                        },
                    }}
                />
            </section>
            <hr className={css(GenericStyles.divider)} />

            <footer className={css(Styles.footer)}>
                <LoadButton
                    {...{
                        theme: DangerFilled,
                        loading: isSaving,
                        onClick: onClose,
                    }}
                >
                    Close w/o saving
                </LoadButton>
                <LoadButton
                    {...{
                        theme: PrimaryFilled,
                        loading: isSaving,
                        className: css(Styles.button),
                        onClick: OnClickSave,
                    }}
                >
                    Save
                </LoadButton>
            </footer>
        </section>
    );
}

const Styles = CreateScheet({
    footer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginLeft: 15,
    },
});
