import { flushToStyleTag } from 'aphrodite';
import { Fragment, PropsWithChildren, useEffect, useRef } from 'react';
import AnimateHeight from 'react-animate-height';

export type props = PropsWithChildren<{
    duration?:          number
    height?:            number | "auto"
    onClick?:           (e: React.MouseEvent) => void,
    className?:         string,
    onGetNewHeight?:    (height: number) => void,
}>

export default function SmoothHeight({ onGetNewHeight, children, duration, height, className, onClick }: props) {
    return (
        <AnimateHeight {...{
            height:     typeof height !== "undefined" ? height : "auto",
            duration:   typeof duration !== "undefined" ? duration : 300,
            className,
            onClick,
            onAnimationEnd: (data) => {
                onGetNewHeight && onGetNewHeight(data.newHeight);
            }
        }}>
            { children }
        </AnimateHeight>
    )
}

export function SmoothHeightWithIgnore({ ignore, ...props }: props & { ignore: boolean }) {
    const ref = useRef<HTMLDivElement>(null);
    const ogn  = useRef(props.onGetNewHeight);
    ogn.current = props.onGetNewHeight;

    useEffect(() => {
        if (ignore && ref.current && ogn.current) {
            flushToStyleTag();
            ogn.current(ref.current.offsetHeight);
        }
    }, [ ignore ]);

    return (
        <Fragment>
            { ignore ? (
                <div ref={ ref } className={ props.className }>{props.children}</div>
            ) : (
                <SmoothHeight {...props} />
            ) }
        </Fragment>
    )
}