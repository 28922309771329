import { useState } from "react";
import SafeUpdate, { CustomCommands, Spec } from 'immutability-helper';

export default function useSafeState<S, C extends CustomCommands<object> = never>(initialState: S | (() => S)):[S, ($spec: Spec<S, C>) => void] {
    const [ state, setState ] = useState<S>(initialState);

    function safeSetState($spec: Spec<S, C>) {
        setState(s => SafeUpdate(s, $spec))
    }

    return [ state, safeSetState ];
}