import { ResultWithError } from "packages/errors/errors";
import CheckResultRuntime from "packages/helpers/CheckResultRuntime";
import { get } from "packages/rest/api";
import { makeAPIHook } from "packages/rest/useAPI";
import { PlayersMeta, PlayersMetaRuntype } from "../model";


export default async function LoadBannedPlayers(uri: string):Promise<ResultWithError<PlayersMeta>> {
    const res = await get<PlayersMeta>({ url: `tournaments/${uri}/players/bans` });
    return CheckResultRuntime(PlayersMetaRuntype, res);
}

export const useLoadBannedPlayers = makeAPIHook(LoadBannedPlayers);