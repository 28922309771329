import converter from 'hex2dec';

export function HexToDec(hex: string) {
    return converter.hexToDec(`0x${hex}`);
}

export function DecToHex(dec: number) {
    return converter.decToHex(dec.toString(), { prefix: false });
}

export function PrstToHex(prst: number) {
    return DecToHex(Math.round(prst * (255/100)));
}