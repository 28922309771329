import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithBG } from "packages/aphrodite/WithBG";
import { Haettenschweiler } from "../fonts";
import Header from "../modules/Header.react";

export default function Pentagruelica() {
    return (
        <article className={ css(Styles.page, WithBG("#191716", "radial-gradient(at top left, rgba(31, 36, 37, 0.2), rgba(31, 36, 37, 0)), radial-gradient(rgba(31, 36, 37, 0.3), rgba(31, 36, 37, 0)), url(/images/penta_map.jpg)")) }>
            <Header />
            <div className={ css(Styles.body) }>
                <h1 className={ css(Styles.title) }>Pentagruelica</h1>
                <div className={ css(Styles.title_label) }>the first TMA community map</div>
                <div className={ css(Styles.list) }>build by <strong>Lurk, Urumi7849, Schlumpy, Ozei and Deadfish</strong></div>
                <a {...{
                    href:      "https://trackmania.exchange/maps/31829/pentagruelica",
                    target:    "_blank",
                    className: css(Styles.open),
                }}>
                    Try it out
                </a>
            </div>
        </article>
    )
}

const Styles = CreateScheet({
    page: {
        minHeight:     "100vh",
        color:         "white",
        display:       "flex",
        flexDirection: "column",
    },
    body: {
        flexGrow:      1,
        paddingBottom: 30,
        position:      "relative",
    },
    title: {
        padding:    "50px 10px 0 50px",
        fontWeight: 400,
        fontSize:   80,
        margin:     0,
        color:      "#fec90c",
        fontStyle:  "italic",
        fontFamily: [Haettenschweiler, "Roboto"],
        textShadow: "1px 3px rgba(0,0,0)",
        '@media (max-width: 600px)': {
            padding:   "50px 10px 0 10px",
            textAlign: "center",
        }
    },
    title_label: {
        padding:    "0px 10px 0 50px",
        fontSize:   18,
        fontWeight: 400,
        fontStyle:  "italic",
        textShadow: "1px 1px rgba(0,0,0, 0.6)",
        '@media (max-width: 600px)': {
            padding:   "0px 10px 0 10px",
            textAlign: "center",
        }
    },
    open: {
        position:       "absolute",
        top:            "calc(50% - 127px)",
        left:           "calc(50% - 110px)",
        marginTop:      50,
        background:     "#fec90c",
        color:          "white",
        fontSize:       23,
        fontWeight:     600,
        textDecoration: "none",
        padding:        "14px 30px",
        display:        "flex",
        justifyContent: "center",
        alignItems:     "center",
        borderRadius:   10,
        transition:     "all 0.2s",
        margin:         "60px auto 0 auto",
        width:          220,
        boxShadow:      "rgba(0, 0, 0, 0.2) 1.95px 1.95px 2.6px, rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        textShadow:     "1px 1px rgb(0 0 0 / 30%)",
        ":hover":       {
            boxShadow:  "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            background: "#fdd444",
        }
    },
    list: {
        listStyle:   "none",
        margin:      0,
        paddingLeft: 20,
        position:    "absolute",
        bottom:      20,
        right:       20,
        fontSize:    24,
        textShadow:  "1px 1px rgb(0 0 0 / 50%)",
        fontStyle:   "italic",
        '@media (max-width: 600px)': {
            left:      20,
            textAlign: "center",
        }
    }
});