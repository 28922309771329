import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCog } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import { showLightbox } from "packages/lightbox/lightbox";
import { DarkTheme } from "src/themes/lightbox";
import { Cup } from "packages/whc/model";
import NextWeekEditor from "./NextWeekEditor.react";
import { Colors } from "../BaseStyles";

export default function ManagerPanel({ cup }: { cup: Cup }) {
    const [show, setShow] = useState(false);

    function OpenNextWeekEditor() {
        setShow(false);
        showLightbox({
            selector: "edit-tournament",
            content: { cup },
            Component: NextWeekEditor,
            theme: DarkTheme,
        });
    }

    return (
        <aside className={css(Styles.panel)}>
            {show ? (
                <Fragment>
                    <h3
                        className={css(Styles.title)}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        <div style={{ flexGrow: 1, marginRight: 10 }}>
                            Management
                        </div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className={css(Styles.icon)}
                        />
                    </h3>
                    <ul className={css(Styles.list)}>
                        <li
                            {...{
                                className: css(Styles.item),
                                onClick: OpenNextWeekEditor,
                            }}
                        >
                            Edit cup
                        </li>
                    </ul>
                </Fragment>
            ) : (
                <div
                    className={css(Styles.collapsed)}
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faCog}
                        className={css(Styles.icon)}
                        size="lg"
                    />
                </div>
            )}
        </aside>
    );
}

const Styles = CreateScheet({
    panel: {
        position: "fixed",
        top: 120,
        right: 0,
        backgroundColor: "white",
        borderRadius: "6px 0 0 6px",
        fontSize: 14,
        userSelect: "none",
        zIndex: 10,
        color: Colors.Primary,
    },
    list: {
        listStyle: "none",
        margin: 0,
        padding: "10px 0",
    },
    item: {
        padding: "8px 20px",
        cursor: "pointer",
    },
    collapsed: {
        padding: "10px",
        cursor: "pointer",
    },
    icon: {
        display: "block",
        color: Colors.Primary,
    },
    title: {
        padding: "10px 10px 0 20px",
        margin: 0,
        fontWeight: 500,
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
});
