import { CreateScheet, css } from "aphrodite";
import SafeUpdate from "packages/helpers/SafeUpdate";
import { OpenPage } from "packages/history/history";
import PrettyTable from "packages/tables/PrettyTable.react";
import { GetFormat, Records } from "packages/tournaments/model";
import PrintNumber from "packages/utils/PrintNumber";
import PrintTime from "packages/utils/PrintTime";
import { useParams } from "react-router-dom";
import { GetPath, PageProps } from "../model";
import GeneralStyles from "../modules/GeneralStyles";
import { GetTableTheme } from "packages/themes/components";

export default function Player({ theme, tournament, prefix }: PageProps) {
    const { username } = useParams<{ username: string }>();
    const player = tournament.players.find((p) => p.user.username === username);
    let records: Records = [];
    if (player) {
        Object.values(tournament.records).forEach((trackRecords) => {
            const record = trackRecords.find(
                (r) => r.account_id === player.user.account_id
            );
            if (record) {
                records = SafeUpdate(records, { $push: [record] });
            }
        });
    }

    const format = GetFormat(tournament.time_attack_format_id);
    if (!format) {
        return null;
    }

    return (
        <div className={css(GeneralStyles.content)}>
            {player ? (
                <>
                    <div className={css(Styles.meta)}>
                        <strong
                            className={css(
                                Styles.username,
                                theme.color("primary")
                            )}
                        >
                            {username}
                        </strong>
                        {tournament.has_time_attack && player.position > 0 && (
                            <div
                                className={css(
                                    Styles.pos,
                                    theme.background("secondary")
                                )}
                            >
                                <div className={css(Styles.pos_num)}>
                                    #{player.position}
                                </div>
                                <div className={css(Styles.pos_label)}>
                                    score
                                </div>
                                <div className={css(Styles.pos_score)}>
                                    {PrintNumber(player.score)}
                                </div>
                            </div>
                        )}
                    </div>
                    {tournament.has_time_attack && (
                        <PrettyTable
                            {...{
                                thead: [
                                    "map",
                                    "position",
                                    format.has_single_score
                                        ? format.score_title
                                        : undefined,
                                    "time",
                                ].filter((v) => Boolean(v)),
                                tbody: records.map((record) => {
                                    const track = tournament.tracks.find(
                                        (t) => t.uid === record.track_uid
                                    );
                                    if (!track) {
                                        return [];
                                    }

                                    return [
                                        () => (
                                            <strong>
                                                <a
                                                    {...{
                                                        href: `/${GetPath(
                                                            tournament.uri,
                                                            "tracks",
                                                            track.uid,
                                                            prefix
                                                        )}`,
                                                        onClick: OpenPage(
                                                            GetPath(
                                                                tournament.uri,
                                                                "tracks",
                                                                track.uid,
                                                                prefix
                                                            )
                                                        ),
                                                        className: css(
                                                            theme.color(
                                                                "secondary"
                                                            )
                                                        ),
                                                    }}
                                                >
                                                    {track.clear_name}
                                                </a>
                                            </strong>
                                        ),
                                        record.score.position,
                                        format.has_double_score ? (
                                            <span>
                                                <strong>
                                                    {format.score_func(
                                                        record.score.score
                                                    )}
                                                </strong>{" "}
                                                (
                                                {format.score_func(
                                                    record.score.position_score
                                                )}{" "}
                                                + {record.score.time_score})
                                            </span>
                                        ) : format.has_single_score ? (
                                            <span>
                                                {format.score_func(
                                                    record.score.score
                                                )}
                                            </span>
                                        ) : undefined,
                                        PrintTime(record.time),
                                    ].filter((v) => Boolean(v));
                                }),
                                theme: GetTableTheme(theme),
                                layout: {
                                    theadColumnFontSize: 15,
                                    tbodyColumnFontSize: 13,
                                    titlePadding: "3px 10px 13px 10px",
                                },
                            }}
                        />
                    )}
                </>
            ) : (
                <div className={css(GeneralStyles.no_data)}>no such player</div>
            )}
        </div>
    );
}

const Styles = CreateScheet({
    meta: {
        padding: "20px 0",
        fontSize: 20,
        position: "relative",
        margin: "20px 6px",
        height: 67,
        display: "flex",
        alignItems: "center",
    },
    username: {
        fontFamily: "Montserrat",
        fontSize: 30,
    },
    pos: {
        position: "absolute",
        right: 0,
        top: 0,
        color: "white",
        textAlign: "center",
        height: 67,
        width: 67,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 6,
    },
    pos_num: {
        fontSize: 29,
        fontWeight: 600,
    },
    pos_label: {
        fontSize: 9,
    },
    pos_score: {
        fontSize: 12,
    },
});
