import { CreateScheet, css } from "packages/aphrodite/aphrodite";
import { WithBG } from "packages/aphrodite/WithBG";
import Header from "../modules/Header.react";


export default function Loop_Camchange() {
    return (
        <article className={ css(Styles.page, WithBG("#191716", "linear-gradient(rgba(31, 36, 37, 0.8), rgba(31, 36, 37, 0.9)), url(/images/bg.jpg)"))}>
            <Header />
            <div>
                <h1 className={ css(Styles.head)}>CAMCHANGES</h1>
                <h2 className={ css(Styles.subhead)}>How to change cameras for Loops, Turnovers, Wallrides, etc.</h2>
            </div>
            <div className={ css(Styles.linkcontainer)}>
                <h3 className={ css(Styles.subhead2)}>Jump to:</h3>
                <ol >
                    <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link1">In Game trigger</a></li>
                    <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link2">Place triggerbox</a></li>
                    <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link3">Add Player Camera block</a></li>
                    <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link4">Choose camera type</a></li>
                    <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link5">Add empty exit trigger</a></li>
                    <li className={ css(Styles.list)}><a className={ css(Styles.links)} href="#link6">Video Tutorial</a></li>
                </ol>
            </div>
            <section className={ css(Styles.body)}>  
                <div  className={ css(Styles.content)}>
                    <ol>
                        <h3 id="link1" className={ css(Styles.spananchor)}> &nbsp; </h3>
                        <li className={ css(Styles.list)}>
                            <p>Open the Mediatracker and edit "In Game". This will let you place triggers that activate, when driving over them.</p>
                            <img alt="preview" src="https://i.imgur.com/cdKis5h.jpg" className={ css(Styles.image)} />
                        </li>
                        <h3 id="link2" className={ css(Styles.spananchor)}> &nbsp;</h3>
                        <li className={ css(Styles.list)}>
                            <p>     
                                Move the triggerbox to the place you want your camchange to happen (use the scrollwheel to change the height), then simply click and drag an area. 
                                Try to make it unmissable, better too make it a bit bigger/thicker than needed.
                            </p>
                            <p>For better organisation, rename the trigger, since you can later re-use the same trigger for other camchanges, by clicking the cogwheel icon in the "Triggers" column and adding areas.</p>
                            <img alt="preview" src="https://i.imgur.com/wJVUEVW.jpg" className={ css(Styles.image)} />
                        </li>
                        <h3 id="link3" className={ css(Styles.spananchor)}> &nbsp;</h3>
                        <li className={ css(Styles.list)}>
                            <p>Click on the + icon in the "Tracks" column. Choose the "Player Camera" block.</p>
                            <img alt="preview" src="https://i.imgur.com/BkeKNws.jpg" className={ css(Styles.image)} />
                        </li>
                        <h3 id="link4" className={ css(Styles.spananchor)}> &nbsp; </h3>
                        <li className={ css(Styles.list)}>
                            <p>Now click the first keyframe of the Player camera block in the timeline. Choose Internal for Camera type in the top left box.</p>
                            <img alt="preview" src="https://i.imgur.com/ijswgUL.jpg" className={ css(Styles.image)} />
                        </li>
                        <h3 id="link5" className={ css(Styles.spananchor)}> &nbsp; </h3>
                        <li className={ css(Styles.list)}>
                            <p>For exiting the cam we add a new trigger by pressing the + icon in the "Triggers" column.</p> 
                            <p>Place the triggerbox where you want the cam exit to be, rename the trigger and you are set. DON'T put another player camera in the timeline!!! This would force a specific cam for the rest of the track. 
                                An empty trigger will always put the player back into the cam he had before the change. </p>
                            <img alt="preview" src="https://i.imgur.com/hGZuLeG.jpg" className={ css(Styles.image)} />
                        </li>
                        <h3 id="link6" className={ css(Styles.spananchor)}> &nbsp; </h3>                               
                        <li className={ css(Styles.list)}>
                            Short Video Tutorial by Skyliner
                        </li>
                        <iframe className={ css(Styles.image)} src="https://www.youtube.com/embed/tXo9DMCSbOw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </ol>
                </div>
            </section>
        </article>
    )
}
const Styles = CreateScheet({
    page: {
        minGeight    : "100vh",
        color        : "white",
        display      : "flex",
        flexDirection: "column",
    },
    body: {
        display      : "flex",
        flexGrow     : 1,
        flexDirection: "column",
        alignItems   : "center",
        paddingLeft   : "15%",
        '@media (max-width: 1000px)': {
            paddingLeft: "0",
        }
    },      
    linkcontainer: {                        
        margin        : "20px",
        display       : "flex",
        position      : "fixed",
        top           : "418px",
        width         : "15%",
        flexDirection : "column",
        padding       : "20px",
        justifyContent: "center",
        '@media (max-width: 1000px)': {
            display: "none",
        }
    },
    content: {
        margin        : "auto",
        display       : "flex",
        flexGrow      : 1,
        flexDirection : "row",
        justifyContent: "space-around",
        alignItems    : "flex-start",
        paddingBottom : "100px",
        fontSize      : 28
    },
    head: {
        fontSize : 80,
        textAlign: "center",
        color    : "#ADD8E6",
        '@media (max-width: 1000px)': {
            fontSize: 40,
        }
    },
    subhead: {
        fontSize : 40,
        textAlign: "center",
        '@media (max-width: 1000px)': {
            fontSize: 20,
        }    

    },
    subhead2: {
        fontSize: 28,
        color   : "#ADD8E6",

    },
    list: {
        padding : "10px",
        fontSize: 20
    },
    spananchor:{
        marginTop    : "-50px",
        paddingBottom: "50px",
        display      : "block",
    },

    links: {
        textDecoration: "none",
        color         : "#E0FFFF",

    },
    image: {
        maxWidth: "60%"
    }
}) 