import { ComponentProps } from "packages/lightbox/model";
import { Lightbox, Header, Body } from "packages/lightbox/lightbox";
import { Tournament } from "packages/tournaments/model";
import { Theme } from "packages/themes/theme";
import { TimeAttackLeaderboard } from "../pages/TimeAttack.react";
import { CreateScheet } from "packages/aphrodite/aphrodite";
import { useLoadAllPlayers } from "packages/tournaments/API/LoadAllPlayers";
import WithSpinner from "packages/spinners/SmartSpinner.react";
import { GetSpinnerTheme } from "packages/themes/components";

export default function ShowDetailedTA({
    content,
    close,
}: ComponentProps<{
    prefix?: string;
    theme: Theme;
    tournament: Tournament;
}>) {
    const [data] = useLoadAllPlayers(true, content.tournament.uri);

    return (
        <Lightbox {...{ close }}>
            <Header>Edit tournament</Header>
            <Body styles={Styles.box}>
                <WithSpinner
                    suspense={data.isLoading}
                    theme={GetSpinnerTheme(content.theme)}
                >
                    <TimeAttackLeaderboard
                        {...{
                            uri: content.tournament.uri,
                            theme: content.theme,
                            players: data.payload || [],
                            tracks: content.tournament.tracks,
                            prefix: content.prefix,
                            format_id: content.tournament.time_attack_format_id,
                            total_tracks: content.tournament.tracks.length,
                            show_full: true,
                        }}
                    />
                </WithSpinner>
            </Body>
        </Lightbox>
    );
}

export const Styles = CreateScheet({
    wrapper: {
        width: "90vw",
    },
    box: {
        overflow: "auto",
        maxHeight: "90vh",
    },
});
